import React, { useEffect, useState, useRef } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import CircularProgress from "@mui/material/CircularProgress"
import * as Yup from "yup"
import "../../css/products.css"
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../components/ImageKit/ImageKitCred"
import { IKContext, IKImage, IKUpload, IKVideo } from "imagekitio-react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import Autocomplete from "@mui/material/Autocomplete"
import {
  useAddons,
  useCategorySetting,
  useProductCategorySearch,
  useProductsModifiers,
  useSpecificSubcatogories,
  useSpecificSubcatogoriesSetting,
} from "common/Hooks/queryCalls"
import { addProduct, updateProduct } from "common/Services/dbServices"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import { useProduct } from "common/Services/product"
import { IconButton } from "@mui/material"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import { useCurrentProvider } from "common/Hooks/queryCalls"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { LottieLoading } from "common/LottieLoading"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Tooltip from "@mui/material/Tooltip"
import Select from "react-select"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Table,
  InputGroup,
  Input,
} from "reactstrap"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import { v4 as uuidv4 } from "uuid"
import DeleteIcon from "@mui/icons-material/Delete"
import _isEqual from "lodash/isEqual"
import _ from "lodash"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { ObjectId } from "common/Services/dbServices"
const AddProduct = () => {
  const {
    setSearchCategory,
    setCategoryId,
    setSubCategoryId,
    searchCategory,
    categoryId,
    subCategoryId,
    productCategorySearchData,
    productCategorySearchIsLoading,
    categoriesSettingData,
    specificSubcatogories,
    SpecificSubcatogoriesSetting,
    productAdd,
    productUpdate,
    showSuccessAdded,
    setShowSuccessAdded,
    showSuccessRemoved,
    setShowSuccessRemoved,
    productsRefetch,
    menuId,
    setMenuId,
    menuData,
    menuIsLoading,
    menuRefetch,
    menuIsSuccess,
    productVariantsData,
    productVariantsIsLoading,
    productVariantsRefetch,
    productVariantsSuccess,
    productVariantsDataForSelect,
    productVariantsSuccessForSelect,
    productsDataCount,
    productsCountIsLoading,
    productsCountRefetch,
  } = useProduct()

  const { id } = useParams()
  const inputRefMainImage = useRef(null)
  const inputRefMedia = useRef(null)
  const inputVariantMedia = useRef(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const [modifierId, setModifierId] = useState("")
  const [modifierName, setModifierName] = useState("")
  const [resIcon, setResIcon] = useState({})
  const [isHoveredIcon, setIsHoveredIcon] = useState(false)
  const [modifierType, setModifierType] = React.useState("")
  const [modifierPrice, setModifierPrice] = React.useState(0)
  const [modifierData, setModifierData] = useState([])
  const [flag, setFlag] = useState(true)
  const [showImageError, setImageShowError] = useState(false)
  const [multipleImages, setmultipleImages] = useState([])
  const [multipleUploading, setMultipleUploading] = useState(false)
  const [showMultipleError, setMultipleShowError] = useState(false)
  const [showError, setShowError] = useState(false)
  const [badgeNew, setBadgeNew] = useState(false)
  const [sale, setSale] = useState(false)
  const [popular, setPopular] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [simpleProductEnable, setSimpleProductEnable] = useState(false)
  const [variableProductEnable, setVariableProductEnable] = useState(false)
  const [productType, setProductType] = React.useState("")
  const [selectedVariants, setSelectedVariants] = useState([])
  const [openAccordion, setOpenAccordion] = useState("")
  const [divSelection, setDivSelection] = useState("attribute")
  const [transformedProductVariantsData, setTransformedProductVariantsData] =
    useState()
  const [price, setPrice] = useState(null)
  const [salePrice, setSalePrice] = useState(null)
  const [resVariant, setResVariant] = useState({})
  const [variantImageLoading, setVariantImageLoading] = useState(false)
  const [selectedVariantCombinations, setSelectedVariantCombinations] =
    useState([])
  const [variantCombinations, setVariantCombinations] = useState([])
  const [isVariantCombinationExist, setIsVariantCombinationExist] =
    useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [errorMessage1, setErrorMessage1] = useState("")
  const [errorMessage2, setErrorMessage2] = useState("")
  const [combinationExistError, setCombinationExistError] = useState(false)
  const [combinationBasicDetailsError, setCombinationBasicDetailsError] =
    useState(false)
  const [combinationAttributesError, setCombinationAttributesError] =
    useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)

  // console.log("menuData:", menuData)

  const currentProvider = useCurrentProvider()
  const navigate = useNavigate()

  const handleChange = event => {
    setModifierType(event.target.value)
  }

  // const addons = useAddons({ providerId })

  const productsModifier = useProductsModifiers({
    // menuId: specificProduct?._id,
  })

  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price is required"),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  // console.log("currentProvider", currentProvider?.data?._id)

  async function addNewProduct(data) {
    if (id) {
      setLoading(true)
      try {
        if (categoryId) {
          const result = await productUpdate({
            providerId: currentProvider?.data?._id,
            item: menuData,
            data,
            resIcon,
            badge: {
              new: badgeNew,
              sale: sale,
              popular: popular,
            },
            multipleImages,
            categoryId,
            subCategoryId,
            selectedVariants,
            productType,
            variantCombinations,
            modifierData,
          })
          // console.log("update:", result)
          setLoading(false)
          menuRefetch()
          productsCountRefetch()
          productsRefetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
          setCategoryId("")
          setSubCategoryId("")
          setSearchCategory("")
          setMenuId(""), navigate("/products")
        } else {
          setLoading(false)
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      } catch (error) {
        setLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      setLoading(true)
      try {
        if (categoryId) {
          const result = await productAdd({
            providerId: currentProvider?.data?._id,
            data,
            resIcon,
            badge: {
              new: badgeNew,
              sale: sale,
              popular: popular,
            },
            multipleImages,
            categoryId,
            subCategoryId,
            selectedVariants,
            productType,
            variantCombinations,
            modifierData,
          })
          // console.log("add:", result)
          setLoading(false)
          menuRefetch()
          productsCountRefetch()
          productsRefetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)

          setCategoryId("")
          setSubCategoryId("")
          setSearchCategory("")
          setMenuId(""), navigate("/products")
        } else {
          setLoading(false)
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      } catch (error) {
        setLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    }
  }

  const handleClickChecking = () => {
    const transformedArray = selectedVariantCombinations?.map(item => ({
      variantId: item.variantId,
      optionId: item.optionId,
    }))
    const foundMatch = variantCombinations?.some(item => {
      const sortedArray2 = _.sortBy(
        item.variantOptions.map(item => ({
          variantId: item.variantId,
          optionId: item.optionId,
        })),
        ["variantId", "optionId"]
      )

      const sortedArray1 = _.sortBy(transformedArray, ["variantId", "optionId"])

      const result = _.isEqual(sortedArray1, sortedArray2)

      return result
    })
    setIsVariantCombinationExist(foundMatch)
  }

  useEffect(() => {
    handleClickChecking()
  }, [selectedVariantCombinations])

  useEffect(() => {
    const tempData = {}
    productVariantsDataForSelect?.forEach(element => {
      tempData[element.value] = element.attributeOptions
    })
    setTransformedProductVariantsData(tempData)
  }, [productVariantsSuccessForSelect])

  useEffect(() => {
    setMenuId(id)
  }, [])

  useEffect(() => {
    setValue("englishName", menuData?.name?.en)
    setValue("englishDescription", menuData?.description?.en)
    setValue("price", menuData?.price)
    setValue("salePrice", menuData?.salePrice)
    setValue("minimumAddons", menuData?.minimumModifiers)
    setValue("maximumAddons", menuData?.maximumModifiers)
    if (menuData?.badge) {
      setBadgeNew(menuData?.badge.new)
      setSale(menuData?.badge.sale)
      setPopular(menuData?.badge.popular)
    }
    setResIcon(menuData?.mainImage)
    setmultipleImages(menuData?.media || [])
    setTimeout(() => {
      setProductType(menuData?.type)
    }, 200)
    setCategoryId(menuData?.categoryId)
    setSubCategoryId(menuData?.subCategoryId)
    setSelectedVariants(
      menuData?.attributes?.map(obj => ({
        label: obj.name.en,
        value: obj._id,
        availableOptions: obj.availableOptions?.map(obj1 => ({
          label: obj1.value,
          value: obj1._id,
        })),
      })) || []
    )
    setVariantCombinations(
      menuData?.variantCombinations?.map(item => ({
        ...item,
        variantOptions: item.variantOptions.map(option => ({
          ...option,
          optionLabel: option.optionLabel.value,
          variantLabel: option.variantLabel.name.en,
        })),
        categoryId: menuData?.categoryId,
      })) || []
    )
  }, [menuIsSuccess])

  useEffect(() => {
    if (flag && productsModifier.data) {
      setModifierData([...productsModifier.data])
      setFlag(false)
    }
  }, [productsModifier.data])

  const onErrorIcon = err => {
    setImageUploading(false)
    setImageShowError(true)
  }

  const onSuccessIcon = res => {
    // console.log("resImage:", res)
    setResIcon(res)
    setImageUploading(false)
  }

  const onUploadProgress = progress => {
    if (progress) {
    }
    setImageUploading(true)
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setImageShowError(false)
    setShowError(false)
    setMultipleShowError(false)
  }

  const onErrorMultiple = err => {
    setMultipleUploading(false)
    // console.log("err:", err)
    setMultipleShowError(true)
  }

  const onSuccessMultiple = res => {
    setmultipleImages(prevSelectedData => [...prevSelectedData, res])
    // console.log("res:", res)
    setMultipleUploading(false)
  }

  const onUploadProgressMultiple = progress => {
    // console.log("progress:", progress)

    setMultipleUploading(true)
  }

  const onErrorVariant = err => {
    setVariantImageLoading(false)
    setImageShowError(true)
  }

  const onSuccessVariant = res => {
    // console.log("resImage:", res)
    setResVariant(res)
    setVariantImageLoading(false)
  }

  const onUploadProgressVariant = progress => {
    setVariantImageLoading(true)
  }

  const deleteItemAtIndex = indexToDelete => {
    setmultipleImages(prevData =>
      prevData.filter((_, index) => index !== indexToDelete)
    )
  }
  const handleRadioChange = event => {
    setProductType(event.target.value) // Step 3
  }

  const toggle = id => {
    if (openAccordion === id) {
      setOpenAccordion()
    } else {
      setOpenAccordion(id)
    }
  }

  const handlePriceChange = e => {
    setPrice(e.target.value)
  }

  const handleSalePriceChange = e => {
    setSalePrice(e.target.value)
  }

  const handleAddVariantProduct = () => {
    if (selectedVariants?.length === selectedVariantCombinations?.length) {
      if (isVariantCombinationExist) {
        setSelectedVariantCombinations([])
        setPrice("")
        setSalePrice("")
        setResVariant({})
        setCombinationExistError(true)
      } else {
        if (selectedVariantCombinations?.length > 0 && price) {
          const updatedProducts = [...variantCombinations]
          updatedProducts.push({
            _id: uuidv4(),
            price: price ? parseInt(price) : null,
            salePrice: salePrice ? parseInt(salePrice) : null,
            variantOptions: selectedVariantCombinations,
            mainImage: resVariant,
            categoryId:
              typeof categoryId === "string"
                ? new ObjectId(categoryId)
                : categoryId,
          })
          setVariantCombinations(updatedProducts)
          setSelectedVariantCombinations([])
          setPrice("")
          setSalePrice("")
          setResVariant({})
        } else {
          setCombinationBasicDetailsError(true)
        }
      }
    } else {
      setCombinationAttributesError(true)
    }
  }

  const handleDeleteVariantProduct = deleteItem => {
    const newData = variantCombinations.filter(
      item => item._id !== deleteItem._id
    )
    setVariantCombinations(newData)
  }

  const handleCloseToastVariant = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setCombinationExistError(false)
    setCombinationBasicDetailsError(false)
    setCombinationAttributesError(false)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  // console.log("selectedVariantCombinations:", selectedVariantCombinations)
  // console.log(
  //   "selectedVariantCombinationsTemp:",
  //   selectedVariantCombinationsTemp
  // )
  // console.log("variantCombinations:", variantCombinations)
  // console.log("menuData?.variantCombinations:", menuData?.variantCombinations)

  // console.log("isVariantCombinationExist:", isVariantCombinationExist)
  // console.log("productVariantsDataForSelect:", productVariantsDataForSelect)
  // console.log("transformedProductVariantsData:", transformedProductVariantsData)
  // console.log("lengthselectedVariants:", selectedVariants?.length)
  // console.log(
  //   "lengthselectedVariantCombinations:",
  //   selectedVariantCombinations?.length
  // )

  if (menuIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  return (
    <div className="product-div">
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Navigation?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to navigate away from the current page. Any unsaved
            changes made to this product may be lost. Are you sure you want to
            continue to the Attributes Page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>No</Button>
          <Button onClick={() => navigate(`/variants`)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showImageError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Image uploading failed. Please try again later
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showMultipleError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Multiple uploading failed. Please try again later
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showImageError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something went wrong !
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={combinationExistError}
          autoHideDuration={1500}
          onClose={handleCloseToastVariant}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToastVariant}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            This variant combination already exists for this product
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={combinationBasicDetailsError}
          autoHideDuration={1500}
          onClose={handleCloseToastVariant}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToastVariant}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Product cannot be added without essential information such as a
            price and variant combinations
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={combinationAttributesError}
          autoHideDuration={1500}
          onClose={handleCloseToastVariant}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToastVariant}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Cannot add variant combination. Ensure all required attributes are
            included in the combination
          </Alert>
        </Snackbar>
      </Stack>
      <h5 className="product-edit-title">
        {id ? `Edit Product` : `Add New Product `}
      </h5>{" "}
      <div style={{ padding: "5%", backgroundColor: "white" }}>
        <form>
          <div className="product-text-field">
            <div className="product-text-field-row">
              <Tooltip title="Add name of the product" arrow>
                <TextField
                  fullWidth
                  label="Name*"
                  id="fullWidth"
                  size="small"
                  {...register("englishName", { required: true })}
                  error={errors.englishName ? true : false}
                  // sx={styleTextField}
                />
              </Tooltip>
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.englishName && errors.englishName?.message
                  ? errors.englishName?.message.toString()
                  : null}
              </Typography>
            </div>
            {/* <div className="product-text-field-row">
            <TextField
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
              // sx={styleTextField}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div> */}
          </div>
          <div className="product-text-field">
            <TextField
              multiline
              maxRows={8}
              fullWidth
              label="Description"
              id="fullWidth"
              size="small"
              {...register("englishDescription", { required: true })}
              error={errors.englishDescription ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors &&
              errors.englishDescription &&
              errors.englishDescription?.message
                ? errors.englishDescription?.message.toString()
                : null}
            </Typography>
            {/* <TextField
            fullWidth
            label="وصف"
            id="fullWidth"
            size="small"
            {...register("arabicDescription", { required: true })}
            error={errors.arabicDescription ? true : false}
            // sx={styleTextField}
          /> */}
            {/* <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors &&
            errors.arabicDescription &&
            errors.arabicDescription?.message
              ? errors.arabicDescription?.message.toString()
              : null}
          </Typography> */}
          </div>
          <div className="product-text-field">
            <div className="product-text-field-row">
              <Tooltip title="Add price of the product" arrow>
                <TextField
                  fullWidth
                  label="Price*"
                  id="fullWidth"
                  size="small"
                  {...register("price", { required: true })}
                  error={errors.price ? true : false}
                  // sx={styleTextField}
                />
              </Tooltip>
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.price && errors.price?.message
                  ? errors.price?.message.toString()
                  : null}
              </Typography>
            </div>
            <Tooltip title="Add sale price of the product" arrow>
              <TextField
                helperText="Kindly note that the sale price should not exceed the original price."
                fullWidth
                label="Sale Price"
                id="fullWidth"
                size="small"
                {...register("salePrice", { required: true })}
                error={errors.salePrice ? true : false}
                // sx={styleTextField}
              />
            </Tooltip>

            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.salePrice && errors.salePrice?.message
                ? errors.salePrice?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="product-text-field">
            {/* <TextField
            fullWidth
            label="Minimum Addons"
            id="fullWidth"
            size="small"
            {...register("minimumAddons", { required: true })}
            error={errors.minimumAddons ? true : false}
            // sx={styleTextField}
          />
          <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors && errors.minimumAddons && errors.minimumAddons?.message
              ? errors.minimumAddons?.message.toString()
              : null}
          </Typography>
          <TextField
            fullWidth
            label="Maximum Addons"
            id="fullWidth"
            size="small"
            {...register("maximumAddons", { required: true })}
            error={errors.maximumAddons ? true : false}
            // sx={styleTextField}
          />
          <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors && errors.maximumAddons && errors.maximumAddons?.message
              ? errors.maximumAddons?.message.toString()
              : null}
          </Typography> */}
          </div>
          <div className="product-text-field">
            <Autocomplete
              options={productCategorySearchData || []}
              fullWidth
              size="small"
              value={categoriesSettingData?.label || ""}
              onChange={(event, newValue) => {
                setCategoryId(newValue?.id)
              }}
              renderInput={params => (
                <Tooltip title="Select your category here" arrow>
                  <TextField
                    {...params}
                    label="Select Category"
                    size="small"
                    // sx={styleTextField}
                    onChange={newValue =>
                      setSearchCategory(newValue.target.value)
                    }
                  />
                </Tooltip>
              )}
            />

            {categoryId && (
              <Autocomplete
                options={specificSubcatogories || []}
                fullWidth
                size="small"
                value={SpecificSubcatogoriesSetting?.label || ""}
                onChange={(event, newValue) => {
                  setSubCategoryId(newValue?.id)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Sub Category"
                    size="small"
                    // sx={styleTextField}
                    onChange={newValue =>
                      setSearchCategory(newValue.target.value)
                    }
                  />
                )}
              />
            )}
          </div>{" "}
          <div className="product-text-field">
            <div className="product-text-field-row">
              <Box sx={{ display: "flex" }}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel component="legend">Badges</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={badgeNew}
                          onChange={() => {
                            setBadgeNew(!badgeNew),
                              setSale(false),
                              setPopular(false)
                          }}
                        />
                      }
                      label="New"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sale}
                          onChange={() => {
                            setBadgeNew(false),
                              setSale(!sale),
                              setPopular(false)
                          }}
                        />
                      }
                      label="Sale"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={popular}
                          onChange={() => {
                            setBadgeNew(false),
                              setSale(false),
                              setPopular(!popular)
                          }}
                        />
                      }
                      label="Popular"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </div>
          </div>
          <div className="product-media-div">
            <div className="product-image-field">
              <h6 style={{ display: "flex", flexWrap: "wrap", width: "50px" }}>
                Cover Image:
              </h6>

              {resIcon && resIcon.filePath ? (
                <div
                  style={{
                    width: 35,
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                  onMouseEnter={() => setIsHoveredIcon(true)}
                  onMouseLeave={() => setIsHoveredIcon(false)}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <IKImage
                      urlEndpoint={urlEndpoint}
                      path={resIcon.filePath}
                      className="product-img-size"
                    />
                    {resIcon.filePath && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingTop: "2px",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.6rem",
                                backgroundColor: "#040D12",
                                width: "100%",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                          }}
                          onClick={() => {
                            //  setImage(null);
                            setResIcon(null)
                          }}
                          style={{ fontFamily: "outfit" }}
                        >
                          Delete
                        </Button>
                      </div>

                      // <div
                      //   style={{
                      //     position: "absolute",
                      //     top: "5px",
                      //     right: "5px",
                      //     zIndex: "1",
                      //     cursor: "pointer",
                      //   }}
                      //   onClick={() => {
                      //     //  setImage(null);
                      //     setResIcon(null)
                      //   }}
                      // >
                      //   <AiOutlineCloseCircle
                      //     size={16}
                      //     color="red"
                      //     style={{
                      //       position: "absolute",
                      //       top: -10,
                      //       right: -10,
                      //       // color: 'red',
                      //       cursor: "pointer",
                      //     }}
                      //   />
                      // </div>
                    )}
                  </div>
                </div>
              ) : (
                <IKContext
                  publicKey={publicKey}
                  urlEndpoint={urlEndpoint}
                  authenticationEndpoint={authenticationEndpoint}
                >
                  {imageUploading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    <>
                      <IKUpload
                        onError={onErrorIcon}
                        onSuccess={onSuccessIcon}
                        onUploadStart={onUploadProgress}
                        buttonProps={{ children: "+" }}
                        inputRef={inputRefMainImage}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={e => {
                          // Check if the selected file is an image
                          const selectedFile = e.target.files[0]
                          if (selectedFile) {
                            if (!selectedFile.type.startsWith("image/")) {
                              // Check the file type and show an appropriate error message
                              if (
                                selectedFile.type.startsWith("application/pdf")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a PDF."
                                )
                              } else if (
                                selectedFile.type.startsWith("video/")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a video."
                                )
                              } else {
                                setErrorMessage("Please select an image file.")
                              }
                              // Clear the file input (optional)
                              e.target.value = null
                            } else {
                              // Clear any previous error messages
                              setErrorMessage("")
                            }
                          }
                        }}
                      />
                      <div
                        className="image-upload-btn-div"
                        onClick={() => inputRefMainImage.current.click()}
                      >
                        <FileUploadIcon />
                        <Button
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.8rem",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.8rem",
                            },
                          }}
                          variant="standard"
                          // onClick={() => inputRefMainImage.current.click()}
                          style={{ fontFamily: "outfit" }}
                        >
                          Upload
                        </Button>
                      </div>
                    </>
                  )}
                </IKContext>
              )}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
            <div
              className="product-image-upload-field"
              style={
                multipleImages?.length > 0
                  ? {}
                  : {
                      marginTop: "30px",
                    }
              }
            >
              <div className="product-multiple-image-field">
                <h6>Media:</h6>{" "}
                <div
                  style={
                    multipleImages?.length > 0
                      ? {
                          position: "relative",
                          marginTop: "40px",
                        }
                      : { position: "relative" }
                  }
                  className="multiple-product-images"
                >
                  {multipleImages?.map((item, index) => (
                    <>
                      <div>
                        {item?.fileType === "image" ? (
                          <IKImage
                            urlEndpoint={urlEndpoint}
                            path={item?.filePath}
                            className="product-img-size"
                          />
                        ) : (
                          ""
                        )}
                        {item?.fileType === "non-image" ? (
                          <IKVideo
                            urlEndpoint={urlEndpoint}
                            path={item?.filePath}
                            transformation={[
                              { height: 250, width: 250, q: 95 },
                            ]}
                            controls={true}
                            className="product-video-size"
                          />
                        ) : (
                          ""
                        )}{" "}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingTop: "2px",
                            // width:'100%'
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              "@media (max-width: 600px)": {
                                fontSize: "0.6rem",
                                backgroundColor: "#040D12",
                                width: "100%",
                              },
                              "@media (min-width: 601px) and (max-width: 960px)":
                                {
                                  fontSize: "0.6rem",
                                  backgroundColor: "#040D12",
                                  width: "100%",
                                },
                              "@media (min-width: 970px)": {
                                fontSize: "0.6rem",
                                backgroundColor: "#040D12",
                                width: "100%",
                              },
                            }}
                            onClick={() => deleteItemAtIndex(index)}
                            style={{ fontFamily: "outfit" }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>

                      {/* <IconButton
                      size="small"
                      onClick={() => deleteItemAtIndex(index)}
                      className="product-cross-icon"
                    >
                      <AiOutlineCloseCircle size={16} color="red" />
                    </IconButton> */}
                    </>
                  ))}{" "}
                  <IKContext
                    publicKey={publicKey}
                    urlEndpoint={urlEndpoint}
                    authenticationEndpoint={authenticationEndpoint}
                  >
                    {multipleUploading ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress size={25} />
                      </Box>
                    ) : (
                      <>
                        <IKUpload
                          onError={onErrorMultiple}
                          onSuccess={onSuccessMultiple}
                          onUploadStart={onUploadProgressMultiple}
                          buttonProps={{ children: "+" }}
                          inputRef={inputRefMedia}
                          style={{ display: "none" }}
                          accept="image/*,video/*" // Accept both image and video files
                          onChange={e => {
                            // Check if the selected file is an image or video
                            const selectedFile = e.target.files[0]
                            if (selectedFile) {
                              if (
                                !(
                                  selectedFile.type.startsWith("image/") ||
                                  selectedFile.type.startsWith("video/")
                                )
                              ) {
                                // Show an error message for non-image and non-video files
                                setErrorMessage1(
                                  "Please select an image or video file."
                                )
                                // Clear the file input (optional)
                                e.target.value = null
                              } else {
                                // Clear any previous error messages
                                setErrorMessage1("")
                              }
                            }
                          }}
                        />

                        <div
                          className="image-upload-btn-div"
                          onClick={() => inputRefMedia.current.click()}
                        >
                          <FileUploadIcon />
                          <Button
                            sx={{
                              "@media (max-width: 600px)": {
                                fontSize: "0.6rem",
                              },
                              "@media (min-width: 601px) and (max-width: 960px)":
                                {
                                  fontSize: "0.8rem",
                                },
                              "@media (min-width: 970px)": {
                                fontSize: "0.8rem",
                              },
                            }}
                            variant="standard"
                            // onClick={() => inputRefMedia.current.click()}
                            style={{ fontFamily: "outfit" }}
                          >
                            Upload
                          </Button>
                        </div>
                      </>
                    )}
                  </IKContext>
                  {errorMessage1 && (
                    <p style={{ color: "red" }}>{errorMessage1}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="product-text-field-product-type">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Product Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={productType} // Controlled component
                onChange={handleRadioChange} // Step 3
              >
                <FormControlLabel
                  value="simpleProduct"
                  control={<Radio />}
                  label="Simple Product"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="variableProduct"
                  control={<Radio />}
                  label="Variable Product"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>

            {productType === "variableProduct" ? (
              <div className="variable-product-main-div">
                <div className="variable-product-sub">
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.5rem",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.6rem",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.7rem",
                      },
                    }}
                    variant={
                      divSelection === "attribute" ? "contained" : "outlined"
                    }
                    onClick={() => setDivSelection("attribute")}
                    style={{ fontFamily: "outfit" }}
                  >
                    Attributes
                  </Button>
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.5rem",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.6rem",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.7rem",
                      },
                    }}
                    variant={
                      divSelection === "variation" ? "contained" : "outlined"
                    }
                    onClick={() => setDivSelection("variation")}
                    style={{ fontFamily: "outfit" }}
                  >
                    Variations
                  </Button>
                </div>
                <div className="variable-product-sub-value">
                  {divSelection === "attribute" ? (
                    <>
                      <div className="variable-product-sub-value-sub">
                        <Button
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.5rem",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.6rem",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.7rem",
                            },
                          }}
                          variant="outlined"
                          onClick={() => {
                            setOpenDialog(true)
                          }}
                          style={{ fontFamily: "outfit" }}
                        >
                          Add Attributes
                        </Button>{" "}
                        <Select
                          className="variable-product-sub-value-sub-select"
                          options={productVariantsDataForSelect}
                          isMulti
                          onChange={selectedOptions => {
                            if (
                              selectedOptions?.length > selectedVariants?.length
                            ) {
                              selectedOptions.forEach(option => {
                                const existingIndex =
                                  selectedVariants.findIndex(
                                    item =>
                                      item.value.toString() ===
                                      option.value.toString()
                                  )

                                if (existingIndex === -1) {
                                  setSelectedVariants(prevState => [
                                    ...prevState,
                                    option,
                                  ])
                                } else {
                                  setSelectedVariants(prevState => {
                                    const updatedVariants = [...prevState]
                                    updatedVariants[existingIndex] = option
                                    return updatedVariants
                                  })
                                }
                              })
                            } else {
                              setSelectedVariants(selectedOptions)
                            }
                          }}
                          value={selectedVariants}
                        />
                      </div>
                      <div className="variable-product-sub-value-accordion">
                        <Accordion open={openAccordion} toggle={toggle}>
                          {selectedVariants?.map((item, index) => (
                            <AccordionItem>
                              <AccordionHeader targetId={`${index + 1}`}>
                                {item?.label}
                              </AccordionHeader>
                              <AccordionBody accordionId={`${index + 1}`}>
                                <label htmlFor="select">Select options:</label>
                                <Select
                                  key={index}
                                  id="select"
                                  options={
                                    transformedProductVariantsData[item.value]
                                  }
                                  isMulti
                                  onChange={selected => {
                                    if (
                                      selected?.length >
                                      selectedVariants[index].availableOptions
                                        ?.length
                                    ) {
                                      selected.forEach(option => {
                                        const existingIndex = selectedVariants[
                                          index
                                        ].availableOptions.findIndex(
                                          item =>
                                            item.value.toString() ===
                                            option.value.toString()
                                        )

                                        if (existingIndex === -1) {
                                          const tempState = selectedVariants
                                          tempState[
                                            index
                                          ].availableOptions.push(option)
                                          setSelectedVariants(tempState)
                                        }
                                      })
                                    } else {
                                      // console.log("selected:", selected)
                                      const updatedVariants = [
                                        ...selectedVariants,
                                      ]
                                      updatedVariants[index].availableOptions =
                                        selected
                                      setSelectedVariants(updatedVariants)
                                    }
                                  }}
                                  value={item.availableOptions}
                                />
                              </AccordionBody>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {divSelection === "variation" ? (
                    <>
                      <div className="variable-product-sub-value-sub-variation">
                        {selectedVariants?.length > 0 ? (
                          <>
                            <Table>
                              <thead>
                                <tr>
                                  {selectedVariants?.map(item => (
                                    <>
                                      <th style={{ textAlign: "center" }}>
                                        {item.label}
                                      </th>
                                    </>
                                  ))}
                                  <th style={{ textAlign: "center" }}>Price</th>
                                  <th style={{ textAlign: "center" }}>
                                    Sale Price
                                  </th>
                                  <th style={{ textAlign: "center" }}>Image</th>
                                  <th style={{ textAlign: "center" }}>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <thead>
                                <tr>
                                  {selectedVariants?.map((item, index) => (
                                    <>
                                      <th
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <Select
                                          className="table-select-field"
                                          options={item.availableOptions}
                                          onChange={selectedOption => {
                                            const updatedCombinations = [
                                              ...selectedVariantCombinations,
                                            ]

                                            const variantId =
                                              selectedVariants[index].value
                                            const optionId =
                                              selectedOption.value
                                            const variantLabel =
                                              selectedVariants[index].label
                                            const optionLabel =
                                              selectedOption.label

                                            // Check if a combination with the same variantId already exists
                                            const existingIndex =
                                              updatedCombinations.findIndex(
                                                combination =>
                                                  combination.variantId.toString() ===
                                                  variantId.toString()
                                              )

                                            if (existingIndex !== -1) {
                                              // Update the option_id for the existing variant_id
                                              updatedCombinations[
                                                existingIndex
                                              ].optionId = optionId
                                              updatedCombinations[
                                                existingIndex
                                              ].optionLabel = optionLabel
                                            } else {
                                              // Create a new combination if it doesn't exist
                                              updatedCombinations.push({
                                                variantLabel,
                                                optionLabel,
                                                variantId,
                                                optionId,
                                              })
                                            }

                                            // console.log(
                                            //   "updatedCombinations:",
                                            //   updatedCombinations
                                            // )

                                            // Update state with the updated combinations
                                            setSelectedVariantCombinations(
                                              updatedCombinations
                                            )
                                          }}
                                          styles={{
                                            option: base => ({
                                              ...base,
                                              backgroundColor: "#0C090A",
                                              opacity: "1 ",
                                              color: "white",
                                            }),
                                          }}
                                        />
                                      </th>
                                    </>
                                  ))}
                                  <th
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {/* <InputGroup>
                                      {" "}
                                      <Input
                                        style={{
                                          textAlign: "center",
                                          width: "100px",
                                        }}
                                        type="number"
                                        value={price}
                                        onChange={handlePriceChange}
                                      />
                                    </InputGroup> */}
                                    <TextField
                                      style={{
                                        textAlign: "center",
                                        width: "100px",
                                      }}
                                      type="number"
                                      size="small"
                                      value={price}
                                      onChange={handlePriceChange}
                                    />
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {/* <InputGroup>
                                      {" "}
                                      <Input
                                        style={{
                                          textAlign: "center",
                                          width: "100px",
                                        }}
                                        type="number"
                                        value={salePrice}
                                        onChange={handleSalePriceChange}
                                      />
                                    </InputGroup> */}
                                    <TextField
                                      style={{
                                        textAlign: "center",
                                        width: "100px",
                                      }}
                                      type="number"
                                      size="small"
                                      value={salePrice}
                                      onChange={handleSalePriceChange}
                                    />
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {resVariant && resVariant.filePath ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <IKImage
                                            urlEndpoint={urlEndpoint}
                                            path={resVariant.filePath}
                                            width="60px"
                                            height="70px"
                                          />
                                          <Button
                                            sx={{
                                              "@media (max-width: 600px)": {
                                                fontSize: "0.6rem",
                                                backgroundColor: "#040D12",
                                              },
                                              "@media (min-width: 601px) and (max-width: 960px)":
                                                {
                                                  fontSize: "0.6rem",
                                                  backgroundColor: "#040D12",
                                                },
                                              "@media (min-width: 970px)": {
                                                fontSize: "0.6rem",
                                                backgroundColor: "#040D12",
                                              },
                                            }}
                                            variant="contained"
                                            onClick={() => setResVariant({})}
                                            style={{ fontFamily: "outfit" }}
                                          >
                                            delete
                                          </Button>
                                        </div>
                                      </>
                                    ) : variantImageLoading ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CircularProgress size={20} />
                                      </Box>
                                    ) : (
                                      <IKContext
                                        publicKey={publicKey}
                                        urlEndpoint={urlEndpoint}
                                        authenticationEndpoint={
                                          authenticationEndpoint
                                        }
                                      >
                                        <IKUpload
                                          onError={onErrorVariant}
                                          onSuccess={onSuccessVariant}
                                          onUploadStart={
                                            onUploadProgressVariant
                                          }
                                          buttonProps={{ children: "+" }}
                                          inputRef={inputVariantMedia}
                                          style={{ display: "none" }}
                                          accept="image/*"
                                          onChange={e => {
                                            // Check if the selected file is an image
                                            const selectedFile =
                                              e.target.files[0]
                                            if (selectedFile) {
                                              if (
                                                !selectedFile.type.startsWith(
                                                  "image/"
                                                )
                                              ) {
                                                // Check the file type and show an appropriate error message
                                                if (
                                                  selectedFile.type.startsWith(
                                                    "application/pdf"
                                                  )
                                                ) {
                                                  setErrorMessage2(
                                                    "Please select an image, not a PDF."
                                                  )
                                                } else if (
                                                  selectedFile.type.startsWith(
                                                    "video/"
                                                  )
                                                ) {
                                                  setErrorMessage2(
                                                    "Please select an image, not a video."
                                                  )
                                                } else {
                                                  setErrorMessage2(
                                                    "Please select an image file."
                                                  )
                                                }
                                                // Clear the file input (optional)
                                                e.target.value = null
                                              } else {
                                                // Clear any previous error messages
                                                setErrorMessage2("")
                                              }
                                            }
                                          }}
                                        />
                                        <IconButton
                                          onClick={() =>
                                            inputVariantMedia.current.click()
                                          }
                                        >
                                          <AddAPhotoIcon />
                                        </IconButton>
                                      </IKContext>
                                    )}
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        "@media (max-width: 600px)": {
                                          fontSize: "0.5rem",
                                        },
                                        "@media (min-width: 601px) and (max-width: 960px)":
                                          {
                                            fontSize: "0.6rem",
                                          },
                                        "@media (min-width: 970px)": {
                                          fontSize: "0.7rem",
                                        },
                                      }}
                                      variant="contained"
                                      onClick={() => handleAddVariantProduct()}
                                      style={{ fontFamily: "outfit" }}
                                    >
                                      Add
                                    </Button>
                                  </th>
                                </tr>
                              </thead>
                            </Table>
                          </>
                        ) : null}
                        {variantCombinations?.length > 0 ? (
                          <>
                            <div className="variable-product-sub-value-sub-variation-added">
                              {" "}
                              <h6>Added Variant Products</h6>
                              <Table>
                                {variantCombinations?.map((item, index) => (
                                  <tbody>
                                    <tr>
                                      {item?.variantOptions?.map(item => (
                                        <>
                                          <th
                                            style={{
                                              textAlign: "center",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {/* {item.optionLabel} */}
                                            <TextField
                                              style={{
                                                textAlign: "center",
                                                width: "100px",
                                              }}
                                              id="outlined-read-only-input"
                                              label={capitalizeFirstLetter(
                                                item.variantLabel
                                              )}
                                              value={capitalizeFirstLetter(
                                                item.optionLabel
                                              )}
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                              size="small"
                                            />
                                          </th>
                                        </>
                                      ))}
                                      <th
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {/* <InputGroup>
                                          {" "}
                                          <Input
                                            style={{
                                              textAlign: "center",
                                              width: "100px",
                                            }}
                                            type="number"
                                            value={item.price}
                                            onChange={e => {
                                              const updatedCombinations = [
                                                ...variantCombinations,
                                              ]
                                              updatedCombinations[index].price =
                                                parseInt(e.target.value)

                                              setVariantCombinations(
                                                updatedCombinations
                                              )
                                            }}
                                            placeholder="Price"
                                          />
                                        </InputGroup> */}
                                        <TextField
                                          style={{
                                            textAlign: "center",
                                            width: "100px",
                                          }}
                                          type="number"
                                          size="small"
                                          label="Price"
                                          value={item.price}
                                          onChange={e => {
                                            const updatedCombinations = [
                                              ...variantCombinations,
                                            ]
                                            updatedCombinations[index].price =
                                              parseInt(e.target.value)

                                            setVariantCombinations(
                                              updatedCombinations
                                            )
                                          }}
                                        />
                                      </th>
                                      <th
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {/* <InputGroup>
                                          {" "}
                                          <Input
                                            style={{
                                              textAlign: "center",
                                              width: "100px",
                                            }}
                                            type="number"
                                            value={item.salePrice}
                                            onChange={e => {
                                              const updatedCombinations = [
                                                ...variantCombinations,
                                              ]
                                              updatedCombinations[
                                                index
                                              ].salePrice = parseInt(
                                                e.target.value
                                              )

                                              setVariantCombinations(
                                                updatedCombinations
                                              )
                                            }}
                                            placeholder="Sale Price"
                                          />
                                        </InputGroup> */}
                                        <TextField
                                          style={{
                                            textAlign: "center",
                                            width: "100px",
                                          }}
                                          type="number"
                                          size="small"
                                          label="Sale Price"
                                          value={item.salePrice}
                                          onChange={e => {
                                            const updatedCombinations = [
                                              ...variantCombinations,
                                            ]
                                            updatedCombinations[
                                              index
                                            ].salePrice = parseInt(
                                              e.target.value
                                            )

                                            setVariantCombinations(
                                              updatedCombinations
                                            )
                                          }}
                                        />
                                      </th>
                                      <th
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                          src={item.mainImage.url}
                                        />
                                      </th>
                                      <th>
                                        <IconButton
                                          onClick={() => {
                                            handleDeleteVariantProduct(item)
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </th>
                                    </tr>
                                  </tbody>
                                ))}
                              </Table>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="variable-product-sub-value-accordion"></div>
                      {/* <div>
                      <Button
                        sx={{
                          "@media (max-width: 600px)": {
                            fontSize: "0.5rem",
                          },
                          "@media (min-width: 601px) and (max-width: 960px)": {
                            fontSize: "0.6rem",
                          },
                          "@media (min-width: 970px)": {
                            fontSize: "0.7rem",
                          },
                        }}
                        variant="outlined"
                        // onClick={() => inputRefMainImage.current.click()}
                        style={{ fontFamily: "outfit" }}
                      >
                        Save variations
                      </Button>{" "}
                    </div> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="product-text-field">
            {/* <TableContainer component={Paper}>
            <Table aria-label="caption table">
             
              <TableHead>
                <TableRow>
                  <TableCell align="center">Addons</TableCell>
                  <TableCell align="center">Price</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    <Autocomplete
                      sx={{
                        "@media (max-width: 600px)": {
                          padding: 0,
                          minWidth: 200,
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          padding: 0,
                          minWidth: 250,
                        },
                        "@media (min-width: 970px)": {
                          padding: 0,
                          minWidth: 250,
                        },
                      }}
                      options={addons?.data || []}
                      fullWidth
                      size="small"
                      onChange={(event, newValue) => {
                        setModifierId(newValue?.id)
                        setModifierName(newValue?.label)
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select modfiers"
                          size="small"
                          sx={styleTextField}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      size="small"
                      sx={{
                        "@media (max-width: 600px)": {
                          padding: 0,
                          minWidth: 50,
                          "& .MuiInputBase-input": {
                            height: "40px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          padding: 0,
                          minWidth: 100,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 970px)": {
                          padding: 0,
                          minWidth: 150,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                      }}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={modifierPrice}
                      onChange={event => setModifierPrice(event.target.value)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FormControl
                      sx={{
                        "@media (max-width: 600px)": {
                          // m: 1,
                          minWidth: 100,
                          "& .MuiInputBase-input": {
                            height: "40px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          // m: 1,
                          minWidth: 100,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 970px)": {
                          // m: 1,
                          minWidth: 150,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                      }}
                    >
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={modifierType}
                        onChange={handleChange}
                        autoWidth
                        label="Type"
                      >
                        <MenuItem value="single">single</MenuItem>
                        <MenuItem value="multiple">multiple</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        if (modifierId && modifierPrice && modifierType) {
                          setModifierData([
                            ...modifierData,
                            {
                              modifierId,
                              price: parseInt(modifierPrice),
                              type: modifierType,
                              name: modifierName,
                            },
                          ])
                          // setModifierId("");
                          setModifierPrice(0)
                          setModifierType("")
                          setModifierName("")
                        }
                      }}
                    >
                      <AddCircleIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow> */}

            {/* {modifierData?.map((data, index) => {
                  // console.log("hasvdhcjdsgvj",data)
                  return (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row" align="center">
                          <TextField
                            sx={styleTextField}
                            value={data?.getModifiers?.name?.en || data?.name}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            sx={styleTextField}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={data?.price}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField sx={styleTextField} value={data?.type} />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              const newArr = [
                                ...modifierData.slice(0, index),
                                ...modifierData.slice(index + 1),
                              ]
                              // console.log("newArr:", newArr);

                              setModifierData(newArr)
                            }}
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer> */}

            {/* <div className="product-video-field">
            <h5>Video:</h5>
            {productVideo && productVideo.filePath ? (
              <div
                style={{
                  width: 35,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
                onMouseEnter={() => setIsHoveredIcon(true)}
                onMouseLeave={() => setIsHoveredIcon(false)}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <IKVideo
                    urlEndpoint={urlEndpoint}
                    // className="ikvideo-with-tr"
                    path={productVideo.filePath}
                    transformation={[{ height: 200, width: 200, q: 95 }]}
                    controls={true}
                    style={{ height: "100px", width: "150px" }}
                  />

                  {productVideo.filePath && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        zIndex: "1",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setProductVideo(null)
                      }}
                    >
                      <AiOutlineCloseCircle
                        size={16}
                        color="red"
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          // color: 'red',
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={authenticationEndpoint}
              >
                {videoUploading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  <IKUpload
                    onError={onErrorVideo}
                    onSuccess={onSuccessVideo}
                    onUploadStart={onUploadProgressVideo}
                    buttonProps={{ children: "+" }}
                  />
                )}
              </IKContext>
            )}
          </div> */}
          </div>
          <Box
            textAlign="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              marginTop: "50px",
            }}
          >
            {!loading ? (
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#190c33",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#190c33",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#190c33",
                  },
                }}
                onClick={() => {
                  setCategoryId("")
                  setSubCategoryId("")
                  setSearchCategory("")
                  setMenuId("")
                  navigate(`/products`)
                }}
                style={{ fontFamily: "outfit" }}
              >
                Back
              </Button>
            ) : (
              ""
            )}
            {loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                // onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>Save</span>
              </LoadingButton>
            ) : imageUploading || multipleUploading ? (
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                }}
                disabled
                style={{ fontFamily: "outfit" }}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                }}
                onClick={handleSubmit(addNewProduct)}
                style={{ fontFamily: "outfit" }}
              >
                Save
              </Button>
            )}
          </Box>
        </form>
      </div>
    </div>
  )
}

export default AddProduct

const styleTextField = {
  "@media (max-width: 600px)": {
    "& .MuiInputBase-input": {
      height: "40px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 970px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
}
