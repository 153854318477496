import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import Autocomplete from "@mui/material/Autocomplete"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"

import "../../css/subcategories.css"
import { useProduct } from "common/Services/product"

const AddSubCategories = ({
  providerId,
  setOpen,
  edit,
  specificSubCategory = null,
  refetch,
  setShowSuccessAdded,
  setShowError,
  handleClose,
}) => {
  const {
    subCategoryUpdate,
    subCategoryAdd,
    setSearchCategory,
    searchCategory,
    categoriesSettingData,
    categorySearchData,
    categoryId,
    setCategoryId,
    subCategoryDatCount,
    subCategoryCountIsLoading,
    subCategoryCountRefetch,
  } = useProduct()
  const [loading, setLoading] = React.useState(false)

  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  async function addNewSubCategory(data) {
    if (edit) {
      setLoading(true)

      try {
        if (categoryId) {
          const result = await subCategoryUpdate({
            item: specificSubCategory,
            data,
            categoryId,
          })
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
          setLoading(false)
          setOpen(false)
          refetch()
          subCategoryCountRefetch()
          setSearchCategory("")
        } else {
          setLoading(false)
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      } catch (error) {
        setLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      setLoading(true)
      try {
        if (categoryId) {
          const result = await subCategoryAdd({ providerId, data, categoryId })
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
          setLoading(false)
          setOpen(false)
          refetch()
          subCategoryCountRefetch()
          setSearchCategory("")
        } else {
          setLoading(false)
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      } catch (e) {
        setLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    }
  }

  useEffect(() => {
    setValue("englishName", specificSubCategory?.name?.en)
    setCategoryId(specificSubCategory?.categoryId)
  }, [specificSubCategory])

  return (
    <div style={{ marginTop: "156" }}>
      <h5 className="subCategory-edit-title">
        {edit ? `Edit Sub Category` : `Add New Sub Category`}
      </h5>{" "}
      <form>
        <div className="subCategory-text-field">
          <div className="subCategory-text-field-row">
            <TextField
              // sx={styleTextField}
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="subCategory-text-field">
          <Autocomplete
            options={categorySearchData || []}
            fullWidth
            size="small"
            value={categoriesSettingData?.label || ""}
            onChange={(event, newValue) => {
              setCategoryId(newValue?.id)
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Category"
                size="small"
                // sx={styleTextField}

                onChange={newValue =>
                  setSearchCategory(newValue?.target?.value)
                }
              />
            )}
          />
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!loading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => handleClose()}
              style={{ fontFamily: "outfit" }}
            >
              Close
            </Button>
          ) : (
            ""
          )}

          {loading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewSubCategory)}
              style={{ fontFamily: "outfit" }}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddSubCategories
const styleTextField = {
  "@media (max-width: 600px)": {
    "& .MuiInputBase-input": {
      height: "40px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 970px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
}
