import dayjs from "dayjs"

export const formatDate = date => {
  return dayjs(date).format("MMMM D, YYYY h:mm A")
}
export const formatDateTime = date => {
  return dayjs(date).format("MMM D,h:mm A")
}
export const formatDateOnly = date => {
  return dayjs(date).format("MMMM D, YYYY")
}
export const formatCurrency = amount => {
  return `₹ ${amount}`
}

export const format24ToTime = time24 => {
  return dayjs(time24, "HH:mm").format("h:mm A")
}
