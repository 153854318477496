import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import "../../css/orders.css"
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { useNavigate } from "react-router-dom"
import { LottieLoading } from "../../common/LottieLoading"
import { IconButton, Tooltip } from "@mui/material"
import CachedIcon from "@mui/icons-material/Cached"
import CircularProgress from "@mui/material/CircularProgress"
import { formatDate, formatCurrency } from "common/Services/functions"
import Paper from "@mui/material/Paper"
import { Stack } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import StorefrontIcon from "@mui/icons-material/Storefront"
import moment from "moment"
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap"
import { useCurrentProvider, useOrdersBtwDates } from "common/Hooks/queryCalls"
import TextField from "@mui/material/TextField"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import { useOrder } from "common/Services/order"
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  fontSize: "0.5rem",
  display: "flex",
  justifyContent: "center",
  gap: "50px",
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const Orders = () => {
  const {
    ongoingOrdersData,
    ongoingOrdersIsLoading,
    ongoingOrdersRefetch,
    selectedStatus,
    selectedPaymentStatus,
    setSelectedStatus,
    setSelectedPaymentStatus,

    setStartDate,

    setEndDate,
    completedOrdersData,
    completedOrdersIsLoading,

    setCompletedStartDate,

    setCompletedEndDate,
  } = useOrder()
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [btnprimary2, setBtnprimary2] = useState(false)
  const [syncLoading, setSyncLoading] = useState(false)
  const [firstDate, setFirstDate] = useState()
  const [lastDate, setLastDate] = useState()
  const [completedFirstDate, setCompletedFirstDate] = useState()
  const [completedLastDate, setCompletedLastDate] = useState()

  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  const currentProvider = useCurrentProvider()

  const handleDateChange = event => {
    setStartDate(firstDate)
    setEndDate(lastDate)
  }
  const handleClearDateChange = event => {
    setStartDate("")
    setFirstDate("")
    setLastDate("")
    setEndDate("")
  }
  const handleClearCompletedDate = event => {
    setCompletedStartDate("")
    setCompletedEndDate("")
    setCompletedFirstDate("")
    setCompletedLastDate("")
  }

  const handleCompletedDateChange = event => {
    setCompletedStartDate(completedFirstDate)
    setCompletedEndDate(completedLastDate)
  }

  const openPrice = Boolean(anchorEl)
  const handleDateClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleDateClose = () => {
    setAnchorEl(null)
  }

  // console.log("ongoingordersData=====", ongoingOrdersData)
  // console.log("completedOrdersData=====", completedOrdersData)

  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  if (completedOrdersIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  if (ongoingOrdersIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  return (
    <>
      <div className="page-title-box">
        <div>
          {" "}
          <h6 className="page-title">Order List</h6>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item active">
              Exploring Your Order History
            </li>
          </ol>
        </div>
      </div>

      <div className="orders-div">
        <div className="orders-list">
          <Box
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                bgcolor: "background.paper",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                bgcolor: "background.paper",
              },
              "@media (min-width: 970px)": {
                fontSize: "1rem",
                bgcolor: "background.paper",
              },
            }}
            style={{ marginTop: "20px" }}
          >
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{ backgroundColor: "#333547" }}
              >
                <Tab
                  sx={tableHeadStyle}
                  label="Ongoing Orders"
                  {...a11yProps(0)}
                  style={{ fontFamily: "outfit" }}
                />
                <Tab
                  sx={tableHeadStyle}
                  label="Completed Orders"
                  {...a11yProps(1)}
                  style={{ fontFamily: "outfit" }}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {/* {ongoingOrders?.data?.length == 0 && (
                  <caption style={{ display: "flex", fontFamily: "outfit" }}>
                    No Ongoing Orders Available
                  </caption>
                )} */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      flexWrap: "wrap",
                      paddingBottom: "10px",
                    }}
                  >
                    {" "}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {" "}
                      <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                        {" "}
                        Order Status
                      </span>
                      <div className="btn-group">
                        <Dropdown
                          isOpen={btnprimary1}
                          toggle={() => setBtnprimary1(!btnprimary1)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn "
                            style={{
                              backgroundColor: "#333547",
                              color: "white",
                            }}
                          >
                            {`${selectedStatus.replace(
                              /^./,
                              selectedStatus[0].toUpperCase()
                            )} ( ${ongoingOrdersData?.length} )`}{" "}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => setSelectedStatus("all")}
                            >
                              All
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setSelectedStatus("pending")}
                            >
                              Pending
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setSelectedStatus("accepted")}
                            >
                              Accepted
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setSelectedStatus("ready")}
                            >
                              Ready
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setSelectedStatus("cancelled")}
                            >
                              cancelled
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>{" "}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {" "}
                      <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                        Payment Status
                      </span>
                      <div className="btn-group">
                        <Dropdown
                          isOpen={btnprimary2}
                          toggle={() => setBtnprimary2(!btnprimary2)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn "
                            style={{
                              backgroundColor: "#333547",
                              color: "white",
                            }}
                          >
                            {`${selectedPaymentStatus.replace(
                              /^./,
                              selectedPaymentStatus[0].toUpperCase()
                            )} `}{" "}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {" "}
                            <DropdownItem
                              onClick={() =>
                                setSelectedPaymentStatus("pending")
                              }
                            >
                              Pending
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setSelectedPaymentStatus("paid")}
                            >
                              Paid
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>{" "}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {" "}
                      <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                        Date
                      </span>
                      <div className="btn-group">
                        <Button
                          id="basic-button"
                          aria-controls={openPrice ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openPrice ? "true" : undefined}
                          onClick={handleDateClick}
                          variant="contained"
                          endIcon={<KeyboardArrowDownIcon />}
                          sx={{
                            "@media (max-width: 600px)": {
                              // fontSize: "0.6rem",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              backgroundColor: "#333547",
                              height: "35px",
                              "&:hover": {
                                backgroundColor: "#333547",
                              },
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                // fontSize: "0.8rem",
                                fontWeight: "400",
                                textTransform: "capitalize",
                                backgroundColor: "#333547",
                                height: "35px",
                                "&:hover": {
                                  backgroundColor: "#333547",
                                },
                              },
                            "@media (min-width: 970px)": {
                              // fontSize: "0.8rem",
                              fontWeight: "400",
                              textTransform: "capitalize",
                              backgroundColor: "#333547",
                              height: "35px",
                              "&:hover": {
                                backgroundColor: "#333547",
                              },
                            },
                          }}
                        >
                          Date
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openPrice}
                          onClose={handleDateClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></MenuItem>
                          <MenuItem>
                            <label style={{ fontFamily: "outfit" }}>
                              Start Date:
                              <input
                                type="date"
                                value={firstDate}
                                onChange={event =>
                                  setFirstDate(event.target.value)
                                }
                                className="date-input"
                              />
                            </label>
                          </MenuItem>
                          <MenuItem>
                            <label style={{ fontFamily: "outfit" }}>
                              End Date:
                              <input
                                type="date"
                                value={lastDate}
                                onChange={event =>
                                  setLastDate(event.target.value)
                                }
                                className="date-input"
                              />
                            </label>
                          </MenuItem>
                          <MenuItem onClick={handleDateClose}>
                            <Button
                              variant="contained"
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.8rem",
                                  marginLeft: "5px",
                                  textTransform: "capitalize",
                                  backgroundColor: "#333547",
                                  fontWeight: "400",
                                  height: "35px",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.8rem",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                    backgroundColor: "#333547",
                                    fontWeight: "400",
                                    height: "35px",
                                    "&:hover": {
                                      backgroundColor: "#535EB4",
                                    },
                                  },
                                "@media (min-width: 970px)": {
                                  fontSize: "0.8rem",
                                  marginLeft: "5px",
                                  textTransform: "capitalize",
                                  backgroundColor: "#333547",
                                  fontWeight: "400",
                                  height: "35px",
                                  "&:hover": {
                                    backgroundColor: "#535EB4",
                                  },
                                },
                              }}
                              onClick={handleDateChange}
                            >
                              Search
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.8rem",
                                  marginLeft: "5px",
                                  textTransform: "capitalize",
                                  backgroundColor: "#333547",
                                  fontWeight: "400",
                                  height: "35px",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.8rem",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                    backgroundColor: "#333547",
                                    fontWeight: "400",
                                    height: "35px",
                                    "&:hover": {
                                      backgroundColor: "#535EB4",
                                    },
                                  },
                                "@media (min-width: 970px)": {
                                  fontSize: "0.8rem",
                                  marginLeft: "5px",
                                  textTransform: "capitalize",
                                  backgroundColor: "#333547",
                                  fontWeight: "400",
                                  height: "35px",
                                  "&:hover": {
                                    backgroundColor: "#535EB4",
                                  },
                                },
                              }}
                              onClick={handleClearDateChange}
                            >
                              Clear
                            </Button>
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Box
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      overflowY: "scroll",
                      maxHeight: "calc(90vh - 100px)",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      overflowY: "scroll",
                      maxHeight: "calc(90vh - 100px)",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "1rem",
                      overflowY: "scroll",
                      maxHeight: "calc(90vh - 100px)",
                    },
                  }}
                >
                  {ongoingOrdersData?.length == 0 ? (
                    <caption
                      style={{
                        display: "flex",
                        fontFamily: "outfit",
                        height: "100px",
                      }}
                    >
                      No Ongoing Orders Available
                    </caption>
                  ) : (
                    ongoingOrdersData?.map(item => {
                      return (
                        <div
                          key={item._id}
                          style={{ margin: "5px" }}
                          onClick={() => {
                            navigate(`/${item?._id}/order-details`)
                          }}
                        >
                          <Card
                            sx={{
                              "& .MuiCardContent-root:last-child": {
                                paddingBottom: "10px",
                              },
                            }}
                          >
                            <CardContent
                              className="order_main_content"
                              sx={{ padding: "2px" }}
                            >
                              <CardContent className="order_card_content_1">
                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "1rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {item ? item.refNumber : null}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {formatDate(item?.date)}
                                </Typography>

                                <div>
                                  <p className="item_detail_title">
                                    Item Details
                                  </p>
                                  {item?.items
                                    ? item?.items?.map(itm => {
                                        return (
                                          <p
                                            className="item_detail_des"
                                            key={itm?._id}
                                          >
                                            {itm?.name?.en} x {itm?.quantity}
                                          </p>
                                        )
                                      })
                                    : null}
                                </div>
                                {item.orderType === "inStorePickup" ? (
                                  <div>
                                    <p className="item_detail_title">
                                      <StorefrontIcon /> In Store Pickup
                                    </p>
                                  </div>
                                ) : null}
                              </CardContent>

                              <CardContent
                                sx={{
                                  "@media (max-width: 600px)": {
                                    margin: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      margin: 0,
                                      display: "flex",
                                      flexDirection: "column",
                                    },
                                  "@media (min-width: 960px)": {
                                    margin: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                  },
                                }}
                                className="order_card_content_3"
                              >
                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "1rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {`Order Status : ${
                                    item ? item.status : null
                                  }`}
                                </Typography>

                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {`${item?.customer?.firstName} ${item?.customer?.lastName}`}
                                </Typography>

                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {`+91  ${item?.customer?.number}`}
                                </Typography>
                                <div className="total_amt_div">
                                  <p className="total_amt_title">
                                    Total Amount
                                  </p>
                                  <p className="total_amt_des">
                                    {formatCurrency(
                                      item?.payments?.amountPayable
                                    )}
                                  </p>
                                </div>
                              </CardContent>
                            </CardContent>
                          </Card>
                        </div>
                      )
                    })
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {/* {completedOrders?.data?.length == 0 && (
                  <caption style={{ display: "flex", fontFamily: "outfit" }}>
                    No Completed Orders Available
                  </caption>
                )} */}
                <Box
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      maxHeight: "calc(90vh - 100px)",
                      overflowY: "scroll",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      maxHeight: "calc(90vh - 100px)",
                      overflowY: "scroll",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "1rem",
                      maxHeight: "calc(90vh - 100px)",
                      overflowY: "scroll",
                    },
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        paddingBottom: "10px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {" "}
                        <span
                          style={{ textAlign: "center", fontSize: "0.7rem" }}
                        >
                          Date
                        </span>
                        <div className="btn-group">
                          <Button
                            id="basic-button"
                            aria-controls={openPrice ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openPrice ? "true" : undefined}
                            onClick={handleDateClick}
                            variant="contained"
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                              "@media (max-width: 600px)": {
                                // fontSize: "0.6rem",
                                fontWeight: "400",
                                textTransform: "capitalize",
                                backgroundColor: "#333547",
                                height: "35px",
                                "&:hover": {
                                  backgroundColor: "#333547",
                                },
                              },
                              "@media (min-width: 601px) and (max-width: 960px)":
                                {
                                  // fontSize: "0.8rem",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  backgroundColor: "#333547",
                                  height: "35px",
                                  "&:hover": {
                                    backgroundColor: "#333547",
                                  },
                                },
                              "@media (min-width: 970px)": {
                                // fontSize: "0.8rem",
                                fontWeight: "400",
                                textTransform: "capitalize",
                                backgroundColor: "#333547",
                                height: "35px",
                                "&:hover": {
                                  backgroundColor: "#333547",
                                },
                              },
                            }}
                          >
                            Date
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openPrice}
                            onClose={handleDateClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></MenuItem>
                            <MenuItem>
                              <label style={{ fontFamily: "outfit" }}>
                                Start Date:
                                <input
                                  type="date"
                                  value={completedFirstDate}
                                  onChange={event =>
                                    setCompletedFirstDate(event.target.value)
                                  }
                                  className="date-input"
                                />
                              </label>
                            </MenuItem>
                            <MenuItem>
                              <label style={{ fontFamily: "outfit" }}>
                                End Date:
                                <input
                                  type="date"
                                  value={completedLastDate}
                                  onChange={event =>
                                    setCompletedLastDate(event.target.value)
                                  }
                                  className="date-input"
                                />
                              </label>
                            </MenuItem>
                            <MenuItem onClick={handleDateClose}>
                              <Button
                                variant="contained"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.8rem",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                    backgroundColor: "#333547",
                                    fontWeight: "400",
                                    height: "35px",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                      marginLeft: "5px",
                                      textTransform: "capitalize",
                                      backgroundColor: "#333547",
                                      fontWeight: "400",
                                      height: "35px",
                                      "&:hover": {
                                        backgroundColor: "#535EB4",
                                      },
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.8rem",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                    backgroundColor: "#333547",
                                    fontWeight: "400",
                                    height: "35px",
                                    "&:hover": {
                                      backgroundColor: "#535EB4",
                                    },
                                  },
                                }}
                                onClick={handleCompletedDateChange}
                              >
                                Search
                              </Button>

                              <Button
                                variant="contained"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.8rem",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                    backgroundColor: "#333547",
                                    fontWeight: "400",
                                    height: "35px",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                      marginLeft: "5px",
                                      textTransform: "capitalize",
                                      backgroundColor: "#333547",
                                      fontWeight: "400",
                                      height: "35px",
                                      "&:hover": {
                                        backgroundColor: "#535EB4",
                                      },
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.8rem",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                    backgroundColor: "#333547",
                                    fontWeight: "400",
                                    height: "35px",
                                    "&:hover": {
                                      backgroundColor: "#535EB4",
                                    },
                                  },
                                }}
                                onClick={handleClearCompletedDate}
                              >
                                Clear
                              </Button>
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                  {completedOrdersData?.length == 0 ? (
                    <caption style={{ display: "flex", fontFamily: "outfit" }}>
                      No Completed Orders Available
                    </caption>
                  ) : (
                    completedOrdersData?.map(item => {
                      return (
                        <div
                          key={item._id}
                          style={{ margin: "5px" }}
                          onClick={() => {
                            navigate(`/${item?._id}/order-details`)
                          }}
                        >
                          <Card
                            sx={{
                              "& .MuiCardContent-root:last-child": {
                                paddingBottom: "10px",
                              },
                            }}
                          >
                            <CardContent
                              className="order_main_content"
                              sx={{ padding: "2px" }}
                            >
                              <CardContent className="order_card_content_1">
                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "1rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {item ? item.refNumber : null}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {formatDate(item?.date)}
                                </Typography>
                                <div>
                                  <p className="item_detail_title">
                                    Item Details
                                  </p>
                                  {item?.items
                                    ? item?.items?.map(itm => {
                                        return (
                                          <p
                                            className="item_detail_des"
                                            key={itm?._id}
                                          >
                                            {itm?.name?.en} x {itm?.quantity}
                                          </p>
                                        )
                                      })
                                    : null}
                                </div>
                                {item.orderType === "inStorePickup" ? (
                                  <div>
                                    <p className="item_detail_title">
                                      <StorefrontIcon /> In Store Pickup
                                    </p>
                                  </div>
                                ) : null}
                              </CardContent>
                              <CardContent
                                sx={{
                                  "@media (max-width: 600px)": {
                                    margin: 0,
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      margin: 0,
                                      display: "flex",
                                      flexDirection: "column-reverse",
                                    },
                                  "@media (min-width: 960px)": {
                                    margin: 0,
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                  },
                                }}
                                className="order_card_content_3"
                              >
                                {/* <Button
                                onClick={() => {
                                  window.location.assign(
                                    `${window.location.origin}/${item?._id}/order-details`
                                  )
                                }}
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.65rem",
                                    textAlign: "center",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                      textAlign: "center",
                                    },
                                  "@media (min-width: 960px)": {
                                    fontSize: "0.8rem",
                                    textAlign: "center",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                                variant="contained"
                                startIcon={<AdsClickIcon />}
                              >
                                view details
                              </Button> */}
                                <div className="total_amt_div">
                                  <p className="total_amt_title">
                                    Total Amount
                                  </p>
                                  <p className="total_amt_des">
                                    {formatCurrency(
                                      item?.payments?.amountPayable
                                    )}
                                  </p>
                                </div>
                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {`+91  ${item?.customer?.number}`}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  component="div"
                                  className="order_no"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.6rem",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.8rem",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                    },
                                  }}
                                  style={{ fontFamily: "outfit" }}
                                >
                                  {`${item?.customer?.firstName} ${item?.customer?.lastName}`}
                                </Typography>
                              </CardContent>
                            </CardContent>
                            {/* <CardActions className="pickup_btn">view details</CardActions> */}
                          </Card>
                        </div>
                      )
                    })
                  )}
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </div>
        {value === 1 ? (
          ""
        ) : (
          <div
            style={{
              // width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              margin: "0px",
              padding: "0px",
              position: "fixed",
              right: "25px",
              bottom: "5vh",
            }}
          >
            {syncLoading ? (
              <CircularProgress
                size={35}
                sx={{
                  marginRight: "10px",
                  marginTop: "4px",
                  color: " rgb(51, 53, 71)",
                }}
              />
            ) : (
              <>
                <Tooltip title="Fetch New Orders">
                  <IconButton
                    sx={{
                      backgroundColor: "rgb(51, 53, 71)",
                      height: "45px",
                      width: "45px",
                    }}
                    onClick={() => {
                      setSyncLoading(true)
                      ongoingOrdersRefetch()
                      setTimeout(() => {
                        setSyncLoading(false)
                      }, 2000)
                    }}
                  >
                    <CachedIcon fontSize="large" sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Orders

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
}
