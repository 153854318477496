import React, { useState, useRef, useEffect } from "react"
import "../../css/services.css"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import CircularProgress from "@mui/material/CircularProgress"
import { useNavigate, useParams } from "react-router-dom"
import { IKContext, IKImage, IKUpload, IKVideo } from "imagekitio-react"
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../components/ImageKit/ImageKitCred"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import {
  useCurrentProvider,
  useServiceCategoryData,
  useServiceCategorySearch,
  useOneService,
} from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import Autocomplete from "@mui/material/Autocomplete"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { TimeField } from "@mui/x-date-pickers/TimeField"
import { addService, updateService } from "common/Services/dbServices"
import dayjs from "dayjs"
const AddServices = () => {
  const inputRefMainImage = useRef(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [resIcon, setResIcon] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [searchCategory, setSearchCategory] = useState("")
  const [categoryId, setCategoryId] = useState(null)
  const [duration, SetDuration] = useState(null)
  const [durationError, setDurationError] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const [errorMessage, setErrorMessage] = useState("")
  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    arabicName: Yup.string().required("اسم is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price is required"),
  })
  const [checked, setChecked] = React.useState(false)
  const currentProvider = useCurrentProvider()
  const handleChange = event => {
    setChecked(event.target.checked)
  }
  const serviceCategorySettingData = useServiceCategoryData({ categoryId })
  const serviceCategorySearchData = useServiceCategorySearch({
    search: searchCategory,
    providerId: currentProvider?.data?._id,
  })
  const {
    data,
    isLoading: isLoadingData,
    refetch,
    isSuccess,
  } = useOneService({
    id,
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewService = async data => {
    // console.log("data:", data)
    if (id) {
      setIsLoading(true)
      if (durationError === false) {
        try {
          const result = await updateService({
            itemId: id,
            data,
            resIcon,
            isActive: checked,
            categoryId,
            duration,
          })
          // console.log("update:", result)
          setIsLoading(false)
          refetch()
          navigate("/services")
        } catch (error) {
          setIsLoading(false)
          console.log(error)
        }
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(true)
      if (durationError === false) {
        try {
          const result = await addService({
            providerId: currentProvider?.data?._id,
            data,
            resIcon,
            isActive: checked,
            categoryId,
            duration,
          })
          // console.log("add:", result)
          setIsLoading(false)
          navigate("/services")
        } catch (error) {
          setIsLoading(false)
          // console.log(error)
        }
      } else {
        setIsLoading(false)
      }
    }
  }
  const onErrorIcon = err => {
    setImageUploading(false)
    setImageShowError(true)
  }

  const onSuccessIcon = res => {
    // console.log("resImage:", res)
    setResIcon(res)
    setImageUploading(false)
  }

  const onUploadProgress = progress => {
    if (progress) {
    }
    setImageUploading(true)
  }

  const convertToDateTime = timeObject => {
    if (timeObject) {
      const currentTime = dayjs()
        .hour(timeObject.HH)
        .minute(timeObject.MM)
        .second(0)
        .millisecond(0)
      SetDuration(currentTime)
    }
  }

  useEffect(() => {
    setValue("englishName", data?.name?.en)
    setValue("arabicName", data?.name?.ar)
    setValue("englishDes", data?.description?.en)
    setValue("arabicDes", data?.description?.ar)
    setValue("price", data?.price)
    setValue("salePrice", data?.salePrice)
    setResIcon(data?.image)
    setChecked(data?.isActive)
    setCategoryId(data?.categoryId)
    convertToDateTime(data?.duration)
  }, [isSuccess])

  if (isLoadingData) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  return (
    <div className="service-div">
      <h5 className="service-edit-title">
        {id ? `Edit Service` : `Add New Service`}
      </h5>{" "}
      <form>
        <div className="service-text-field">
          <div className="service-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
            <TextField
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="service-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Description"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("englishDes", { required: true })}
              error={errors.englishDes ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishDes && errors.englishDes?.message
                ? errors.englishDes?.message.toString()
                : null}
            </Typography>
            <TextField
              fullWidth
              label="وصف"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("arabicDes", { required: true })}
              error={errors.arabicDes ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicDes && errors.arabicDes?.message
                ? errors.arabicDes?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="service-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Price"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("price", { required: true })}
              error={errors.price ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.price && errors.price?.message
                ? errors.price?.message.toString()
                : null}
            </Typography>
            <TextField
              fullWidth
              label="Sale Price"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("salePrice", { required: true })}
              error={errors.salePrice ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.salePrice && errors.salePrice?.message
                ? errors.salePrice?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="service-text-field-row">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                format="HH:mm"
                fullWidth
                label="Duration(HH:MM)"
                size="small"
                value={duration}
                onChange={newValue => {
                  if (
                    (new Date(newValue)?.getHours() === 0 ||
                      new Date(newValue)?.getHours()) &&
                    (new Date(newValue)?.getMinutes() === 0 ||
                      new Date(newValue)?.getMinutes())
                  ) {
                    SetDuration(newValue)
                    setDurationError(false)
                  } else {
                    setDurationError(true)
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
            <Autocomplete
              options={serviceCategorySearchData?.data || []}
              fullWidth
              size="small"
              value={serviceCategorySettingData?.data?.label || ""}
              onChange={(event, newValue) => {
                setCategoryId(newValue?.id)
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Service Category"
                  size="small"
                  onChange={newValue =>
                    setSearchCategory(newValue.target.value)
                  }
                />
              )}
            />
          </div>

          <div>
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label="Is Active"
              labelPlacement="start"
            />
          </div>

          <div className="service-media-div">
            <div className="service-image-field">
              <h6 style={{ display: "flex", flexWrap: "wrap", width: "50px" }}>
                Image:
              </h6>

              {resIcon && resIcon.filePath ? (
                <div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <IKImage
                      urlEndpoint={urlEndpoint}
                      path={resIcon.filePath}
                      style={{
                        width: "calc(150px - 30px)",
                        height: "100px",
                        borderRadius: "10px",
                      }}
                    />
                    {resIcon.filePath && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingTop: "2px",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.6rem",
                                backgroundColor: "#040D12",
                                width: "100%",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                          }}
                          onClick={() => {
                            //  setImage(null);
                            setResIcon(null)
                          }}
                          style={{ fontFamily: "outfit" }}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <IKContext
                  publicKey={publicKey}
                  urlEndpoint={urlEndpoint}
                  authenticationEndpoint={authenticationEndpoint}
                >
                  {imageUploading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    <>
                      <IKUpload
                        onError={onErrorIcon}
                        onSuccess={onSuccessIcon}
                        onUploadStart={onUploadProgress}
                        buttonProps={{ children: "+" }}
                        inputRef={inputRefMainImage}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={e => {
                          // Check if the selected file is an image
                          const selectedFile = e.target.files[0]
                          if (selectedFile) {
                            if (!selectedFile.type.startsWith("image/")) {
                              // Check the file type and show an appropriate error message
                              if (
                                selectedFile.type.startsWith("application/pdf")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a PDF."
                                )
                              } else if (
                                selectedFile.type.startsWith("video/")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a video."
                                )
                              } else {
                                setErrorMessage("Please select an image file.")
                              }
                              // Clear the file input (optional)
                              e.target.value = null
                            } else {
                              // Clear any previous error messages
                              setErrorMessage("")
                            }
                          }
                        }}
                      />
                      <div
                        className="image-upload-btn-div"
                        onClick={() => inputRefMainImage.current.click()}
                      >
                        <FileUploadIcon />
                        <Button
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.8rem",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.8rem",
                            },
                          }}
                          variant="standard"
                          // onClick={() => inputRefMainImage.current.click()}
                          style={{ fontFamily: "outfit" }}
                        >
                          Upload
                        </Button>
                      </div>
                    </>
                  )}
                </IKContext>
              )}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : !duration || imageUploading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              disabled
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewService)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddServices
