import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
  Alert,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

// import images
import logoSm from "../../assets/images/logo-sm.png"
import {
  createNewTechnicians,
  registerDetails,
} from "common/Services/dbServices"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { app } from "common/Services/dbServices"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"

import { LottieLoading } from "common/LottieLoading"
import { usePrefixCheck } from "common/Hooks/queryCalls"
import Select from "react-select"

const Register = props => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [formError, setFormError] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [existingError, setExistingError] = useState(false)
  const [allProviderData, setAllProviderData] = useState([])
  const [providerLoading, setProviderLoading] = useState(false)
  const [type, setType] = useState(null)

  // const allData = async () => {
  //   try {
  //     const allProvider = await checkRegisterDetails()
  //     setAllProviderData(allProvider)
  //     setProviderLoading(false)
  //   } catch (error) {
  //     setProviderLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   setProviderLoading(true)
  //   allData()
  // }, [])
  // console.log("data:", allProviderData)
  // console.log("loading state:", providerLoading)

  const dispatch = useDispatch()

  const { user } = useSelector(state => ({
    user: state.Account.user,
  }))

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
      phoneNumber: "",
      description: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Business Name"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(6, "Password must have at least 6 characters"),
      phoneNumber: Yup.string().required("Please Enter Your Number"),
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        "Phone number is not valid"
      ),
      description: Yup.string().required(
        "Please Enter Your Business Description"
      ),
    }),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation.validationSchema),
  })

  const userName = validation.values.username
  const email = validation.values.email
  const password = validation.values.password
  const phoneNumber = validation.values.phoneNumber
  const description = validation.values.description

  const emailCheck = usePrefixCheck({ fieldValue: email })
  const numberCheck = usePrefixCheck({ fieldValue: phoneNumber })

  // console.log("checking:", emailCheck?.data, numberCheck?.data)

  async function onSignUp(data) {
    setFormError(false)
    setLoginError(false)
    setLoading(true)
    if (emailCheck?.data?.length > 0 || numberCheck?.data?.length > 0) {
      setLoading(false)
      setExistingError(true)
      setTimeout(() => {
        setExistingError(false)
      }, 5000)
    } else {
      if (userName && email && password && phoneNumber && type) {
        try {
          const result = await registerDetails({
            email,
            userName,
            phoneNumber,
            description,
            type,
          })

          if (result) {
            await app.emailPasswordAuth.registerUser({ email, password })
            setTimeout(() => {
              navigate("/login")
              setLoading(false)
            }, 3000)
          }
        } catch (error) {
          // alert(error)
          setLoading(false)
          setLoginError(true)
          setTimeout(() => {
            setLoginError(false)
          }, 5000)
        }
      } else {
        // alert("Please Complete Form")
        setLoading(false)
        setFormError(true)
        setTimeout(() => {
          setFormError(false)
        }, 5000)
      }
    }
  }

  const options = [
    { type: "e-commerce", value: 1, label: "Online Retailers" },
    { type: "booking-services", value: 2, label: "Spa and Salon Appointments" },
    { type: "booking-services", value: 3, label: "Healthcare Appointments" },
  ]

  document.title = "Register | Merche"
  if (providerLoading) {
    return <LottieLoading />
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            {loginError ? (
              <Alert color="danger">Sorry,there was a problem</Alert>
            ) : (
              ""
            )}

            {formError ? <Alert color="danger">Required All Fields</Alert> : ""}
            {existingError ? (
              <Alert color="danger">
                Sorry this email or number is already in use!
              </Alert>
            ) : (
              ""
            )}
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div
                    className="text-primary text-center p-4"
                    style={{ backgroundColor: "#333547" }}
                  >
                    <h5 className="text-white font-size-20">Register</h5>
                    {/* <p className="text-white-50">
                      Get your free Merche account now.
                    </p> */}
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    <Form
                      className="mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">
                          Name of Your Business*
                        </Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter Name of Your Business"
                          type="text"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="description">
                          Description of Your Business*
                        </Label>
                        <Input
                          name="description"
                          className="form-control"
                          placeholder="Short Bio About Your Business"
                          type="text"
                          id="description"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                              ? true
                              : false
                          }
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="type">
                          Choose Type Of Your Business*
                        </Label>
                        <Select
                          id="type"
                          options={options}
                          onChange={select => {
                            setType(select?.type)
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="phoneNumber">
                          Phone Number*
                        </Label>
                        <Input
                          name="phoneNumber"
                          className="form-control"
                          placeholder="Phone Number"
                          type="text"
                          id="phoneNumber"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneNumber || ""}
                          invalid={
                            validation.touched.phoneNumber &&
                            validation.errors.phoneNumber
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phoneNumber}
                          </FormFeedback>
                        ) : null}
                        {/* {numberCheck?.data?.length > 0 ? (
                          <span style={{ color: "red" }}>
                            Number already in use
                          </span>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="useremail">
                          Email*
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="useremail"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                        {/* {emailCheck?.data?.length > 0 ? (
                          <span style={{ color: "red" }}>
                            Email already in use
                          </span>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          Password*
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          id="userpassword"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          {loading ? (
                            <LoadingButton
                              color="secondary"
                              loading
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="contained"
                              className="btn btn-primary w-md waves-effect waves-light"
                            >
                              <span> Register</span>
                            </LoadingButton>
                          ) : password?.length >= 6 ? (
                            <button
                              onClick={onSignUp}
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Register
                            </button>
                          ) : (
                            <LoadingButton
                              loading
                              loadingIndicator=" Register"
                              variant="outlined"
                            >
                              Register
                            </LoadingButton>
                          )}
                        </div>
                      </div>
                      <div className="mt-2 mb-0 row">
                        {/* <div className="col-12 mt-4">
                          <p className="mb-0">
                            By registering you agree to the Veltrix{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div> */}
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link
                    to="/login"
                    className="fw-medium "
                    style={{ color: "#333547" }}
                  >
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                {/* <p>
                  © {new Date().getFullYear()} Merche. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Coderythm
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
