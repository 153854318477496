import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return <></>
  // return (
  //   <React.Fragment>
  //     <footer className="footer">
  //       <Container fluid={true}>
  //         <Row>
  //           <div className="col-12">
  //             © {new Date().getFullYear()} Merche<span className="d-none d-sm-inline-block"> - Crafted
  //             {" "} by CodeRythm.</span>
  //           </div>
  //         </Row>
  //       </Container>
  //     </footer>
  //   </React.Fragment>
  // )
}

export default Footer
