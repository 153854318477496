import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { Button, IconButton } from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import EditNoteIcon from "@mui/icons-material/EditNote"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import AddSubCategories from "./AddSubCategories"
import AddCategories from "pages/Categories/AddCategories"
import { LottieLoading } from "../../common/LottieLoading"
import SaveIcon from "@mui/icons-material/Save"
import { LoadingButton } from "@mui/lab"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import { useProduct } from "common/Services/product"
import { Row, Col } from "reactstrap"
export const SubCategories = () => {
  const {
    subCategoryData,
    subCategoryIsLoading,
    subCategoryRefetch,
    providerId,
    categoryRefetch,
    subCategoryDelete,
    subCategoryDatCount,
    subCategoryCountIsLoading,
    subCategoryCountRefetch,
    subCategoryListOptions,
    setsubCategoryListOption,
    setSearchCategory,
    searchCategory,
  } = useProduct()
  const [deleteLoading, setDeleteLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [specificCategory, setSpecificCategory] = useState({})
  const [page, setPage] = React.useState(0)
  const [specificSubCategory, setSpecificSubCategory] = useState({})
  const [edit, setEdit] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [showSuccessAdded, setShowSuccessAdded] = useState(false)
  const [categoryAdded, setCategoryAdded] = useState(false)
  const [showSuccessRemoved, setShowSuccessRemoved] = useState(false)
  const [showError, setShowError] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setSearchCategory("")
    setSpecificSubCategory({})
  }

  const handleCloseCategory = () => {
    setEdit(false)
    setOpenModal(false)
    setSpecificCategory({})
  }

  const handleEdit = item => {
    setSpecificSubCategory(item)
    setOpen(true)
    setEdit(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let tempOptions = subCategoryListOptions
    tempOptions.skip = newPage * tempOptions.limit
    setsubCategoryListOption(tempOptions)
    subCategoryRefetch()
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    let tempOptions = subCategoryListOptions
    tempOptions.skip = 0
    tempOptions.limit = +event.target.value
    setsubCategoryListOption(tempOptions)
    subCategoryRefetch()
  }

  const handleClickDelete = async () => {
    setDeleteLoading(true)

    try {
      const result = await subCategoryDelete({ subCategoryId: deleteItem?._id })
      subCategoryCountRefetch()
      subCategoryRefetch()
      setOpenDialog(false)
      setDeleteLoading(false)
      setShowSuccessRemoved(true)
      setTimeout(() => {
        setShowSuccessRemoved(false)
      }, 3000)
    } catch (error) {
      setDeleteLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDeleteItem({})
  }

  if (subCategoryIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  if (!subCategoryData) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccessAdded(false)
    setShowSuccessRemoved(false)
    setShowError(false)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "outfit" }}>
          {"Are you sure you want to delete this Subcategory?"}
        </DialogTitle>

        <DialogActions>
          {!deleteLoading ? (
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "outfit" }}
            >
              No
            </Button>
          ) : (
            ""
          )}

          {deleteLoading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={deleteLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Yes</span>
            </LoadingButton>
          ) : (
            <Button
              onClick={handleClickDelete}
              autoFocus
              style={{ fontFamily: "outfit" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal}
        onClose={handleCloseCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <AddCategories
            providerId={providerId}
            setOpen={setOpenModal}
            edit={edit}
            specificCategory={specificCategory}
            refetch={categoryRefetch}
            setShowSuccessAdded={setShowSuccessAdded}
            setShowError={setShowError}
            handleClose={handleCloseCategory}
          />
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <AddSubCategories
            providerId={providerId}
            setOpen={setOpen}
            edit={edit}
            specificSubCategory={specificSubCategory}
            refetch={subCategoryRefetch}
            setShowSuccessAdded={setShowSuccessAdded}
            setShowError={setShowError}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessAdded}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            {edit ? `Successfully Updated` : `Successfully Added`}
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessRemoved}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Sub Category Successfully Deleted
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>

      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h6 className="page-title">Sub Categories</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Exploring Subcategories
              </li>
            </ol>
          </div>
          <div className="d-flex flex-wrap justify-content-end gap-1">
            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                  width: "130px",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "150px",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "150px",
                },
              }}
              variant="contained"
              onClick={() => {
                setEdit(false)
                setOpenModal(true)
              }}
              style={{
                fontFamily: "outfit",
              }}
            >
              Add Category
            </Button>

            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                  width: "130px",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "180px",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "160px",
                },
              }}
              variant="contained"
              onClick={() => setOpen(true)}
              style={{
                fontFamily: "outfit",
              }}
            >
              Add Subcategory
            </Button>
          </div>
        </div>
      </div>

      <div>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {subCategoryData?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Category Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Is Active
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subCategoryData?.map(item => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item._id}
                    >
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.name?.en}
                        {/* {""}
                          {` (${item?.name?.ar})`} */}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.getCategories?.name?.en}
                        {/* {""}
                          {` (${item?.getCategories?.name?.ar})`} */}
                      </TableCell>
                      <TableCell align="center" sx={tableBodyStyle}>
                        {item?.metadata?.isActive ? (
                          <CheckCircleIcon fontSize="small" />
                        ) : (
                          <CancelIcon fontSize="small" />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <IconButton onClick={() => {}}>
                                <VisibilityIcon fontSize="small" />
                              </IconButton> */}
                            <IconButton
                              onClick={() => {
                                handleEdit(item)
                              }}
                            >
                              <EditNoteIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpenDialog(true)
                                setDeleteItem(item)
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontFamily: "outfit" }}
            sx={tableFooterStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={subCategoryDatCount || 0}
            rowsPerPage={subCategoryListOptions.limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
