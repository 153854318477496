import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/default-User-Image.png"

import { useCurrentProvider } from "common/Hooks/queryCalls"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/material"

const ProfileMenu = props => {
  const customer = useCurrentProvider()
  // console.log("customer:", customer)
  // const { data, isLoading, refetch } = useBranches({
  //   providerId: customer?.data?._id,
  // })
  // console.log(data)
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const trimText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text
    }
    return text.slice(0, maxLength) + "..."
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {customer?.isLoading ||
          // customer?.isRefetching ||
          customer?.data === undefined ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={25} />
            </Box>
          ) : (
            <>
              <img
                className="rounded-circle header-profile-user"
                src={customer?.data?.icon?.url || user1}
                alt="Header Avatar"
              />
              <h6
                style={{
                  fontSize: "0.69rem",
                  maxWidth: "100px",
                  color: "white",
                }}
              >
                <span>{trimText(customer?.data?.name?.en || "", 20)}</span>
              </h6>
            </>
          )}
          {/* {!isLoading && data?.length >= 0 ? (
            <h6
              style={{
                fontSize: "0.59rem",
                maxWidth: "100px",
              }}
            >
              {" "}
              <a
                target="_blank"
                href={` ${envConfig.SHOP_URL}p/${data[0]?.prefix}`}
              >
                Visit Store
              </a>
              <span></span>
            </h6>
          ) : (
            ""
          )} */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
