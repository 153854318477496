import React, { useState } from "react"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import Box from "@mui/material/Box"
import "../../css/orders.css"
const CreateCustomer = ({
  providerId,
  setOpen,
  edit,
  handleClose,
  setCreateCustomer,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    number: Yup.string().required("Number is required"),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const addNewCustomer = data => {
    setIsLoading(true)
    setCreateCustomer(prevSelectedData => [...prevSelectedData, data])
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    setOpen(false)
  }
  return (
    <div>
      <h4>{edit ? `Update Customer` : `Add Customer`}</h4>{" "}
      <form>
        <div className="order-text-field">
          <div className="order-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="First Name*"
              id="fullWidth"
              size="small"
              {...register("firstName", { required: true })}
              error={errors.firstName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.firstName && errors.firstName?.message
                ? errors.firstName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="order-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Last Name"
              id="fullWidth"
              size="small"
              {...register("lastName", { required: true })}
              error={errors.lastName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.lastName && errors.lastName?.message
                ? errors.lastName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="order-text-field">
          <div className="order-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Number*"
              id="fullWidth"
              size="small"
              {...register("number", { required: true })}
              error={errors.number ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.number && errors.number?.message
                ? errors.number?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="order-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Email"
              id="fullWidth"
              size="small"
              {...register("email", { required: true })}
              error={errors.email ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.email && errors.email?.message
                ? errors.email?.message.toString()
                : null}
            </Typography>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewCustomer)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default CreateCustomer
