import React, { useContext, useEffect, useState, useReducer } from "react"
import {
  useCurrentProvider,
  useProducts,
  useProductsCount,
  useAllCategory,
  useFetchAllCategory,
  useCategoryCount,
  useSubCategories,
  useSubCategoriesCount,
  useAddons,
  useCategorySetting,
  useProductCategorySearch,
  useProductsModifiers,
  useSpecificSubcatogories,
  useSpecificSubcatogoriesSetting,
  useCategorySearch,
  useFilteringProducts,
  useOneMenu,
  useProductVariants,
  useOneProductVariant,
  useCouponProducts,
  useCouponCustomers,
} from "common/Hooks/queryCalls"

import {
  addProduct,
  updateProduct,
  deleteProduct,
  DeleteCategory,
  AddOneCategory,
  UpdateOneCategory,
  deleteSubCategory,
  updateSubCategory,
  addSubCategory,
  addVariant,
  updateVariant,
  deleteVariant,
} from "./dbServices"

const initValue = {
  items: [],
  address: {},
  total: { itemTotal: 0, amountPayable: 0 },
}

export const ProductContext = React.createContext({
  cart: initValue,
  addToCart: item => {},
  updateQuantity: (item, quantity) => {},
  removeFromCart: item => {},
})

export const ACTIONS = {
  ADD_TO_CART: "add-to-cart",
  UPDATE_QUANTITY: "update-quantity",
  REMOVE_FROM_CART: "remove-from-cart",
  // DELETE_ITEM_FROM_CART: "delete_item_from_cart",
  CLEAR_CART: "clear-cart",
  INIT_CART: "init-cart",
  // SET_ADDRESS: "set-address",
  SET_TOTAL: "set-total",
  // SET_PROVIDER_ID: "set-provider-id",
  // SET_BRANCH_ID: "set-branch-id",
  // SET_DELIVERY_CHARGE: "set-delivery-charge",
  // CHECK_SAME_PROVIDER: 'check-same-provider',
}

function cartReducer(cart, action) {
  switch (action.type) {
    case ACTIONS.ADD_TO_CART:
      return addToCartAction(cart, action.payload)
    case ACTIONS.UPDATE_QUANTITY:
      return updateQuantity(cart, action.payload)
    case ACTIONS.REMOVE_FROM_CART:
      return removeFromCartAction(cart, action.payload)
    // case ACTIONS.DELETE_ITEM_FROM_CART:
    //   return deleteFromCartAction(cart, action.payload);
    case ACTIONS.CLEAR_CART:
      return initValue
    case ACTIONS.INIT_CART:
      return action.payload.initCart
    // case ACTIONS.SET_ADDRESS:
    //   return setAddress(cart, action.payload);
    // case ACTIONS.SET_PROVIDER_ID:
    //   return setProviderId(cart, action.payload);
    // case ACTIONS.SET_BRANCH_ID:
    //   return setBranchId(cart, action.payload);
    // case ACTIONS.SET_DELIVERY_CHARGE:
    //   return setDeliveryCharge(cart, action.payload);
    // case ACTIONS.CHECK_SAME_PROVIDER:
    //   return checkSameProvider(cart, action.payload);

    case ACTIONS.SET_TOTAL:
      return { ...cart, total: action.payload }

    default:
      return cart
  }
}

function addToCartAction(cart, payload) {
  const updatedCartItems = [...cart.items]
  const updatedItemIndex = updatedCartItems.findIndex(
    item => item._id.toString() === payload.item._id.toString()
  )

  if (updatedItemIndex < 0) {
    updatedCartItems.push({ ...payload.item, quantity: 1 })
  } else {
    const updatedItem = {
      ...updatedCartItems[updatedItemIndex],
    }
    updatedItem.quantity++
    updatedCartItems[updatedItemIndex] = updatedItem
  }
  return { ...cart, items: updatedCartItems }
}

function updateQuantity(cart, payload) {
  const updatedCartItems = [...cart.items]
  const updatedItemIndex = updatedCartItems.findIndex(
    item => item._id.toString() === payload.item._id.toString()
  )
  const updatedItem = {
    ...updatedCartItems[updatedItemIndex],
  }
  updatedItem.quantity = payload.quantity
  return { ...cart, items: updatedCartItems }
}

function removeFromCartAction(cart, payload) {
  const updatedCartItems = [...cart.items]
  const updatedItemIndex = updatedCartItems.findIndex(
    item => item._id.toString() === payload.item._id.toString()
  )
  const updatedItem = {
    ...updatedCartItems[updatedItemIndex],
  }
  updatedItem.quantity--
  if (updatedItem.quantity <= 0) {
    updatedCartItems.splice(updatedItemIndex, 1)
  } else {
    updatedCartItems[updatedItemIndex] = updatedItem
  }
  return { ...cart, items: updatedCartItems }
}

export const ProductProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, initValue)
  const [initialization, setInitialization] = useState(false)
  const [searchCategory, setSearchCategory] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [subCategoryId, setSubCategoryId] = useState("")
  const [searchProducts, setSearchProducts] = useState("")
  const [menuId, setMenuId] = useState("")
  const [variantId, setVariantId] = useState("")
  const currentProvider = useCurrentProvider()
  const [showSuccessAdded, setShowSuccessAdded] = useState(false)
  const [showSuccessRemoved, setShowSuccessRemoved] = useState(false)
  const [showSuccessAddedVariant, setShowSuccessAddedVariant] = useState(false)
  const [showSuccessRemovedVariant, setShowSuccessRemovedVariant] =
    useState(false)
  const [selectedStatus, setSelectedStatus] = useState("Status")
  const [selectedCategory, setSelectedCategory] = useState()
  const [startPrice, setStartPrice] = useState(0)
  const [endPrice, setEndPrice] = useState(1000000)
  const [categoryListOptions, setCategoryListOption] = useState({
    sort: { _id: -1 },
    skip: 0,
    limit: 5,
  })

  const [subCategoryListOptions, setsubCategoryListOption] = useState({
    sort: { _id: -1 },
    skip: 0,
    limit: 5,
  })

  const [productListOptions, setProductListOption] = useState({
    sort: { _id: -1 },
    skip: 0,
    limit: 5,
  })

  // useEffect(() => {
  //   let initCart = localStorage.getItem("cart")
  //   if (initCart) {
  //     initCart = JSON.parse(initCart)
  //     // if (initCart.providerId) {
  //     //   initCart.providerId = new BSON.ObjectId(initCart.providerId)
  //     // }
  //     // if (initCart.branchId) {
  //     //   initCart.branchId = new BSON.ObjectId(initCart.branchId)
  //     // }
  //     dispatch({
  //       type: ACTIONS.INIT_CART,
  //       payload: { initCart },
  //     })
  //   }
  //   setTimeout(() => {
  //     setInitialization(true)
  //   }, 200)
  // }, [])
  useEffect(() => {
    calculateTotal()
  }, [cart.items])

  const {
    data: productsData,
    isLoading: productsIsLoading,
    refetch: productsRefetch,
  } = useProducts({
    providerId: currentProvider?.data?._id,
    status: selectedStatus,
    category: selectedCategory,
    startPrice: startPrice,
    endPrice: endPrice,
    options: productListOptions,
  })

  const {
    data: productsDataCount,
    isLoading: productsCountIsLoading,
    refetch: productsCountRefetch,
  } = useProductsCount({
    providerId: currentProvider?.data?._id,
    status: selectedStatus,
    category: selectedCategory,
    startPrice: startPrice,
    endPrice: endPrice,
  })

  const {
    data: menuData,
    isLoading: menuIsLoading,
    refetch: menuRefetch,
    isSuccess: menuIsSuccess,
  } = useOneMenu({
    menuId: menuId,
  })

  const {
    data: categoryData,
    isLoading: categoryIsLoading,
    refetch: categoryRefetch,
  } = useAllCategory({
    providerId: currentProvider?.data?._id,
    options: categoryListOptions,
  })

  const {
    data: fetchAllCategoryData,
    isLoading: fetchCategoryIsLoading,
    refetch: fetchCategoryRefetch,
  } = useFetchAllCategory({
    providerId: currentProvider?.data?._id,
  })

  const {
    data: categoryCount,
    isLoading: categoryCountIsLoading,
    refetch: categoryCountRefetch,
  } = useCategoryCount({
    providerId: currentProvider?.data?._id,
    options: categoryListOptions,
  })

  const {
    data: subCategoryData,
    isLoading: subCategoryIsLoading,
    refetch: subCategoryRefetch,
  } = useSubCategories({
    providerId: currentProvider?.data?._id,
    options: subCategoryListOptions,
  })
  const {
    data: subCategoryDatCount,
    isLoading: subCategoryCountIsLoading,
    refetch: subCategoryCountRefetch,
  } = useSubCategoriesCount({
    providerId: currentProvider?.data?._id,
  })

  const {
    data: productCategorySearchData,
    isLoading: productCategorySearchIsLoading,
    refetch: productCategorySearchRefetch,
  } = useProductCategorySearch({
    search: searchCategory,
    providerId: currentProvider?.data?._id,
  })

  const {
    data: productVariantsData,
    isLoading: productVariantsIsLoading,
    refetch: productVariantsRefetch,
    isSuccess: productVariantsSuccess,
  } = useProductVariants({
    providerId: currentProvider?.data?._id,
  })

  const {
    data: productVariantsDataForSelect,
    isLoading: productVariantsIsLoadingForSelect,
    refetch: productVariantsRefetchForSelect,
    isSuccess: productVariantsSuccessForSelect,
  } = useProductVariants({
    providerId: currentProvider?.data?._id,
    isSelectOption: true,
  })

  const {
    data: oneProductVariantData,
    isLoading: oneProductVariantIsLoading,
    refetch: oneProductVariantRefetch,
    isSuccess: oneProductVariantSuccess,
  } = useOneProductVariant({
    itemId: variantId,
    providerId: currentProvider?.data?._id,
  })

  const { data: categoriesSettingData } = useCategorySetting({ categoryId })
  const { data: specificSubcatogories } = useSpecificSubcatogories({
    subId: categoryId,
  })
  const { data: categorySearchData, refetch: categorySearchIsLoading } =
    useCategorySearch({
      search: searchCategory,
      providerId: currentProvider?.data?._id,
    })

  const { data: SpecificSubcatogoriesSetting } =
    useSpecificSubcatogoriesSetting({ subCategoryId })
  // const createProduct = () => {
  //   console.log("Create Product")
  //   return "product"
  // }

  const {
    data: filteringProductsData,
    isLoading: filteringProductsIsLoading,
    refetch: filteringProductsRefetch,
  } = useFilteringProducts({
    searchName: searchProducts,
    providerId: currentProvider?.data?._id,
  })

  const {
    data: couponProductsData,
    isLoading: couponProductsLoading,
    refetch: couponProductsRefetch,
  } = useCouponProducts({
    providerId: currentProvider?.data?._id,
  })

  const {
    data: couponCustomersData,
    isLoading: couponCustomersLoading,
    refetch: couponCustomersRefetch,
  } = useCouponCustomers({
    providerId: currentProvider?.data?._id,
  })

  const productUpdate = async ({
    providerId,
    item,
    data,
    resIcon,
    badge,
    multipleImages,
    categoryId,
    subCategoryId,
    selectedVariants,
    productType,
    variantCombinations,
    modifierData,
  }) => {
    const result = await updateProduct({
      providerId,
      item,
      data,
      resIcon,
      badge,
      multipleImages,
      categoryId,
      subCategoryId,
      selectedVariants,
      productType,
      variantCombinations,
      modifierData,
    })

    return result
  }

  const productAdd = async ({
    providerId,
    data,
    resIcon,
    badge,
    multipleImages,
    categoryId,
    subCategoryId,
    selectedVariants,
    productType,
    variantCombinations,
    modifierData,
  }) => {
    const result = await addProduct({
      providerId,
      data,
      resIcon,
      badge,
      multipleImages,
      categoryId,
      subCategoryId,
      selectedVariants,
      productType,
      variantCombinations,
      modifierData,
    })

    return result
  }

  const productDelete = async ({ providerId, menuId }) => {
    const result = await deleteProduct({
      providerId,
      menuId,
    })

    return result
  }

  const categoryDelete = async ({ itemId }) => {
    const result = await DeleteCategory({
      itemId,
    })
    productCategorySearchRefetch()
    categorySearchIsLoading()
    return result
  }

  const categoryUpdate = async ({ item, data, providerId }) => {
    const result = await UpdateOneCategory({
      item,
      data,
      providerId,
    })
    productCategorySearchRefetch()
    categorySearchIsLoading()
    return result
  }

  const categoryAdd = async ({ data, providerId }) => {
    const result = await AddOneCategory({
      data,
      providerId,
    })
    productCategorySearchRefetch()
    categorySearchIsLoading()
    return result
  }

  const subCategoryDelete = async ({ subCategoryId }) => {
    const result = await deleteSubCategory({ subCategoryId })

    return result
  }
  const subCategoryUpdate = async ({ item, data, categoryId }) => {
    const result = await updateSubCategory({
      item,
      data,
      categoryId,
    })
    return result
  }

  const subCategoryAdd = async ({ providerId, data, categoryId }) => {
    const result = await addSubCategory({ providerId, data, categoryId })
    return result
  }

  const variantDelete = async ({ itemId }) => {
    const result = await deleteVariant({
      itemId,
    })
    return result
  }

  const variantUpdate = async ({ id, data, providerId, options }) => {
    const result = await updateVariant({
      id,
      data,
      providerId,
      options,
    })
    return result
  }

  const variantAdd = async ({ data, providerId, options }) => {
    const result = await addVariant({
      data,
      providerId,
      options,
    })
    return result
  }

  const addToCart = item => {
    dispatch({ type: ACTIONS.ADD_TO_CART, payload: { item } })
  }

  const updateQuantity = (item, quantity) => {
    dispatch({
      type: ACTIONS.UPDATE_QUANTITY,
      payload: { item, quantity },
    })
  }

  const removeFromCart = item => {
    dispatch({ type: ACTIONS.REMOVE_FROM_CART, payload: { item } })
  }
  const clearCart = () => {
    dispatch({ type: ACTIONS.CLEAR_CART })
  }

  // const saveToStorage = () => {
  //   if (initialization) {
  //     localStorage.setItem("cart", JSON.stringify(cart))
  //   }
  // }

  const calculateTotal = () => {
    let total = 0
    cart.items.forEach(item => {
      total += item.quantity * (item.salePrice || item.price)
    })
    dispatch({
      type: ACTIONS.SET_TOTAL,
      payload: {
        itemTotal: total,
        amountPayable: total,
      },
    })
    // saveToStorage()
  }

  return (
    <ProductContext.Provider
      value={{
        cart,
        addToCart,
        updateQuantity,
        removeFromCart,
        productsData,
        productsIsLoading,
        productsRefetch,
        productsDataCount,
        productsCountIsLoading,
        productsCountRefetch,
        productListOptions,
        setProductListOption,
        currentProvider,
        providerId: currentProvider?.data?._id,
        categoryCount,
        categoryCountIsLoading,
        categoryCountRefetch,
        categoryData,
        categoryIsLoading,
        categoryRefetch,
        fetchAllCategoryData,
        fetchCategoryIsLoading,
        fetchCategoryRefetch,
        categoryListOptions,
        setCategoryListOption,
        subCategoryData,
        subCategoryIsLoading,
        subCategoryRefetch,
        subCategoryDatCount,
        subCategoryCountIsLoading,
        subCategoryCountRefetch,
        subCategoryListOptions,
        setsubCategoryListOption,
        setSearchCategory,
        setCategoryId,
        setSubCategoryId,
        searchCategory,
        categoryId,
        subCategoryId,
        productCategorySearchData,
        productCategorySearchIsLoading,
        categoriesSettingData,
        specificSubcatogories,
        SpecificSubcatogoriesSetting,
        productAdd,
        productUpdate,
        productDelete,
        categoryDelete,
        categoryAdd,
        categoryUpdate,
        subCategoryDelete,
        subCategoryUpdate,
        subCategoryAdd,
        categorySearchData,
        categorySearchIsLoading,
        filteringProductsData,
        filteringProductsIsLoading,
        filteringProductsRefetch,
        searchProducts,
        setSearchProducts,
        clearCart,
        showSuccessAdded,
        setShowSuccessAdded,
        showSuccessRemoved,
        setShowSuccessRemoved,
        menuId,
        setMenuId,
        menuData,
        menuIsLoading,
        menuRefetch,
        menuIsSuccess,
        selectedStatus,
        setSelectedStatus,
        selectedCategory,
        setSelectedCategory,
        startPrice,
        setStartPrice,
        endPrice,
        setEndPrice,
        productVariantsData,
        productVariantsIsLoading,
        productVariantsRefetch,
        productVariantsSuccess,
        productVariantsDataForSelect,
        productVariantsIsLoadingForSelect,
        productVariantsRefetchForSelect,
        productVariantsSuccessForSelect,
        variantDelete,
        variantAdd,
        variantUpdate,
        variantId,
        setVariantId,
        oneProductVariantData,
        oneProductVariantIsLoading,
        oneProductVariantRefetch,
        oneProductVariantSuccess,
        showSuccessAddedVariant,
        setShowSuccessAddedVariant,
        showSuccessRemovedVariant,
        setShowSuccessRemovedVariant,
        couponProductsData,
        couponProductsLoading,
        couponProductsRefetch,
        couponCustomersData,
        couponCustomersLoading,
        couponCustomersRefetch,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export function useProduct() {
  return useContext(ProductContext)
}
