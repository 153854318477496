import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "react-query"
import store from "./store"
import { OrderProvider } from "common/Services/order"
import { ProductProvider } from "common/Services/product"
import { CustomerProvider } from "common/Services/customer"
import { UserProvider } from "Context/userContext"
import { ServiceProvider } from "common/Services/services"
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      {" "}
      <UserProvider>
        <OrderProvider>
          <ProductProvider>
            <ServiceProvider>
              <CustomerProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CustomerProvider>
            </ServiceProvider>
          </ProductProvider>
        </OrderProvider>
      </UserProvider>
    </QueryClientProvider>
  </Provider>
)

serviceWorker.unregister()
