import React, { useContext, useEffect } from "react"
import { UserContext } from "Context/userContext"
import { useNavigate } from "react-router-dom"
import { app } from "common/Services/dbServices"
import { useParams } from "react-router-dom"

function webViewLogin() {
  const { emailPasswordLogin, fetchUser } = useContext(UserContext)
  const navigate = useNavigate()
  const params  = useParams()
  // console.log('params=',params)
  const userName = params.email
  const password = params.password
  const url = `/${params.url}`

  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = async () => {
    try{
      const fetchedUser = await fetchUser()
      console.log("fetchedUser=", fetchedUser?._profile?.data?.email)
      if (fetchedUser && fetchedUser?._profile?.data?.email === userName) {
        console.log("login");
        redirectNow()
      } else {
        const result = await emailPasswordLogin(userName, password)
        console.log('result=',result)
        if (result && result._profile?.data?.email === userName){
          if (app.currentUser) {
            localStorage.setItem("authUser", JSON.stringify(app.currentUser))
            redirectNow()
          }

        }
      }
    } catch(error){
      console.log('error', error);
    }
   
  }

  const redirectNow = () => {
    console.log("welcome")
    const redirectTo = location.search.replace("?redirectTo=", "")
    navigate(redirectTo ? redirectTo : url)
  }





  return <div></div>
}

export default webViewLogin
