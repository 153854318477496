import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Tab, Tabs } from "react-bootstrap"
import ListGroup from "react-bootstrap/ListGroup"
import "../../css/dashboard-details.css"
import { ObjectId, getCouponCode } from "common/Services/dbServices"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { formatDate, formatCurrency } from "../../common/Services/functions"

const FormElements = () => {
  const [tabKey, initTabKey] = useState("one")
  const [response, setResponse] = useState(null)
  const urlParams = useParams()

  useEffect(() => {
    getCouponData()
  }, [])

  const getCouponData = async () => {
    try {
      const res = await getCouponCode({ id: ObjectId(urlParams.id) })
      setResponse(res)
    } catch (error) {
      console.error(error)
    }
  }

  // document.title = "Coupon Details | Merche"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs
            // maintitle="Sayartak"
            // title="Coupon"
            breadcrumbItem="Coupon Details"
            url="/Coupon"
          /> */}
          {/* <h4> Coupon Details </h4>{" "} */}
          {/* <Tabs activeKey={tabKey} onSelect={e => initTabKey(e)}>
            <Tab eventKey="one"> */}
          <Row>
            <Col>
              {/* <Card>
                    <CardBody> */}
              {/* <div class="details-section">
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Coupon Name
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response
                                    ? response[0]?.name
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Description
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.description
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Type
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.type
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Amount
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.amount
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-12">
                              <hr style={{ width: "100%" }} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Validity
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? (<>{formatDate(response[0]?.validFrom)} to {formatDate(response[0]?.validTill)}</>)
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                code
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.couponCode
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                    
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Included CategoryTypes
                              </label>
                              <div class="details-section-item-content">
                                {
                                 response && response.length > 0? response[0]?.getIncludedCategoryTypes.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name.en.toString()
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                            
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Excluded CategoryTypes
                              </label>
                              <div class="details-section-item-content">
                              {
                                 response && response.length > 0? response[0]?.getExcludedCategoryTypes.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name.en.toString()
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Included ProductTypes
                              </label>
                              <div class="details-section-item-content">
                                {
                                 response && response.length > 0? response[0]?.getIncludedProductTypes.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name.en.toString()
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                            
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Excluded ProductTypes
                              </label>
                              <div class="details-section-item-content">
                              {
                                 response && response.length > 0? response[0]?.getExcludedProductTypes.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name.en.toString()
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                               Included Customers
                              </label>
                              <div class="details-section-item-content">
                              {
                                 response && response.length > 0? response[0]?.getIncludedCustomers.map((v)=>{
                                    return (
                               <span>
                                  {
                               v.firstName
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Excluded Customers
                              </label>
                              <div class="details-section-item-content">
                              {
                                 response && response.length > 0? response[0]?.getExcludedCustomers.map((v)=>{
                                    return (
                               <span>
                                  {
                               v.firstName
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

              <div className="page-title-box">
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ marginTop: "-80px" }}
                >
                  <div>
                    {" "}
                    <h6 className="page-title"> Coupon Details</h6>
                  </div>
                </div>
              </div>

              <ListGroup style={{ marginTop: "-50px" }}>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Coupon Name:</h6>
                      <h6 className="eachitem-div-h6">
                        {response ? response[0]?.name : "-"}
                      </h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Description:</h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.description
                          : "-"}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6"> Type:</h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.type
                          : "-"}
                      </h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Amount :</h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.amount
                          : "-"}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Validity:</h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0 ? (
                          <>
                            {formatDate(response[0]?.validFrom)} to <br />
                            {formatDate(response[0]?.validTill)}
                          </>
                        ) : (
                          "-"
                        )}
                      </h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6"> code:</h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.couponCode
                          : "-"}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">
                        Included CategoryTypes:
                      </h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.getIncludedCategoryTypes.map(v => {
                              return <span>{v.name.en.toString() || "-"},</span>
                            })
                          : null}
                      </h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">
                        {" "}
                        Excluded CategoryTypes:
                      </h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.getExcludedCategoryTypes.map(v => {
                              return <span>{v.name.en.toString() || "-"},</span>
                            })
                          : null}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">
                        {" "}
                        Included ProductTypes:
                      </h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.getIncludedProductTypes.map(v => {
                              return <span>{v.name.en.toString() || "-"},</span>
                            })
                          : null}
                      </h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">
                        {" "}
                        Excluded ProductTypes:
                      </h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.getExcludedProductTypes.map(v => {
                              return <span>{v.name.en.toString() || "-"},</span>
                            })
                          : null}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6"> Included Customers:</h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.getIncludedCustomers.map(v => {
                              return <span>{v.firstName || "-"},</span>
                            })
                          : null}
                      </h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6"> Excluded Customers:</h6>
                      <h6 className="eachitem-div-h6">
                        {response && response.length > 0
                          ? response[0]?.getExcludedCustomers.map(v => {
                              return <span>{v.firstName || "-"},</span>
                            })
                          : null}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>

              {/* </CardBody>
                  </Card> */}
            </Col>
          </Row>
          {/* </Tab> */}

          {/* </Tabs> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
