import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate ,useParams} from "react-router-dom";

import {
  GoogleMap,
  Marker,
  LoadScript,
  InfoWindow,
  Autocomplete,
} from "@react-google-maps/api";
import {
  useCurrentProvider,
   useBranches,
} from "../../common/Hooks/queryCalls"
import {  updateStoreLocation } from "../../common/Services/dbServices"


const containerStyle = {
  width: "100%",
  height: "80vh",
};
const placeLibrary = ["places", "geometry", "drawing"];

const Maps = () => {
  const currentProvider = useCurrentProvider()
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [address, setAddress] = useState("");
  const [autocomplete, setAutocomplete] = useState();
  const [marker, setMarker] = useState();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [center, setCenter] = useState({
    lat: 10.1632 ,
    lng: 76.6413 ,
  });
  // const navigate = useNavigate();
  let params = useParams();
  let activeStatus =params.status

    const {
    data: branchData,
    isLoading: branchLoading,
    refetch: branchRefetch,
  } = useBranches({
    providerId: currentProvider?.data?._id,
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick =async () => {
    setOpen(false);
    const result = await updateStoreLocation({
      providerId: currentProvider?.data?._id,
      itemId: branchData[0]?._id,
      address,
      marker,
      inStockStatus:activeStatus
    })
    navigate(`/details`);
  };

  const onLoad = (autocomplete) => setAutocomplete(autocomplete);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      //console.log("map place", place);
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setMarker({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      const geocoder = new google.maps.Geocoder();
      const latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      geocoder.geocode({ location: latLng }, (results, status) => {
        //console.log("result:", results);
        if (status === "OK" && results) {
          //console.log(results ? results[0].formatted_address : "");
          setAddress(results ? results[0].formatted_address : "");
          setShowInfoWindow(true);
          setOpen(true);
        } else {
          // console.log(
          //   "Geocode was not successful for the following reason: " + status
          // );
        }
      });
    } else {
      // console.log("Autocomplete is not loaded yet!");
    }
  };
  const handleMapClick = (event) => {
    const { latLng } = event;
    setMarker({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        setAddress(results ? results[0].formatted_address : "");
        setShowInfoWindow(true);
        setOpen(true);
      }
    });
  };

  // console.log("marker:;", marker);
  // console.log("cuurent selected address::", address);
  return (
    <>
      {" "}
      <LoadScript
        googleMapsApiKey="AIzaSyDprokhYl0DOal2so4taJHLK-2ik3Yf8dc"
        libraries={placeLibrary}
      >
        {/* <div>
        <input type="text" value={address} onChange={handleAddressChange} />
        <button onClick={handleSearchClick}>Search</button>
      </div> */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onClick={handleMapClick}
          // options={{
          //   styles: mapStyles // Pass your map styles JSON object here
          // }}
        >
          {/* Child components, such as markers, info windows, etc. */}
          {marker && (
            <Marker position={marker} onClick={() => setShowInfoWindow(true)}>
              {showInfoWindow && (
                <InfoWindow onCloseClick={() => setShowInfoWindow(false)}>
                  <div>{address}</div>
                </InfoWindow>
              )}
            </Marker>
          )}
          {/* <>  {position && <Marker position={position} />}</> */}
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <input
              type="text"
              placeholder="Search a location from map"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `360px`,
                height: `50px`,
                padding: `0 12px`,
                borderRadius: `20px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                top: 0,
                left: " 50%",
                transform: " translateX(-50%)",
                marginTop: "60px",
              }}
            />
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation: Proceed with Selected Address?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClick} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Maps;
