import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { Button, IconButton } from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import EditNoteIcon from "@mui/icons-material/EditNote"
import ShareIcon from "@mui/icons-material/Share"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import { formatCurrency } from "../../common/Services/functions"
import AddProduct from "./AddProduct"
import AddCategories from "pages/Categories/AddCategories"
import { LottieLoading } from "../../common/LottieLoading"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import { deleteProduct } from "common/Services/dbServices"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import envConfig from "../../env/env.json"
import { useProduct } from "common/Services/product"
import { useNavigate } from "react-router-dom"
import { Row, Col } from "reactstrap"
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import TextField from "@mui/material/TextField"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share"
import InstagramIcon from "@mui/icons-material/Instagram"
const shopUrl = envConfig.SHOP_URL.replace(/^(https?:\/\/)/, "")

const Products = () => {
  const {
    currentProvider,
    productsData,
    productsIsLoading,
    productsRefetch,
    productsDataCount,
    productsCountIsLoading,
    productsCountRefetch,
    productListOptions,
    setProductListOption,
    providerId,
    categoryRefetch,
    productDelete,
    showSuccessAdded,
    setShowSuccessAdded,
    showSuccessRemoved,
    setShowSuccessRemoved,
    setMenuId,
    selectedStatus,
    setSelectedStatus,
    selectedCategory,
    setSelectedCategory,
    setStartPrice,
    setEndPrice,
    categoryData,
    fetchAllCategoryData,
    categoryIsLoading,
  } = useProduct()

  const [selectedCategoryName, setSelectedCategoryName] = useState("Category")
  const [deleteLoading, setDeleteLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [openShere, setOpenShere] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [specificCategory, setSpecificCategory] = useState({})

  const [page, setPage] = React.useState(0)
  const [specificProduct, setSpecificProduct] = useState({})
  const [edit, setEdit] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [productLinkCopy, setProductLinkCopy] = useState(false)
  // const [linkCopied, setLinkCopied] = useState(false)
  const [linkCopied, setLinkCopied] = useState()

  const [showError, setShowError] = useState(false)

  const [btnprimary1, setBtnprimary1] = useState(false)
  const [btnprimary2, setBtnprimary2] = useState(false)

  const [firstPrice, setFirstPrice] = useState()
  const [lastPrice, setLastPrice] = useState()

  const [anchorEl, setAnchorEl] = useState(null)

  const navigate = useNavigate()

  if (productsIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setOpenModal(false)
    setEdit(false)
    setSpecificCategory({})
    setSpecificProduct({})
  }

  const handleEdit = item => {
    setSpecificProduct(item)
    setOpen(true)
    setEdit(true)
  }

  const handleClickDelete = async () => {
    setDeleteLoading(true)
    try {
      const result = await productDelete({
        providerId,
        menuId: deleteItem?._id,
      })

      if (result) {
        productsCountRefetch()
        productsRefetch()
        setOpenDialog(false)
        setDeleteLoading(false)
        setShowSuccessRemoved(true)
        setTimeout(() => {
          setShowSuccessRemoved(false)
        }, 3000)
      }
    } catch (error) {
      setDeleteLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const handleCopyLink = item => {
    const link = `https://${shopUrl}p/${currentProvider?.data?.branch?.prefix}/p/${item?._id}`
    setLinkCopied(link)
    setOpenShere(true)
    // navigator.clipboard.writeText(link).then(() => {
    //   setLinkCopied(true)
    //   setProductLinkCopy(true)
    //   setTimeout(() => setLinkCopied(false), 2000)
    // })
  }

  // const instagramShare = () =>{
  //   console.log('instagram login')
  //   const url =`https://www.instagram.com/direct/inbox?text=Check out my awesome ecommerce site`
  //   window.open(url, '_blank');
  // }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDeleteItem({})
  }

  const handleCloseShare = () => {
    setOpenShere(false)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let tempOptions = productListOptions
    tempOptions.skip = newPage * tempOptions.limit
    setProductListOption(tempOptions)
    productsRefetch()
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    let tempOptions = productListOptions
    tempOptions.skip = 0
    tempOptions.limit = +event.target.value
    setProductListOption(tempOptions)
    productsRefetch()
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccessAdded(false)
    setShowSuccessRemoved(false)
    setShowError(false)
    setProductLinkCopy(false)
  }

  const minDistance = 10

  const openPrice = Boolean(anchorEl)
  const handlePriceClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handlePriceClose = () => {
    setAnchorEl(null)
  }

  // console.log("123count:", productsDataCount)

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "outfit" }}>
          {"Are you sure you want to delete this Product?"}
        </DialogTitle>

        <DialogActions>
          {!deleteLoading ? (
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "outfit" }}
            >
              No
            </Button>
          ) : (
            ""
          )}
          {deleteLoading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={deleteLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Yes</span>
            </LoadingButton>
          ) : (
            <Button
              onClick={handleClickDelete}
              autoFocus
              style={{ fontFamily: "outfit" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openShere}
        onClose={handleCloseShare}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: "outfit",
            marginLeft: "-15px",
            padding: "5px 24px",
          }}
        >
          {"Share via"}
        </DialogTitle>
        <DialogActions>
          <EmailShareButton url={linkCopied}>
            <EmailIcon size={40} round={true} />
          </EmailShareButton>
          <FacebookShareButton url={linkCopied}>
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton url={linkCopied}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          <TelegramShareButton url={linkCopied}>
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
          <TwitterShareButton url={linkCopied}>
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
          {/* <InstagramIcon onClick={() => instagramShare()}></InstagramIcon> */}
        </DialogActions>
      </Dialog>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <AddCategories
            providerId={providerId}
            setOpen={setOpenModal}
            edit={edit}
            specificCategory={specificCategory}
            refetch={categoryRefetch}
            setShowSuccessAdded={setShowSuccessAdded}
            setShowError={setShowError}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <AddProduct
            providerId={providerId}
            setOpen={setOpen}
            edit={edit}
            specificProduct={specificProduct}
            refetch={productsRefetch}
            setShowSuccessAdded={setShowSuccessAdded}
            setShowError={setShowError}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessAdded}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            {edit ? `Successfully Updated` : `Successfully Added`}
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessRemoved}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Product Successfully Deleted
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={productLinkCopy}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Product Link Successfully Copied
          </Alert>
        </Snackbar>
      </Stack>

      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h6 className="page-title">Products</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Discovering Your Product Showcase
              </li>
            </ol>
          </div>
          <div className="d-flex flex-wrap justify-content-end gap-1">
            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                  width: "130px",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "150px",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "150px",
                },
              }}
              variant="contained"
              onClick={() => {
                setEdit(false)
                setOpenModal(true)
              }}
              style={{
                fontFamily: "outfit",
              }}
            >
              Add Category
            </Button>

            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                  width: "130px",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "180px",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                  width: "160px",
                },
              }}
              variant="contained"
              onClick={() => {
                setMenuId("")
                navigate(`/addproduct`)
              }}
              style={{ fontFamily: "outfit" }}
            >
              Add Product
            </Button>
          </div>
        </div>
      </div>

      <div
        className="btn-group d-flex flex-wrap justify-content-start gap-1"
        style={{ margin: 5 }}
      >
        <Dropdown
          isOpen={btnprimary1}
          toggle={() => setBtnprimary1(!btnprimary1)}
          style={{ marginRight: "5px" }}
        >
          <DropdownToggle
            tag="button"
            className="btn btn-primary"
            style={{ backgroundColor: "#333547", borderColor: "#333547" }}
          >
            {/* {`${selectedStatus.replace(
              /^./,
              selectedStatus[0].toUpperCase()
            )} ( ${productsData?.length} )`}{" "} */}
            {selectedStatus}
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                setSelectedStatus("Active"), setPage(0)
              }}
            >
              Active
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setSelectedStatus("InActive"), setPage(0)
              }}
            >
              InActive
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Dropdown
          isOpen={btnprimary2}
          toggle={() => setBtnprimary2(!btnprimary2)}
          style={{ marginRight: "5px" }}
        >
          <DropdownToggle
            tag="button"
            className="btn btn-primary"
            style={{ backgroundColor: "#333547", borderColor: "#333547" }}
          >
            {selectedCategoryName.length >= 6
              ? selectedCategoryName.substring(0, 6) + "..."
              : selectedCategoryName}
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu style={{ overflowY: "scroll", height: "150px" }}>
            {fetchAllCategoryData?.map(item => {
              return (
                <DropdownItem
                  onClick={() => {
                    setSelectedCategory(item?._id),
                      setSelectedCategoryName(item?.name?.en),
                      setPage(0)
                  }}
                >
                  {item?.name?.en}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>

        <div>
          <Button
            id="basic-button"
            aria-controls={openPrice ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openPrice ? "true" : undefined}
            onClick={handlePriceClick}
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              "@media (max-width: 600px)": {
                // fontSize: "0.6rem",
                fontWeight: "400",
                textTransform: "capitalize",
                backgroundColor: "#333547",
                height: "35px",
                "&:hover": {
                  backgroundColor: "#333547",
                },
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                // fontSize: "0.8rem",
                fontWeight: "400",
                textTransform: "capitalize",
                backgroundColor: "#333547",
                height: "35px",
                "&:hover": {
                  backgroundColor: "#333547",
                },
              },
              "@media (min-width: 970px)": {
                // fontSize: "0.8rem",
                fontWeight: "400",
                textTransform: "capitalize",
                backgroundColor: "#333547",
                height: "35px",
                "&:hover": {
                  backgroundColor: "#333547", // Change the background color on hover
                },
              },
            }}
          >
            Price
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openPrice}
            onClose={handlePriceClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <p>
                {firstPrice} to {lastPrice}
              </p> */}
            </MenuItem>
            <MenuItem>
              <TextField
                fullWidth
                type="number"
                label="Start Price*"
                id="fullWidth"
                value={firstPrice}
                size="small"
                onChange={event => setFirstPrice(Number(event.target.value))}
                inputProps={{
                  onFocus: event => event.target.select(), // This selects the text on focus
                }}
              />
            </MenuItem>
            <MenuItem>
              <TextField
                fullWidth
                type="number"
                label="End Price*"
                id="fullWidth"
                value={lastPrice}
                size="small"
                onChange={event => setLastPrice(Number(event.target.value))}
                inputProps={{
                  onFocus: event => event.target.select(), // This selects the text on focus
                }}
              />
            </MenuItem>
            <MenuItem onClick={handlePriceClose}>
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.8rem",
                    marginLeft: "5px",
                    textTransform: "capitalize",
                    backgroundColor: "#333547",
                    fontWeight: "400",
                    height: "35px",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    marginLeft: "5px",
                    textTransform: "capitalize",
                    backgroundColor: "#333547",
                    fontWeight: "400",
                    height: "35px",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    marginLeft: "5px",
                    textTransform: "capitalize",
                    backgroundColor: "#333547",
                    fontWeight: "400",
                    height: "35px",
                  },
                }}
                onClick={() => {
                  if (firstPrice === 0) {
                    setStartPrice(1)
                  } else {
                    setStartPrice(Number(firstPrice))
                  }
                  setEndPrice(Number(lastPrice)), setPage(0)
                }}
              >
                Search
              </Button>
            </MenuItem>
          </Menu>
        </div>

        <Button
          variant="contained"
          sx={{
            "@media (max-width: 380px)": {
              fontSize: "0.8rem",
              textTransform: "capitalize",
              backgroundColor: "#333547",
              fontWeight: "400",
              height: "35px",
            },
            "@media (min-width: 380px) and (max-width: 600px)": {
              fontSize: "0.8rem",
              marginLeft: "5px",
              textTransform: "capitalize",
              backgroundColor: "#333547",
              fontWeight: "400",
              height: "35px",
            },
            "@media (min-width: 600px) and (max-width: 960px)": {
              fontSize: "0.8rem",
              marginLeft: "5px",
              textTransform: "capitalize",
              backgroundColor: "#333547",
              fontWeight: "400",
              height: "35px",
            },
            "@media (min-width: 960px)": {
              fontSize: "0.8rem",
              marginLeft: "5px",
              textTransform: "capitalize",
              backgroundColor: "#333547",
              fontWeight: "400",
              height: "35px",
            },
          }}
          onClick={() => {
            setSelectedStatus("Status"),
              setSelectedCategory(),
              setSelectedCategoryName("Category")
            setStartPrice(0),
              setEndPrice(1000000),
              setFirstPrice(),
              setLastPrice(),
              setPage(0)
          }}
        >
          Clear
        </Button>
      </div>

      <div>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {productsData?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Sale Price
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsData?.map(item => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item._id}
                    >
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.name?.en}
                        {/* {""} */}
                        {/* {` (${item?.name?.ar})`} */}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {formatCurrency(item?.price)}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.salePrice
                          ? formatCurrency(item?.salePrice)
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.getCategories?.name?.en}
                        {/* {""} */}
                        {/* {` (${item?.getCategories?.name?.ar})`} */}
                      </TableCell>
                      <TableCell align="center" sx={tableBodyStyle}>
                        {item?.metadata?.isActive ? (
                          <CheckCircleIcon fontSize="small" />
                        ) : (
                          <CancelIcon fontSize="small" />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <IconButton onClick={() => {}}>
                                <VisibilityIcon fontSize="small" />
                              </IconButton>  */}
                            <IconButton
                              onClick={() => {
                                setMenuId(item?._id)
                                navigate(`/editproduct/${item?._id}`)
                              }}
                            >
                              <EditNoteIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpenDialog(true)
                                setDeleteItem(item)
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>

                            <IconButton onClick={() => handleCopyLink(item)}>
                              <ShareIcon fontSize="small" />
                            </IconButton>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontFamily: "outfit" }}
            sx={tableFooterStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={productsDataCount || 0}
            rowsPerPage={productListOptions.limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

export default Products

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
