import React, { useContext, useEffect, useState, useReducer } from "react"
import {
  useAudienceBookings,
  useBookingServices,
  useCurrentProvider,
} from "common/Hooks/queryCalls"
import { createOneCustomer } from "./dbServices"
const initValue = {
  items: [],
  totalDuration: null,
  totalAmount: null,
}

export const ServiceContext = React.createContext({
  services: initValue,
  addToCart: item => {},
  updateQuantity: (item, quantity) => {},
  removeFromCart: item => {},
})

export const ACTIONS = {
  ADD_TO_CART: "add-to-cart",
  REMOVE_FROM_CART: "remove-from-cart",
  CLEAR_CART: "clear-cart",
  INIT_CART: "init-cart",
  SET_TOTAL: "set-total",
  SET_TOTAL_AMT: "set-total-amt",
}

function serviceReducer(services, action) {
  switch (action.type) {
    case ACTIONS.ADD_TO_CART:
      return addToCartAction(services, action.payload)
    case ACTIONS.REMOVE_FROM_CART:
      return removeFromCartAction(services, action.payload)
    case ACTIONS.CLEAR_CART:
      return initValue
    case ACTIONS.INIT_CART:
      return action.payload.initCart
    case ACTIONS.SET_TOTAL:
      return { ...services, totalDuration: action.payload }
    case ACTIONS.SET_TOTAL_AMT:
      return { ...services, totalAmount: action.payload }
    default:
      return services
  }
}

function addToCartAction(services, payload) {
  let updatedCartItems = [...services.items]

  const updatedItemIndex = updatedCartItems.findIndex(
    item => item._id.toString() === payload.item._id.toString()
  )
  if (updatedItemIndex < 0) {
    updatedCartItems.push({ ...payload.item, quantity: 1 })
  } else {
    const updatedItem = {
      ...updatedCartItems[updatedItemIndex],
    }
    updatedItem.quantity++
    updatedCartItems[updatedItemIndex] = updatedItem
  }

  return { ...services, items: updatedCartItems }
}
function removeFromCartAction(services, payload) {
  console.log(services, payload)
  let updatedCartItems = [...services.items]

  const updatedItemIndex = updatedCartItems.findIndex(
    item => item._id?.toString() === payload.item?._id.toString()
  )
  const updatedItem = {
    ...updatedCartItems[updatedItemIndex],
  }
  updatedItem.quantity--
  if (updatedItem.quantity <= 0) {
    updatedCartItems.splice(updatedItemIndex, 1)
  } else {
    updatedCartItems[updatedItemIndex] = updatedItem
  }

  return { ...services, items: updatedCartItems }
}

export const ServiceProvider = ({ children }) => {
  const [entityId, setEntityId] = useState()
  const [services, dispatch] = useReducer(serviceReducer, initValue)
  const currentProvider = useCurrentProvider()

  useEffect(() => {
    calculateTotalAmt()
    calculateTotal()
  }, [services.items])

  const addToCart = item => {
    dispatch({ type: ACTIONS.ADD_TO_CART, payload: { item } })
  }

  const removeFromCart = item => {
    dispatch({ type: ACTIONS.REMOVE_FROM_CART, payload: { item } })
  }

  const calculateTotal = () => {
    const { totalHours, totalMinutes } = services?.items?.reduce(
      (total, services) => {
        const { duration } = services
        const { HH, MM } = duration
        total.totalHours += HH
        total.totalMinutes += MM
        return total
      },
      { totalHours: 0, totalMinutes: 0 }
    )

    const adjustedHours = totalHours + Math.floor(totalMinutes / 60)
    const adjustedMinutes = totalMinutes % 60
    dispatch({
      type: ACTIONS.SET_TOTAL,
      payload: { HH: adjustedHours, MM: adjustedMinutes },
    })
  }

  const calculateTotalAmt = () => {
    let total = 0
    services.items.forEach(item => {
      total += item.quantity * (item.salePrice || item.price)
    })
    dispatch({
      type: ACTIONS.SET_TOTAL_AMT,
      payload: {
        itemTotal: total,
        amountPayable: total,
      },
    })
  }

  const clearCart = () => {
    dispatch({ type: ACTIONS.CLEAR_CART })
  }

  const {
    data: bookingServicesData,
    isLoading: bookingServicesIsLoading,
    refetch: bookingServicesRefetch,
  } = useBookingServices({
    providerId: currentProvider?.data?._id,
  })

  const {
    data: audience,
    isLoading: isAudienceLoading,
    refetch: audienceRefetch,
  } = useAudienceBookings({
    providerId: currentProvider?.data?._id,
  })

  
  //to create a customer
  const createCustomer = async ({ data }) => {
    try {
      const result = await createOneCustomer({ data })
      return result
      // console.log("result:", result)
    } catch (error) {
      throw error
    }
  }


  return (
    <ServiceContext.Provider
      value={{
        services,
        addToCart,
        removeFromCart,
        clearCart,
        bookingServicesData,
        bookingServicesIsLoading,
        bookingServicesRefetch,
        branchId: currentProvider?.data?.branch?._id,
        providerId: currentProvider?.data?._id,
        audience,
        isAudienceLoading,
        audienceRefetch,
        entityId,
        setEntityId,
        createCustomer
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

export function useServiceCart() {
  return useContext(ServiceContext)
}
