import React, { useEffect, useState } from "react"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import "../../css/branches.css"
import InputAdornment from "@mui/material/InputAdornment"
import { useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import Autocomplete from "@mui/material/Autocomplete"
import {
  useBranchCity,
  useBranchCitySetting,
  usePrefixCheck,
} from "common/Hooks/queryCalls"
import Maps from "components/Maps/maps"
import { addBranch, updateBranch } from "common/Services/dbServices"
const AddBranch = ({
  providerId,
  setOpen,
  edit,
  specificProvider = null,
  refetch,
  setEdit,
  setShowSuccessAdded,
  setShowError,
  handleCloseBranch,
}) => {
  const navigate = useNavigate()
  const [flag, setFlag] = useState(false)
  const [cityId, setCityId] = useState("")
  const [loading, setLoading] = React.useState(false)
  const [geoCode, setGeoCode] = useState(null)
  const [checkPrefix, setCheckPrefix] = useState("")
  const [checkNumber, setCheckNumber] = useState("")
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)
  const prefixCheck = usePrefixCheck({ fieldValue: checkPrefix })
  const numberCheck = usePrefixCheck({ fieldValue: checkNumber })
  const { data, isLoading } = useBranchCity()
  const citySetting = useBranchCitySetting({ cityId })

  const selectedAddress = localStorage.getItem("mapAddress")
  const selectedGeoMarker = JSON.parse(localStorage.getItem("mapGeoCode"))

  const editenglishName = localStorage.getItem("englishName")

  const editnumber = localStorage.getItem("number")
  const editemail = localStorage.getItem("email")
  const editusername = localStorage.getItem("username")
  const editprefix = localStorage.getItem("prefix")
  const editId = localStorage.getItem("_id")
  const editcity = localStorage.getItem("city")
  const editUpdate = localStorage.getItem("edit")

  const validationSchema = Yup.object().shape({
    number: Yup.string().required("Number is required"),
    englishName: Yup.string().required(" Name is required"),
    email: Yup.string().required("Email is required"),
    username: Yup.string().required("UserName is required"),
    prefix: Yup.string().required("Prefix is required"),
    branchAddress: Yup.string().required("Address is required"),
    deliveryCharge: Yup.number()
      .typeError("Delivery Charge  must be a number")
      .required("Delivery Charge is required"),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const englishName = watch("englishName")
  //   const arabicName = watch("arabicName");
  const email = watch("email")
  const number = watch("number")
  const username = watch("username")
  const prefix = watch("prefix")
  // const city = watch("city");
  const address = watch("branchAddress")
  async function addNewBranch(data) {
    if (edit) {
      setLoading(true)
      try {
        const result = await updateBranch({
          providerId,
          itemId: editId,
          data,
        })
        if (result) {
          setLoading(false)
          setOpen(false)
          refetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
        }

        localStorage.removeItem("_id")
        localStorage.removeItem("englishName")
        localStorage.removeItem("email")
        localStorage.removeItem("number")
        localStorage.removeItem("username")
        localStorage.removeItem("prefix")
        localStorage.removeItem("mapAddress")
        localStorage.removeItem("mapGeoCode")
        localStorage.removeItem("city")
        localStorage.removeItem("edit")
      } catch (error) {
        setLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      setLoading(true)
      try {
        const result = await addBranch({ providerId, data })
        if (result) {
          setLoading(false)
          setOpen(false)
          refetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
        }

        localStorage.removeItem("_id")
        localStorage.removeItem("englishName")
        localStorage.removeItem("email")
        localStorage.removeItem("number")
        localStorage.removeItem("username")
        localStorage.removeItem("prefix")
        localStorage.removeItem("mapAddress")
        localStorage.removeItem("mapGeoCode")
        localStorage.removeItem("city")
        localStorage.removeItem("edit")
      } catch (error) {
        setLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    }
  }

  useEffect(() => {
    setValue("branchAddress", specificProvider ? specificProvider?.address : "")
    setValue("email", specificProvider ? specificProvider?.email : "")
    setValue("englishName", specificProvider ? specificProvider?.name?.en : "")
    setValue("number", specificProvider ? specificProvider?.number : "")
    setValue("username", specificProvider ? specificProvider?.username : "")
    setValue("prefix", specificProvider ? specificProvider?.checkPrefix : "")
    setValue(
      "deliveryCharge",
      specificProvider ? specificProvider?.deliveryCharge : ""
    )
    setCityId(specificProvider ? specificProvider?.city : "")
  }, [specificProvider])

  useEffect(() => {
    setValue("email", editemail ? editemail : "")
    setValue("englishName", editenglishName ? editenglishName : "")
    setValue("number", editnumber ? editnumber : "")
    setValue("username", editusername ? editusername : "")
    setValue("prefix", editprefix ? editprefix : "")
    setValue("branchAddress", selectedAddress)
    setValue(
      "deliveryCharge",
      specificProvider ? specificProvider?.deliveryCharge : ""
    )
    setCityId(editcity ? editcity : "")
    setGeoCode(selectedGeoMarker)
    if (editUpdate === "true") {
      setEdit(true)
    }
  }, [selectedAddress])

  useEffect(() => {
    setValue(
      "branchAddress",
      specificProvider?.address ? specificProvider?.address : ""
    )
  }, [])

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 350,
              height: "90%",
              border: "2px solid #000",
              boxShadow: 24,
              backgroundColor: "white",
              p: 4,
              "& .MuiTextField-root": { m: 0.5 },
              // "& .MuiButton-root": { m: 0.5 },
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              height: "80%",
              backgroundColor: "white",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              "& .MuiTextField-root": { m: 0.5 },
              // "& .MuiButton-root": { m: 0.5 },
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              height: "80%",
              border: "2px solid #000",
              backgroundColor: "white",
              boxShadow: 24,
              p: 4,
              "& .MuiTextField-root": { m: 0.5 },
              // "& .MuiButton-root": { m: 0.5 },
            },
          }}
        >
          <Maps />
        </Box>
      </Modal>
      <div className="branch-add-form-div">
        <h4>{edit ? `Edit Control` : `Add New Branch`}</h4>{" "}
        <form>
          <div className="branch-text-field">
            {" "}
            <div className="branch-text-field-row">
              {" "}
              <TextField
                // sx={styleTextField}
                fullWidth
                label="Name*"
                id="fullWidth"
                size="small"
                {...register("englishName", { required: true })}
                error={errors.englishName ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.englishName && errors.englishName?.message
                  ? errors.englishName?.message.toString()
                  : null}
              </Typography>
            </div>
            {/* <div className="branch-text-field-row">
              {" "}
              <TextField
                // sx={styleTextField}
                fullWidth
                label="اسم*"
                id="fullWidth"
                size="small"
                {...register("arabicName", { required: true })}
                error={errors.arabicName ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.arabicName && errors.arabicName?.message
                  ? errors.arabicName?.message.toString()
                  : null}
              </Typography>
            </div> */}
          </div>
          <div className="branch-text-field">
            <div className="branch-text-field-row">
              {" "}
              <TextField
                // sx={styleTextField}
                fullWidth
                label="Email*"
                id="fullWidth"
                size="small"
                {...register("email", { required: true })}
                error={errors.email ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.email && errors.email?.message
                  ? errors.email?.message.toString()
                  : null}
              </Typography>
            </div>
            <div className="branch-text-field-row">
              <TextField
                // sx={styleTextField}
                fullWidth
                label="Username*"
                size="small"
                {...register("username", { required: true })}
                error={errors.username ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.username && errors.username?.message
                  ? errors.username?.message.toString()
                  : null}
              </Typography>
            </div>
          </div>

          <div className="branch-text-field">
            <div className="branch-text-field-row">
              <TextField
                // sx={styleTextField}
                fullWidth
                label="Prefix*"
                size="small"
                {...register("prefix", { required: true })}
                error={errors.prefix ? true : false}
                onChange={newValue => setCheckPrefix(newValue.target.value)}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.prefix && errors.prefix?.message
                  ? errors.prefix?.message.toString()
                  : null}
              </Typography>
              {prefixCheck?.data?.length > 0 && (
                <Typography
                  variant="inherit"
                  color="textSecondary"
                  sx={{ textAlign: "center", color: "red" }}
                >
                  Prefix already taken!!
                </Typography>
              )}
            </div>
            <div className="branch-text-field-row">
              {" "}
              <TextField
                // sx={styleTextField}
                fullWidth
                label="Phone Number*"
                id="fullWidth"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                {...register("number", { required: true })}
                error={errors.number ? true : false}
                onChange={newValue => setCheckNumber(newValue.target.value)}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.number && errors.number?.message
                  ? errors.number?.message.toString()
                  : null}
              </Typography>
              {numberCheck?.data?.length > 0 && (
                <Typography
                  variant="inherit"
                  color="textSecondary"
                  sx={{ textAlign: "center", color: "red" }}
                >
                  Number already taken!!
                </Typography>
              )}
            </div>
          </div>
          <div className="branch-text-field">
            <div className="branch-text-field-row">
              {" "}
              <TextField
                // sx={styleTextField}
                fullWidth
                multiline
                label="Address*"
                size="small"
                {...register("branchAddress", { required: true })}
                error={errors.branchAddress ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.branchAddress && errors.branchAddress?.message
                  ? errors.branchAddress?.message.toString()
                  : null}
              </Typography>
            </div>
          </div>
          <div className="branch-text-field">
            <div className="branch-text-field-row">
              {" "}
              <TextField
                fullWidth
                label="Delivery Charge*"
                size="small"
                {...register("deliveryCharge", { required: true })}
                error={errors.deliveryCharge ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors &&
                errors.deliveryCharge &&
                errors.deliveryCharge?.message
                  ? errors.deliveryCharge?.message.toString()
                  : null}
              </Typography>
            </div>
            {/* <div className="branch-text-field-row">
              <Autocomplete
                options={data || []}
                fullWidth
                size="small"
                value={citySetting?.data?.label || ""}
                onChange={(event, newValue) => {
                  setCityId(newValue?.id)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select City"
                    size="small"
                    // sx={styleTextField}
                    // onChange={(newValue) =>
                    //   setSearchCity(newValue.target.value)
                    // }
                  />
                )}
              />
              <TextField
                // sx={styleTextField}
                fullWidth
                label="City"
                size="small"
                {...register("city", { required: true })}
                error={errors.city ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.city && errors.city?.message
                  ? errors.city?.message.toString()
                  : null}
              </Typography>
            </div> */}
          </div>

          <Box
            textAlign="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            {!loading ? (
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#190c33",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#190c33",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#190c33",
                  },
                }}
                onClick={() => {
                  localStorage.removeItem("_id")
                  localStorage.removeItem("englishName")
                  localStorage.removeItem("arabicName")
                  localStorage.removeItem("email")
                  localStorage.removeItem("number")
                  localStorage.removeItem("username")
                  localStorage.removeItem("prefix")
                  localStorage.removeItem("mapAddress")
                  localStorage.removeItem("mapGeoCode")
                  localStorage.removeItem("city")
                  localStorage.removeItem("edit")
                  //console.log("removed");
                  handleCloseBranch()
                }}
                style={{ fontFamily: "outfit" }}
              >
                Close
              </Button>
            ) : (
              ""
            )}
            {loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                // onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>Save</span>
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                }}
                onClick={handleSubmit(addNewBranch)}
                style={{ fontFamily: "outfit" }}
              >
                Save
              </Button>
            )}
          </Box>
        </form>
      </div>
    </>
  )
}

export default AddBranch

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const styleTextField = {
  "@media (max-width: 600px)": {
    "& .MuiInputBase-input": {
      height: "40px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 970px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
}
