import PropTypes from "prop-types"
import React from "react"
import ListGroup from "react-bootstrap/ListGroup"
import "../../css/dashboard-details.css"
import "../../css/orders.css"
import { Container } from "reactstrap"
import { useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Rating from "@mui/material/Rating"
import { formatDate } from "common/Services/functions"
import { IKImage } from "imagekitio-react"
import { urlEndpoint } from "../../components/ImageKit/ImageKitCred"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
const ReviewDetails = props => {
  const location = useLocation()
  const data = location.state
  const navigate = useNavigate()

  document.title = "Merche - Business"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <div className="order_update_btn_div">
          <Button
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                height: 35,
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                height: 35,
              },
              "@media (min-width: 970px)": {
                fontSize: "0.8rem",
                height: 35,
              },
            }}
            style={{ fontFamily: "outfit", margin: "8px" }}
            variant="outlined"
            onClick={() => {
              navigate(-1 || `/reviews`)
            }}
          >
            Back
          </Button>
          </div>
          <>
            <div>
              <ListGroup>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Date:</h6>
                      <h6 className="eachitem-div-h6">
                        {" "}
                        {formatDate(data?.metaData?.createdAt)}
                      </h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Rating:</h6>
                      <h6 className="eachitem-div-h6">
                        <Rating
                          name="read-only"
                          value={data?.rating || 0}
                          readOnly
                        />
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Review:</h6>
                      <h6 className="eachitem-div-h6">{data?.review}</h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Active:</h6>
                      <h6 className="eachitem-div-h6">
                        {data?.metaData?.isActive.toString()}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                {data?.image && data.image.length > 0 ? (
                  <ListGroup.Item>
                    <div className="listgroupitem-row">
                      <div>
                        <h6>Images:</h6>
                        <div className="eachitem-div-h6" style={{display:'flex'}}>
                          {data.image.map((image, index) => (
                           <div style={{margin:'5px'}}>
                             <IKImage
                              key={index}
                              urlEndpoint={urlEndpoint}
                              path={image.filePath}
                              width="250px"
                              height="150px"
                            />
                           </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ) : null}
              </ListGroup>
            </div>
          </>
        </Container>
      </div>
    </React.Fragment>
  )
}

ReviewDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ReviewDetails)
