import PropTypes from 'prop-types';
import React, { useContext, useEffect } from "react";
import withRouter from 'components/Common/withRouter';

import { logoutUser } from "../../store/actions";
//redux
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'Context/userContext';

const Logout = props => {
  const {fetchUser, emailPasswordLogin,user } = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // if (user && user) {
  //   app.currentUser.logOut();
  //   window.location.reload();
  // } else {
  //   window.location.reload();
  // }

  
  useEffect(() => {
    dispatch(logoutUser(navigate));
    window.location.reload();
    localStorage. clear() 
  }, [dispatch,navigate,user]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object
};


export default withRouter(Logout);
