import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Form,
  Input,
} from "reactstrap"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"

import { Button } from "@mui/material"
import {
  getServiceCategory,
  getCustomers,
  createCouponCode,
  ObjectId,
  updateCoupon,
  getCouponCode,
  getServiceTypesData,
} from "common/Services/dbServices"
import Select from "react-select"
import Switch from "react-switch"
import { useParams } from "react-router-dom"
import { useFormik } from "formik"
import moment from "moment"
import { useProduct } from "common/Services/product"
import { useCustomer } from "common/Services/customer"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"

const FormElements = () => {
  const navigate = useNavigate()

  const [categoryType, setCategoryType] = useState("")
  const [productType, setProductType] = useState("")

  const [customer, setCustomer] = useState([])
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [isActive, setisActive] = useState(false)
  //   const [isServiceChargeActive,setIsServiceChargeActive] = useState(false)
  const [type, setType] = useState("")
  const [response, setResponse] = useState(null)
  const urlParams = useParams()
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const {
    providerId,
    categoryData,
    productsData,
    categoryIsLoading,
    categoryRefetch,
    categoryDelete,
    fetchAllCategoryData,
    couponProductsData,
    couponProductsLoading,
    couponProductsRefetch,
    couponCustomersData,
    couponCustomersLoading,
    couponCustomersRefetch,
  } = useProduct()

  const { customers, isCustomersLoading, customersRefetch } = useCustomer()

  const initialValues = {
    couponName: "",
    couponDescription: "",
    couponCode: "",
    includedCategoryTypes: null,
    excludedCategoryTypes: null,
    includedProductTypes: null,
    excludedProductTypes: null,
    includedCustomers: null,
    excludedCustomers: null,
    type: null,
    amount: null,
    validFrom: null,
    validTill: null,
  }

  useEffect(() => {
    if (response && categoryType) {
      setInputs()
    }
  }, [response, categoryType])

  useEffect(() => {
    if (response && productType) {
      setInputs()
    }
  }, [response, productType])

  useEffect(() => {
    if (response && customer) {
      setInputs()
    }
  }, [response, customer])

  useEffect(() => {
    getCouponData()
    // getCustomerData()
    getProductData()
  }, [fetchAllCategoryData, couponProductsData, couponCustomersData])

  // console.log("couponid===", urlParams.id)

  const onSubmit = async values => {
    // console.log("values ==",values)
    // console.log("Submit button clicked")
    if (urlParams.id === undefined) {
      try {
        const response = await createCouponCode({
          providerId: providerId,
          couponName: values.couponName,
          couponDescription: values.couponDescription,
          couponCode: values.couponCode,
          includedCategoryTypes: values.includedCategoryTypes?.map(s =>
            ObjectId(s.value)
          ),
          excludedCategoryTypes: values.excludedCategoryTypes?.map(s =>
            ObjectId(s.value)
          ),
          includedProductTypes: values.includedProductTypes?.map(s =>
            ObjectId(s.value)
          ),
          excludedProductTypes: values.excludedProductTypes?.map(s =>
            ObjectId(s.value)
          ),
          excludedCustomers: values.excludedCustomers?.map(s =>
            ObjectId(s.value)
          ),
          includedCustomers: values.includedCustomers?.map(s =>
            ObjectId(s.value)
          ),
          // type: values.type.value,
          type,
          amount: values.amount,
          validTill: values.validTill,
          validFrom: values.validFrom,
          toggleSwitch,
          isActive,
          //   isServiceChargeActive
        })
        // console.log("reponse===",response)

        setShowSuccess(true)
        setTimeout(() => {
          // console.log("Before navigate")
          navigate("/coupon")
          // console.log("after navigate")
        }, 1000)
      } catch (error) {
        setShowError(false)
        // setShowError(true);
      }
    } else {
      try {
        const res = await updateCoupon({
          editId: ObjectId(urlParams.id),
          couponName: values.couponName,
          couponDescription: values.couponDescription,
          couponCode: values.couponCode,
          includedCategoryTypes: values.includedCategoryTypes?.map(s =>
            ObjectId(s.value)
          ),
          excludedCategoryTypes: values.excludedCategoryTypes?.map(s =>
            ObjectId(s.value)
          ),
          includedProductTypes: values.includedProductTypes?.map(s =>
            ObjectId(s.value)
          ),
          excludedProductTypes: values.excludedProductTypes?.map(s =>
            ObjectId(s.value)
          ),
          excludedCustomers: values.excludedCustomers?.map(s =>
            ObjectId(s.value)
          ),
          includedCustomers: values.includedCustomers?.map(s =>
            ObjectId(s.value)
          ),
          type,
          amount: values.amount,
          validTill: values.validTill,
          validFrom: values.validFrom,
          toggleSwitch,
          isActive,
          //   isServiceChargeActive,
        })
        setShowSuccess(true)
        setTimeout(() => {
          navigate("/coupon")
        }, 1000)
      } catch (e) {
        // console.log("e=", e)
        setShowError(false)
        // setShowError(true);
      }
    }
  }

  const validate = values => {
    // console.log("validate=", values)
    let errors = {}
    if (!values.couponName) {
      errors.couponName = "Coupon name required"
    }
    if (!values.couponDescription) {
      errors.couponDescription = "Coupon description required"
    }
    if (!values.couponCode) {
      errors.couponCode = "Coupon code value required"
    }
    // if (!values.includedCategoryTypes) {
    //   errors.includedCategoryTypes = "Included category types required"
    // }
    // if (!values.excludedCategoryTypes) {
    //   errors.excludedCategoryTypes = "Excluded category types required"
    // }
    // if (!values.includedProductTypes) {
    //   errors.includedProductTypes = "Included product types required"
    // }
    // if (!values.excludedProductTypes) {
    //   errors.excludedProductTypes = "Excluded product types required"
    // }
    // if (!values.includedCustomers) {
    //   errors.includedCustomers = "Included customers is required"
    // }
    // if (!values.excludedCustomers) {
    //   errors.excludedCustomers = "Excluded customers is required"
    // }
    // if (!values.type) {
    //   errors.type = "type is required"
    // }
    if (!values.amount) {
      errors.amount = "amount is required"
    }
    if (!values.validFrom) {
      errors.validFrom = "validFrom is required"
    }
    if (!values.validTill) {
      errors.validTill = "validTill is required"
    }
    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  const getProductData = async () => {
    try {
      const result = couponProductsData

      if (couponProductsData && fetchAllCategoryData) {
        let products = []
        let category = []
        let Cus = []
        couponProductsData.map((item, index) => {
          let obj1 = {
            value: item?._id.toString(),
            label: item?.name.en,
          }

          products.push(obj1)
        })
        fetchAllCategoryData.map((item, index) => {
          let obj2 = {
            value: item?._id.toString(),
            label: item?.name.en,
          }

          category.push(obj2)
        })

        couponCustomersData.map((item, index) => {
          let obj3 = {
            value: item?._id.toString(),
            label: `${item?.customer?.firstName} ${item?.customer?.lastName}`,
          }

          Cus.push(obj3)
        })

        setCategoryType(category)
        setProductType(products)
        setCustomer(Cus)
      }
    } catch (e) {
      // console.log("Error = ",e)
    }
  }

  const getCouponData = async () => {
    try {
      const res = await getCouponCode({ id: urlParams.id })

      setResponse(res)
      setType(res[0]?.type)
      setToggleSwitch(res[0]?.toggleSwitch)
      setisActive(res[0]?.isActive)
    } catch (error) {
      console.error(error)
    }
  }
  // console.log("type ====",type)
  const setInputs = () => {
    // console.log("response=====", response[0])
    if (response[0]) {
      formik.setValues({
        couponName: response[0].name,
        couponDescription: response[0].description,
        couponCode: response[0].couponCode,
        includedCategoryTypes: categoryType
          ? categoryType.filter(item =>
              response[0].getIncludedCategoryTypes.some(
                v => v.name.en === item.label
              )
            )
          : null,
        excludedCategoryTypes: categoryType
          ? categoryType.filter(item =>
              response[0].getExcludedCategoryTypes.some(
                v => v.name.en === item.label
              )
            )
          : null,
        includedProductTypes: productType
          ? productType.filter(item =>
              response[0].getIncludedProductTypes.some(
                v => v.name.en === item.label
              )
            )
          : null,
        excludedProductTypes: productType
          ? productType.filter(item =>
              response[0].getExcludedProductTypes.some(
                v => v.name.en === item.label
              )
            )
          : null,
        includedCustomers: couponCustomersData
          ? couponCustomersData.filter(item =>
              response[0].getIncludedCustomers.some(
                v => `${v.firstName} ${v.lastName}` === item.label
              )
            )
          : null,
        excludedCustomers: couponCustomersData
          ? couponCustomersData.filter(item =>
              response[0].getExcludedCustomers.some(
                v => `${v.firstName} ${v.lastName}` === item.label
              )
            )
          : null,

        amount: response[0].amount,
        validFrom: moment(response[0].validFrom, "MM/DD/YYYY hh:mm A").format(
          "YYYY-MM-DD HH:mm"
        ),
        validTill: moment(response[0].validTill, "MM/DD/YYYY hh:mm A").format(
          "YYYY-MM-DD HH:mm"
        ),
      })
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        ON
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        OFF
      </div>
    )
  }

  const types = [
    { value: "flat", label: "flat" },
    { value: "percentage", label: "percentage" },
  ]

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setShowSuccess(false)
    setShowError(false)
  }

  // console.log("couponCustomersData:",couponCustomersData)
  // console.log("customers:",customers)

  // document.title = "Add Coupon | Merche"
  return (
    <React.Fragment>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            {urlParams.id
              ? `Coupon Updated Successfully`
              : `Coupon Added Successfully`}
          </Alert>
        </Snackbar>
      </Stack>
      <div className="page-content" style={{ marginTop: -50 }}>
        <Container fluid={true}>
          <h5>{urlParams.id ? `Edit Coupon` : `Add New Coupon `}</h5>{" "}
          <Row>
            <Col>
              <Card style={{ marginTop: "20px" }}>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{
                      marginTop: 10,
                      fontFamily: "outfit",
                      fontSize: " 16px",
                    }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Name</label>
                      <div className="col-md-6">
                        <Input
                          name="couponName"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.couponName}
                        />
                        {formik.touched.couponName &&
                        formik.errors.couponName ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.couponName}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="couponDescription"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.couponDescription}
                        />
                        {formik.touched.couponDescription &&
                        formik.errors.couponDescription ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.couponDescription}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Coupon Code
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Add Coupon Code"
                          name="couponCode"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.couponCode}
                        />
                        {formik.touched.couponCode &&
                        formik.errors.couponCode ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.couponCode}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Included CategoryTypes
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select CategoryType"
                          options={categoryType}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="includedCategoryTypes"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "includedCategoryTypes",
                              selectedOptions
                            )
                          }
                          value={formik.values.includedCategoryTypes}
                        />
                        {/* {formik.touched.includedCategoryTypes &&
                        formik.errors.includedCategoryTypes ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.includedCategoryTypes}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Excluded CategoryTypes
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select CategoryType"
                          options={categoryType}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="excludedCategoryTypes"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "excludedCategoryTypes",
                              selectedOptions
                            )
                          }
                          value={formik.values.excludedCategoryTypes}
                        />
                        {/* {formik.touched.excludedCategoryTypes &&
                        formik.errors.excludedCategoryTypes ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.excludedCategoryTypes}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Included ProductTypes
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select ProductType"
                          options={productType}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="includedProductTypes"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "includedProductTypes",
                              selectedOptions
                            )
                          }
                          value={formik.values.includedProductTypes}
                        />
                        {/* {formik.touched.includedProductTypes &&
                        formik.errors.includedProductTypes ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.includedProductTypes}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Excluded ProductTypes
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select ProductType"
                          options={productType}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="excludedProductTypes"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "excludedProductTypes",
                              selectedOptions
                            )
                          }
                          value={formik.values.excludedProductTypes}
                        />
                        {/* {formik.touched.excludedProductTypes &&
                        formik.errors.excludedProductTypes ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.excludedProductTypes}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Included Customers
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select Customer"
                          options={customer}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="includedCustomers"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "includedCustomers",
                              selectedOptions
                            )
                          }
                          value={formik.values.includedCustomers}
                        />
                        {formik.touched.includedCustomers &&
                        formik.errors.includedCustomers ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.includedCustomers}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Excluded Customers
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select Customer"
                          options={customer}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="excludedCustomers"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "excludedCustomers",
                              selectedOptions
                            )
                          }
                          value={formik.values.excludedCustomers}
                        />
                        {/* {formik.touched.excludedCustomers &&
                        formik.errors.excludedCustomers ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.excludedCustomers}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Type</label>
                      <div className="col-md-6">
                        <select
                          className="form-control form-control-lg"
                          value={type}
                          onChange={e => setType(e.target.value)}
                        >
                          <option value="" disabled defaultValue>
                            Select Type
                          </option>
                          <option value="flat" style={{ fontSize: "15px" }}>
                            Flat
                          </option>
                          <option value="percentage">Percentage</option>
                        </select>
                        {/* {formik.touched.type && formik.errors.type ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.type}
                          </div>
                        ) : null}

                        {/* <Select
                          placeholder="Select Type"
                          options={types}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                              color: "black",
                            }),
                          }}
                          name="amountType"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue("type", selectedOptions)
                          }
                          value={formik.values.type}
                        /> */}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Amount</label>
                      <div className="col-md-6">
                        <Input
                          name="amount"
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Select Amount"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.amount}
                        />
                        {formik.touched.amount && formik.errors.amount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.amount}
                          </div>
                        ) : null}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        ValidFrom{""}
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="datetime-local"
                          defaultValue="2019-08-19T13:45:00"
                          id="example-datetime-local-input"
                          name="validFrom"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.validFrom}
                        />
                        {formik.touched.validFrom && formik.errors.validFrom ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.validFrom}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        ValidTill{""}
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="datetime-local"
                          defaultValue="2019-08-19T13:45:00"
                          id="example-datetime-local-input"
                          name="validTill"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.validTill}
                        />
                        {formik.touched.validTill && formik.errors.validTill ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.validTill}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        UseOnly Once
                      </label>
                      <div className="col-md-6">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setToggleSwitch(!toggleSwitch)
                          }}
                          checked={toggleSwitch}
                        />
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        isActive
                      </label>
                      <div className="col-md-6">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setisActive(!isActive)
                          }}
                          checked={isActive}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label"></label>
                    </Row>
                    <Box
                      textAlign="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                        marginTop: "50px",
                      }}
                    >
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          variant="contained"
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#190c33",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.8rem",
                                backgroundColor: "#190c33",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.8rem",
                              backgroundColor: "#190c33",
                            },
                          }}
                          onClick={() => navigate("/Coupon")}
                          style={{ fontFamily: "outfit" }}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#2b87ae",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.8rem",
                                backgroundColor: "#2b87ae",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.8rem",
                              backgroundColor: "#2b87ae",
                            },
                          }}
                          style={{ fontFamily: "outfit" }}
                          type="submit"
                        >
                          {response && response[0] ? "Update" : "Save"}
                        </Button>
                      </div>
                    </Box>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
