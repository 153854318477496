import React, { useState, useEffect } from "react"
import "../../css/chat.css"
import {
  useChatList,
  useCurrentProvider,
  useCustomerOrders,
  useCustomerDetails,
  useInCompleteOrders,
} from "common/Hooks/queryCalls"
import {
  createNewMessage,
  getDBInstance,
  app,
  updateAmountPayable,
  updateUpiId,
  acceptOrderChat,
  updateReadMessage,
} from "common/Services/dbServices"
import { useRef } from "react"
import { useParams } from "react-router-dom"
import { IconButton } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import Skeleton from "@mui/material/Skeleton"
import { Button } from "@mui/material"
import Stack from "@mui/material/Stack"
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined"
import Avatar from "@mui/material/Avatar"
import { useNavigate } from "react-router-dom"
import { LottieLoading } from "common/LottieLoading"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import {
  formatDate,
  formatCurrency,
  formatDateTime,
} from "common/Services/functions"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Autocomplete from "@mui/material/Autocomplete"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined"
import { IKContext, IKUpload } from "imagekitio-react"
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "components/ImageKit/ImageKitCred"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useCustomer } from "common/Services/customer"

const Chat = () => {
  const navigate = useNavigate()
  const { customerId } = useParams()
  const ref = useRef(null)
  const [message, setMessage] = useState("")
  const [breakAsyncIterator, setBreakAsyncIterator] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [additionalCharges, setAdditionalCharges] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [amountPayable, setAmountPayable] = useState(0)
  const [updateOrderId, setUpdateOrderId] = useState(null)
  const [updateOrderRef, setUpdateOrderRef] = useState(null)
  const [openUpiDialog, setOpenUpiDialog] = React.useState(false)
  const [upiId, setUpiId] = useState("")
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  // const [resImages, setResImages] = useState({})
  const [resImagesLoading, setResImagesLoading] = useState(false)
  const [showImageError, setShowImageError] = useState(false)
  const inputRefTest = useRef(null)
  const [errorMessage, setErrorMessage] = useState("")

  const { customersRefetch } = useCustomer()

  const subscribe = async () => {
    const collectionChat = await getDBInstance(app).collection("chat")
    //console.log("collectionChat:", collectionChat)

    for await (const change of collectionChat.watch()) {
      // let breakAsyncIterator = false; // Later used to exit async iterator
      switch (change.operationType) {
        case "insert": {
          const { documentKey, fullDocument } = change
          //console.log(`new document chat: ${documentKey}`, fullDocument)
          // breakAsyncIterator = true;
          scrollToBottom()
          chatList?.refetch()
          setBreakAsyncIterator(!breakAsyncIterator)
          break
        }
        case "update": {
          const { documentKey, fullDocument } = change
          //console.log(`updated document chat: ${documentKey}`, fullDocument)
          scrollToBottom()
          chatList?.refetch()
          setBreakAsyncIterator(!breakAsyncIterator)
          break
        }
        case "replace": {
          const { documentKey, fullDocument } = change
          //console.log(`replaced document chat: ${documentKey}`, fullDocument)
          // breakAsyncIterator = true;
          scrollToBottom()
          chatList?.refetch()
          setBreakAsyncIterator(!breakAsyncIterator)
          break
        }
        case "delete": {
          const { documentKey } = change
          // console.log(`deleted document chat: ${documentKey}`)
          // breakAsyncIterator = true;
          scrollToBottom()
          chatList?.refetch()
          setBreakAsyncIterator(!breakAsyncIterator)
          break
        }
      }
      if (breakAsyncIterator) break // Exit async iterator
    }
  }

  const scrollToBottom = () => {
    const lastChildElement = ref.current?.lastElementChild
    lastChildElement?.scrollIntoView({ behavior: "smooth" })
    // console.log("lastChildElement:", lastChildElement)
  }

  const currentProvider = useCurrentProvider()
  // console.log("currentProvider:", currentProvider)
  const chatList = useChatList({
    providerId: currentProvider?.data?._id,
    branchId: currentProvider?.data?.branch?._id,
    customerId,
  })

  const handleUpdateRead = async () => {
    await updateReadMessage({ chatId: chatList?.data?._id })
    customersRefetch()
  }

  useEffect(() => {
    const result = totalAmount - discount
    // console.log(Number(result + additionalCharges))
    setAmountPayable(Number(result + additionalCharges))
  }, [totalAmount, additionalCharges, discount])

  ///subscription/////
  useEffect(() => {
    subscribe()
  }, [])
  ///subscription/////

  useEffect(() => {
    scrollToBottom()
  }, [message])

  useEffect(() => {}, [])

  useEffect(() => {
    handleUpdateRead()
  }, [chatList?.isLoading, chatList?.data])

  const customerOrders = useCustomerOrders({
    customerId,
    providerId: currentProvider?.data?._id,
  })
  // console.log("customerOrders:", customerOrders)

  const customerDetails = useCustomerDetails({
    customerId,
  })

  // console.log("customerDetails:", customerDetails)
  const ongoing = useInCompleteOrders({
    providerId: currentProvider?.data?._id,
    customerId,
  })

  // console.log("ongoing:", ongoing?.data)

  if (currentProvider.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  // console.log("chatList:", chatList)

  const handleInputChange = event => {
    setMessage(event.target.value)
  }
  const handleProcess = () => {
    // Clear the input field
    setMessage("")
  }

  const saveMessage = async () => {
    scrollToBottom()
    try {
      if (message?.length > 0) {
        await createNewMessage({
          message,
          providerId: currentProvider?.data?._id,
          branchId: currentProvider?.data?.branch?._id,
          customerId,
          senderEntity: "provider",
          type: "text",
        })
        chatList?.refetch()
        scrollToBottom()
        // console.log("saveMessage:", createCarRes);
      }
      // alert("Item Added Successfully")
    } catch (error) {
      // console.log("Error ==",error)
      alert("Failed to Add message")
    }
  }

  const handleClickMessage = async () => {
    await saveMessage()
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleClickMessage()
      handleProcess()
    }
  }

  const handlePaymentRequest = async () => {
    try {
      if (amountPayable > 0) {
        await createNewMessage({
          message: `upi://pay?pa=${currentProvider?.data?.upiId}&pn=${currentProvider?.data?.name?.en}&am=${amountPayable}&cu=INR`,
          providerId: currentProvider?.data?._id,
          branchId: currentProvider?.data?.branch?._id,
          customerId,
          senderEntity: "provider",
          type: "link",
          data: {
            orderId: updateOrderId,
            orderRefNumber: updateOrderRef,
            upiId: currentProvider?.data?.upiId,
            totalAmount,
            amountPayable,
            discount,
            additionalCharges,
          },
        })
        chatList?.refetch()
        scrollToBottom()
        const result = await updateAmountPayable({
          orderId: updateOrderId,
          providerId: currentProvider?.data?._id,
          amount: amountPayable,
          discount,
          additionalCharges,
        })
        // console.log("order updated:", result)
        ongoing?.refetch()
        handleClose()
      }
    } catch (error) {
      alert("Failed to Request Payment")
    }
  }
  // console.log("selected orderId:", updateOrderId?.toString())

  const handleClickOpenUpiDialog = () => {
    setOpenUpiDialog(true)
  }

  const handleCloseUpiDialog = () => {
    setOpenUpiDialog(false)
  }

  const handleClickOpen = () => {
    try {
      if (
        currentProvider?.data?.upiId &&
        currentProvider?.data?.upiId?.length > 0
      ) {
        setOpen(true)
      } else {
        setOpenUpiDialog(true)
      }
    } catch (error) {
      alert("Failed")
    }
  }

  const handleClose = () => {
    setOpen(false)
    setAdditionalCharges(0)
    setTotalAmount(0)
    setDiscount(0)
    setAmountPayable(0)
  }
  const handleUpdateUpiId = async () => {
    try {
      if (upiId) {
        const result = await updateUpiId({
          providerId: currentProvider?.data?._id,
          upiId,
        })
        currentProvider?.refetch()
        setOpenUpiDialog(false)
        setOpen(true)
        // console.log(result)
      }
    } catch (error) {
      alert("Failed to Add UPI ID")
    }
  }

  const handleAcceptOrder = async (orderId, orderRef) => {
    // setLoading(true)
    try {
      const result = await acceptOrderChat({
        id: orderId.toString(),
      })
      await createNewMessage({
        message: `Order is Accepted for Order No: ${orderRef}`,
        providerId: currentProvider?.data?._id,
        branchId: currentProvider?.data?.branch?._id,
        customerId,
        senderEntity: "system",
        type: "systemProvider",
        data: {
          orderId: orderId,
          orderRefNumber: orderRef,
        },
      })
      await createNewMessage({
        message: `Your Order is Accepted for Order No: ${orderRef}`,
        providerId: currentProvider?.data?._id,
        branchId: currentProvider?.data?.branch?._id,
        customerId,
        senderEntity: "system",
        type: "systemCustomer",
        data: {
          orderId: orderId,
          orderRefNumber: orderRef,
        },
      })
      if (result) {
        // refetch()
        // setLoading(false)
        setShowSuccess(true)
        setTimeout(() => {
          setShowSuccess(false)
        }, 3000)
      }
      // refetch()
    } catch (error) {
      // setLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccess(false)
    setShowError(false)
    setShowImageError(false)
  }

  const handleChatImage = async res => {
    try {
      await createNewMessage({
        message: "",
        providerId: currentProvider?.data?._id,
        branchId: currentProvider?.data?.branch?._id,
        customerId,
        senderEntity: "provider",
        type: "image",
        image: res,
      })
    } catch (error) {
      setShowImageError(true)
    }
  }

  const onErrorImages = err => {
    setResImagesLoading(false)
    setShowImageError(true)
  }

  const onSuccessImages = async res => {
    // setResImages(res)
    handleChatImage(res)
    setResImagesLoading(false)
  }
  const onUploadProgressImages = progress => {
    setResImagesLoading(true)
  }

  // console.log("image res:", resImages)

  // style={{ marginTop: "50px" }}
  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Order Accepted
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showImageError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Image Uploading failed!
          </Alert>
        </Snackbar>
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Payment Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Request payment from customer
          </DialogContentText>

          <Autocomplete
            options={ongoing?.data || []}
            autoHighlight
            onChange={(event, newValue) => {
              if (
                newValue &&
                newValue.totalAmount &&
                newValue._id &&
                newValue.label
              ) {
                setTotalAmount(newValue.totalAmount)
                setUpdateOrderId(newValue._id)
                setUpdateOrderRef(newValue.label)
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Search for Orders"
                variant="standard"
              />
            )}
          />

          <form>
            {" "}
            <TextField
              autoFocus
              margin="dense"
              label="Total Amount"
              type="number"
              fullWidth
              variant="standard"
              value={totalAmount}
              onChange={e => setTotalAmount(Number(e.target.value))}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Additional Amount"
              type="number"
              fullWidth
              variant="standard"
              value={additionalCharges}
              onChange={e => setAdditionalCharges(Number(e.target.value))}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Discount Amount"
              type="number"
              fullWidth
              variant="standard"
              value={discount}
              onChange={e => setDiscount(Number(e.target.value))}
            />
            <TextField
              label="Payable Amount"
              type="number"
              fullWidth
              variant="standard"
              size="small"
              value={amountPayable}
              // onChange={e => setDiscount(Number(e.target.value))}
              readOnly
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handlePaymentRequest}>Request</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpiDialog} onClose={handleCloseUpiDialog}>
        <DialogTitle>Add Your UPI ID</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your UPI ID is not added , please enter your upi id here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="UPI ID"
            type="text"
            fullWidth
            variant="standard"
            value={upiId}
            onChange={e => setUpiId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpiDialog}>Cancel</Button>
          <Button onClick={handleUpdateUpiId}>Update</Button>
        </DialogActions>
      </Dialog>
      <div className="chat-container-div" style={{ marginTop: "50px" }}>
        <div className="chat-container-div1">
          {" "}
          <Button
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.675rem",
                marginBottom: 1,
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.775rem",
                marginBottom: 1,
              },
              "@media (min-width: 970px)": {
                fontSize: "0.775rem",
                marginBottom: 1,
              },
            }}
            style={{ fontFamily: "outfit", margin: "8px" }}
            variant="outlined"
            onClick={() => {
              navigate(-1 || `/audience`)
            }}
          >
            Back
          </Button>{" "}
          <div className="chat-main-div">
            {chatList?.isLoading ? (
              <>{chatListSkelton}</>
            ) : !chatList?.isLoading && chatList?.data === undefined ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h6>Chat is not initiated by customer</h6>
              </div>
            ) : (
              <div className="chat-screen-div" ref={ref}>
                {chatList?.data?.getMessage?.map(item => {
                  return (
                    <>
                      {" "}
                      {item?.senderEntity === "provider" ? (
                        item?.senderEntity === "provider" &&
                        item?.type === "link" ? (
                          <div className="chat-screen-1">
                            <h6 className="chat-screen-1-h5-payment-show">
                              <div className="chat-screen-payment-show">
                                {item?.data?.upiId ? (
                                  <h6>UPI ID :{item?.data?.upiId}</h6>
                                ) : (
                                  ""
                                )}
                                {item?.data?.orderRefNumber ? (
                                  <h6>
                                    Order No :{item?.data?.orderRefNumber}
                                  </h6>
                                ) : (
                                  ""
                                )}
                                {item?.data?.additionalCharges ? (
                                  <h6>
                                    Additional Charges :
                                    {formatCurrency(
                                      item?.data?.additionalCharges
                                    )}
                                  </h6>
                                ) : (
                                  ""
                                )}
                                {item?.data?.discount ? (
                                  <h6>
                                    Discount :
                                    {formatCurrency(item?.data?.discount)}
                                  </h6>
                                ) : (
                                  ""
                                )}
                                {item?.data?.amountPayable ? (
                                  <h6>
                                    Amount Payable :
                                    {formatCurrency(item?.data?.amountPayable)}
                                  </h6>
                                ) : (
                                  ""
                                )}

                                <a
                                  style={{ color: "#00ff80" }}
                                  href={item?.text}
                                >
                                  Payment Request Sent{" "}
                                </a>
                              </div>
                            </h6>
                            <Stack
                              direction="column"
                              spacing={0.5}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Avatar
                                alt={chatList?.data?.getProvider?.name?.en}
                                src={chatList?.data?.getProvider?.icon?.url}
                              />
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.4rem",
                                    textAlign: "center",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.5rem",
                                      textAlign: "center",
                                    },
                                  "@media (min-width:  970px) ": {
                                    fontSize: "0.6rem",
                                    textAlign: "center",
                                  },
                                }}
                              >
                                {formatDateTime(item?.sendOn)}
                              </Typography>
                            </Stack>
                          </div>
                        ) : item?.senderEntity === "provider" &&
                          item?.type != "image" &&
                          item?.type === "text" ? (
                          <div className="chat-screen-1">
                            <h6 className="chat-screen-1-h5">{item?.text}</h6>
                            <Stack
                              direction="column"
                              spacing={0.5}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Avatar
                                alt={chatList?.data?.getProvider?.name?.en}
                                src={chatList?.data?.getProvider?.icon?.url}
                              />
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.4rem",
                                    textAlign: "center",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.5rem",
                                      textAlign: "center",
                                    },
                                  "@media (min-width:  970px) ": {
                                    fontSize: "0.6rem",
                                    textAlign: "center",
                                  },
                                }}
                              >
                                {formatDateTime(item?.sendOn)}
                              </Typography>
                            </Stack>
                          </div>
                        ) : item?.senderEntity === "provider" &&
                          item?.type === "image" &&
                          item?.type != "text" &&
                          item?.image?.url ? (
                          <div className="chat-screen-1">
                            <img
                              className="chat-screen-1-image"
                              src={item?.image?.url}
                              alt="image"
                            />
                            <Stack
                              direction="column"
                              spacing={0.5}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Avatar
                                alt={chatList?.data?.getProvider?.name?.en}
                                src={chatList?.data?.getProvider?.icon?.url}
                              />
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.4rem",
                                    textAlign: "center",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.5rem",
                                      textAlign: "center",
                                    },
                                  "@media (min-width:  970px) ": {
                                    fontSize: "0.6rem",
                                    textAlign: "center",
                                  },
                                }}
                              >
                                {formatDateTime(item?.sendOn)}
                              </Typography>
                            </Stack>
                          </div>
                        ) : (
                          ""
                        )
                      ) : item?.type === "systemProvider" &&
                        item?.type != "systemCustomer" ? (
                        <div className="chat-screen-2">
                          <Stack
                            direction="column"
                            spacing={0.5}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Avatar sx={{ color: "black" }}>
                              <SmartToyOutlinedIcon />
                            </Avatar>

                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.4rem",
                                  textAlign: "center",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.5rem",
                                    textAlign: "center",
                                  },
                                "@media (min-width:  970px) ": {
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                },
                              }}
                            >
                              {formatDateTime(item?.sendOn)}
                            </Typography>
                          </Stack>

                          <div className="chat-screen-2-h5-systemCustomer">
                            {" "}
                            <h6 className="chat-screen-2-h6-systemCustomer">
                              {item?.text}
                            </h6>
                            <Button
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.7rem",
                                    textAlign: "center",
                                  },
                                "@media (min-width:  970px) ": {
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                },
                              }}
                              onClick={() => {
                                navigate(
                                  `/${item?.data?.orderId}/order-details`
                                )
                              }}
                            >
                              view order
                            </Button>
                          </div>
                        </div>
                      ) : item?.type != "systemCustomer" &&
                        item?.type === "systemProviderAcceptance" ? (
                        <div className="chat-screen-2">
                          <Stack
                            direction="column"
                            spacing={0.5}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Avatar sx={{ color: "black" }}>
                              <SmartToyOutlinedIcon />
                            </Avatar>

                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.4rem",
                                  textAlign: "center",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.5rem",
                                    textAlign: "center",
                                  },
                                "@media (min-width:  970px) ": {
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                },
                              }}
                            >
                              {formatDateTime(item?.sendOn)}
                            </Typography>
                          </Stack>

                          <div className="chat-screen-2-h5-systemCustomer">
                            {" "}
                            <h6 className="chat-screen-2-h6-systemCustomer">
                              {item?.text}
                            </h6>
                            <Button
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.7rem",
                                    textAlign: "center",
                                  },
                                "@media (min-width:  970px) ": {
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                },
                              }}
                              onClick={() => {
                                handleAcceptOrder(
                                  item?.data?.orderId,
                                  item?.data?.orderRefNumber
                                )
                              }}
                            >
                              Accept Order
                            </Button>
                          </div>
                        </div>
                      ) : item?.type === "systemCustomer" &&
                        item?.type != "image" ? (
                        ""
                      ) : item?.type === "image" &&
                        item?.senderEntity === "customer" &&
                        item?.image?.url ? (
                        <div className="chat-screen-2">
                          <Stack
                            direction="column"
                            spacing={0.5}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Avatar
                              alt={
                                chatList?.data?.getCustomer?.firstName ||
                                chatList?.data?.getCustomer?.lastName
                              }
                              src={chatList?.data?.getCustomer?.googlePhoto}
                            />
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.4rem",
                                  textAlign: "center",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.5rem",
                                    textAlign: "center",
                                  },
                                "@media (min-width:  970px) ": {
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                },
                              }}
                            >
                              {formatDateTime(item?.sendOn)}
                            </Typography>
                          </Stack>

                          <img
                            className="chat-screen-1-image"
                            src={item?.image?.url}
                            alt="image"
                          />
                        </div>
                      ) : (
                        <div className="chat-screen-2">
                          <Stack
                            direction="column"
                            spacing={0.5}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Avatar
                              alt={
                                chatList?.data?.getCustomer?.firstName ||
                                chatList?.data?.getCustomer?.lastName
                              }
                              src={chatList?.data?.getCustomer?.googlePhoto}
                            />
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.4rem",
                                  textAlign: "center",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.5rem",
                                    textAlign: "center",
                                  },
                                "@media (min-width:  970px) ": {
                                  fontSize: "0.6rem",
                                  textAlign: "center",
                                },
                              }}
                            >
                              {formatDateTime(item?.sendOn)}
                            </Typography>
                          </Stack>

                          <h6 className="chat-screen-2-h5">{item?.text}</h6>
                        </div>
                      )}
                    </>
                  )
                })}
              </div>
            )}
            {!chatList?.isLoading && chatList?.data === undefined ? (
              ""
            ) : (
              <div
                className="chat-screen-footer-div"
                style={{ backgroundColor: "white", paddingTop: "20px" }}
              >
                <input
                  className="chat-message-input"
                  placeholder="Message..."
                  type="text"
                  value={message}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />

                <IKContext
                  publicKey={publicKey}
                  urlEndpoint={urlEndpoint}
                  authenticationEndpoint={authenticationEndpoint}
                >
                  {resImagesLoading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    <>
                      <IconButton onClick={() => inputRefTest.current.click()}>
                        {" "}
                        <AddPhotoAlternateOutlinedIcon fontSize="small" />
                      </IconButton>

                      <IKUpload
                        onError={onErrorImages}
                        onSuccess={onSuccessImages}
                        onUploadStart={onUploadProgressImages}
                        buttonProps={{ children: "+" }}
                        style={{ display: "none" }}
                        inputRef={inputRefTest}
                        accept="image/*"
                        onChange={e => {
                          // Check if the selected file is an image
                          const selectedFile = e.target.files[0]
                          if (selectedFile) {
                            if (!selectedFile.type.startsWith("image/")) {
                              // Check the file type and show an appropriate error message
                              if (
                                selectedFile.type.startsWith("application/pdf")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a PDF."
                                )
                              } else if (
                                selectedFile.type.startsWith("video/")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a video."
                                )
                              } else {
                                setErrorMessage("Please select an image file.")
                              }
                              // Clear the file input (optional)
                              e.target.value = null
                            } else {
                              // Clear any previous error messages
                              setErrorMessage("")
                            }
                          }
                        }}
                      />
                    </>
                  )}
                </IKContext>

                <IconButton onClick={() => handleClickOpen()}>
                  <CurrencyRupeeIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleClickMessage()
                    handleProcess()
                  }}
                  size="small"
                >
                  <SendIcon />
                </IconButton>
              </div>
            )}
          </div>{" "}
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{`Customer Details`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Number
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Email
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >{`${customerDetails?.data?.firstName} ${customerDetails?.data?.lastName}`}</TableCell>

                        <TableCell align="center" sx={tableBodyStyle}>
                          {customerDetails?.data?.number}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                        >{`${customerDetails?.data?.email}`}</TableCell>
                      </TableRow>
                    </TableBody>{" "}
                  </Table>
                </TableContainer>
              </Paper>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="chat-container-div2">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{`Orders (${
                customerOrders?.data?.length || 0
              })`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Order No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          View
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerOrders?.data?.map(item => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.refNumber}
                            </TableCell>

                            <TableCell align="center" sx={tableBodyStyle}>
                              {formatCurrency(item?.amount)}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {formatDate(item?.date)}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {" "}
                              <Button
                                onClick={() => {
                                  navigate(`/${item?._id}/order-details`)
                                }}
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                    },
                                  "@media (min-width: 960px)": {
                                    fontSize: "0.8rem",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                              >
                                view
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>{" "}
                  </Table>
                </TableContainer>
              </Paper>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  )
}

export default Chat
const chatListSkelton = (
  <div className="chat-screen-div">
    <>
      <div className="chat-screen-1">
        <h5></h5>
        <h5>
          <Skeleton variant="text" width={150} sx={{ fontSize: "2.5rem" }} />
        </h5>
      </div>

      <div className="chat-screen-2">
        <h5>
          {" "}
          <Skeleton variant="text" width={150} sx={{ fontSize: "2.5rem" }} />
        </h5>
        <h5> </h5>
      </div>
      <div className="chat-screen-1">
        <h5></h5>
        <h5>
          <Skeleton variant="text" width={150} sx={{ fontSize: "2.5rem" }} />
        </h5>
      </div>

      <div className="chat-screen-2">
        <h5>
          {" "}
          <Skeleton variant="text" width={150} sx={{ fontSize: "2.5rem" }} />
        </h5>
        <h5> </h5>
      </div>
    </>
  </div>
)
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}
