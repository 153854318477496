import React, { useState, useEffect } from "react"
import "../../css/orders.css"
import { useProduct } from "common/Services/product"
import { useOrder } from "common/Services/order"
import { useCustomer } from "common/Services/customer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { formatCurrency } from "common/Services/functions"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import Checkbox from "@mui/material/Checkbox"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import { IconButton } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import AddBoxIcon from "@mui/icons-material/AddBox"
import DoneIcon from "@mui/icons-material/Done"
import { Button } from "@mui/material"
import { orderCreate } from "common/Services/dbServices"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import CreateCustomer from "./CreateCustomer"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import { useNavigate } from "react-router-dom"
import { Row, Col } from "reactstrap"
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const createOrder = () => {
  const navigate = useNavigate()
  const [showError, setShowError] = useState(false)
  const [showAddProductSuccess, setShowAddProductSuccess] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openProductModal, setOpenProductModal] = useState(false)
  const [openCustomerModal, setOpenCustomerModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [createdCustomer, setCreateCustomer] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(3)
  const [productSearch, setProductSearch] = useState("")
  const [customerSearch, setCustomerSearch] = useState("")
  const {
    filteringProductsData,
    filteringProductsIsLoading,
    filteringProductsRefetch,
    searchProducts,
    setSearchProducts,
    addToCart,
    updateQuantity,
    removeFromCart,
    cart,
    clearCart,
  } = useProduct()
  const { branchId, providerId, ongoingOrdersRefetch } = useOrder()

  const {
    customerAddresses,
    customerAddressesIsLoading,
    customerAddressesRefetch,
    entityId,
    setEntityId,
    createCustomer,
    createAddress,
    oneAddress,
    audience,
    isAudienceLoading,
    audienceRefetch,
  } = useCustomer()

  // console.log(filteringProductsData, filteringProductsIsLoading)

  useEffect(() => {
    filteringProductsRefetch()
  }, [searchProducts])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // console.log("cart:", cart)
  // console.log("customer:", customers)
  // console.log("selectedCustomer:", selectedCustomer)
  // console.log("selectedCustomerAddress:", selectedCustomerAddress)
  // console.log("createdCustomer:", createdCustomer)
  const handleCreateOrder = async () => {
    try {
      if (
        branchId &&
        selectedCustomer?._id &&
        providerId &&
        selectedCustomerAddress &&
        cart?.items.length > 0
      ) {
        const result = await orderCreate({
          branchId,
          customerId: selectedCustomer?._id,
          providerId,
          amount: cart?.total?.amountPayable,
          address: selectedCustomerAddress,
          providerType: "e-commerce",
          items: cart?.items,
          payments: cart?.total,
        })
        // console.log("created order:", result)

        setCreateCustomer([])
        setSelectedCustomer()
        setSelectedCustomerAddress()
        setEntityId()
        customerAddressesRefetch()
        audienceRefetch()
        clearCart()
        ongoingOrdersRefetch()
        setTimeout(() => {
          setBtnLoading(false)
          navigate(`/${result.insertedId}/order-details`)
        }, 1000)
      } else {
        const customer = await createCustomer({ data: createdCustomer[0] })
        const address = await createAddress({
          entityId: customer.insertedId,
          data: createdCustomer[0],
        })
        const findAddress = await oneAddress({
          entityId: customer.insertedId.toString(),
        })

        const result = await orderCreate({
          branchId,
          customerId: customer.insertedId,
          providerId,
          amount: cart?.total?.amountPayable,
          address: findAddress,
          providerType: "e-commerce",
          items: cart?.items,
          payments: cart?.total,
        })
        // console.log("created customer else:", customer)
        // console.log("created address else:", address)
        // console.log("created findAddress else:", findAddress)
        // console.log("created order else:", result)

        setCreateCustomer([])
        setSelectedCustomer()
        setSelectedCustomerAddress()
        setEntityId()
        customerAddressesRefetch()
        audienceRefetch()
        clearCart()
        ongoingOrdersRefetch()
        setTimeout(() => {
          setBtnLoading(false)
          navigate(`/${result.insertedId}/order-details`)
        }, 1000)
      }
    } catch (error) {
      setBtnLoading(false)
      setShowError(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
    setEdit(false)
  }
  const handleCloseProductModal = () => {
    setOpenProductModal(false)
  }
  const handleCloseCustomerModal = () => {
    setOpenCustomerModal(false)
  }
  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setShowError(false)
    setShowAddProductSuccess(false)
  }

  const filteredProducts = filteringProductsData?.filter(product =>
    product.name.en?.toLowerCase()?.includes(productSearch.toLowerCase())
  )
  const filteredcustomers = audience?.filter(
    customer =>
      customer.customer.firstName
        ?.toLowerCase()
        ?.includes(customerSearch.toLowerCase()) ||
      customer.customer.lastName
        ?.toLowerCase()
        ?.includes(customerSearch.toLowerCase())
  )
  // console.log(filteredcustomers)

  const getCartItems = item => {
    if (cart.items.length !== 0) {
      const index = cart?.items?.findIndex(cartItem => {
        return cartItem._id.toString() === item._id.toString()
      })

      if (index !== -1) {
        // Item is already in the cart
        return <span style={{ color: "green" }}>Added</span>
      } else {
        // Item is not in the cart
        return (
          <IconButton
            onClick={() => {
              setShowAddProductSuccess(true)
              addToCart(item)
            }}
          >
            <AddBoxIcon />
          </IconButton>
        )
      }
    } else {
      return (
        <IconButton
          onClick={() => {
            setShowAddProductSuccess(true)
            addToCart(item)
          }}
        >
          <AddBoxIcon />
        </IconButton>
      )
    }
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showAddProductSuccess}
          autoHideDuration={1500}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Product added to cart
          </Alert>
        </Snackbar>
      </Stack>
      <Modal
        open={openProductModal}
        onClose={handleCloseProductModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          {/* <Autocomplete
            autoComplete
            includeInputInList
            id="combo-box-demo"
            options={filteringProductsData || []}
            size="small"
            fontSize="0.6rem"
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                minWidth: 300,
                display: "flex",
                justifyContent: "center",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                minWidth: 230,
                display: "flex",
                justifyContent: "center",
                width: 300,
              },
              "@media (min-width: 970px)": {
                fontSize: "0.8rem",
                width: "350px",
                display: "flex",
                justifyContent: "center",
              },
            }}
            onChange={(event, newValue) => {
              if (newValue && newValue._id) {
                filteringProductsRefetch()
              }
            }}
            onClick={() => {
              filteringProductsRefetch()
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Search For Products"
                variant="outlined"
                onChange={newValue => {
                  setSearchProducts(newValue.target.value)
                  filteringProductsRefetch()
                }}
              />
            )}
          /> */}
          <div className="product-search-div">
            <TextField
              label="Search Product Name"
              variant="outlined"
              size="small"
              onChange={newValue => {
                setProductSearch(newValue.target.value)
              }}
            />
          </div>

          {filteringProductsIsLoading || filteringProductsData === undefined ? (
            <CircularProgress sx={{ marginTop: "50px" }} />
          ) : (
            <Paper sx={{ width: "100%", overflow: "hidden", padding: 0 }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  {filteringProductsData?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Items Available
                    </caption>
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Image
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Price
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(item => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={item._id}
                          >
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              <img
                                style={{
                                  height: "80px",
                                  width: "100px",
                                  borderRadius: "10px",
                                }}
                                src={item?.mainImage?.url || ""}
                              />
                            </TableCell>

                            <TableCell align="center" sx={tableBodyStyle}>
                              {item?.name?.en}
                            </TableCell>
                            <TableCell align="center">
                              {formatCurrency(item?.salePrice || item?.price)}
                            </TableCell>
                            <TableCell align="center">
                              {getCartItems(item)}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={tableFooterStyle}
                rowsPerPageOptions={[3, 5, 10, 15]}
                component="div"
                count={filteringProductsData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          <Box
            textAlign="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              paddingTop: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                clearCart()
                setOpenProductModal(false)
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => handleCloseProductModal()}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                setOpenProductModal(false)
              }}
            >
              {` Add : ${cart?.items?.length || 0}`}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openCustomerModal}
        onClose={handleCloseCustomerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <div className="customer-search-div">
            <TextField
              label="Search Customer Name"
              variant="outlined"
              size="small"
              onChange={newValue => {
                setCustomerSearch(newValue.target.value)
              }}
            />
          </div>
          {isAudienceLoading ? (
            <CircularProgress sx={{ marginTop: "50px" }} />
          ) : (
            <Paper
              sx={{
                width: "100%",
                overflowY: "scroll",
                maxHeight: "400px",
              }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  {audience?.length == 0 && createdCustomer.length === 0 ? (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Customers Have
                    </caption>
                  ) : (
                    ""
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Actions
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Customer Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Number
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Email
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {createdCustomer?.map(item => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item._id}
                        >
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            <Checkbox checked={true} />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >{`${item?.firstName} ${item?.lastName}`}</TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                          >{`+91 ${item?.number}`}</TableCell>
                          <TableCell align="center" sx={tableHeadStyle}>
                            {item?.email || `-`}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {filteredcustomers?.map(item => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item._id}
                        >
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            <Checkbox
                              checked={
                                selectedCustomer?._id.toString() ===
                                item?.customer?._id.toString()
                              }
                              onClick={() => {
                                if (
                                  selectedCustomer?._id.toString() ===
                                  item?.customer?._id.toString()
                                ) {
                                  setSelectedCustomer()
                                  setEntityId()
                                } else {
                                  setSelectedCustomer(item?.customer)
                                  setEntityId(item?.customer?._id)
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >{`${item?.customer?.firstName} ${item?.customer?.lastName}`}</TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                          >{`+91 ${item?.customer?.number}`}</TableCell>
                          <TableCell align="center" sx={tableBodyStyle}>
                            {item?.customer?.email || `-`}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
          <Box
            textAlign="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              paddingTop: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                setCreateCustomer([])
                setSelectedCustomer()
                setEntityId()
                setOpenCustomerModal(false)
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => handleCloseCustomerModal()}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                setOpenCustomerModal(false)
              }}
            >
              {`Add`}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <CreateCustomer
            providerId={providerId}
            setOpen={setOpen}
            edit={edit}
            setCreateCustomer={setCreateCustomer}
            // specificCategory={specificCategory}
            // refetch={categoryRefetch}
            // setShowSuccessAdded={setShowSuccessAdded}
            // setShowError={setShowError}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <div className="create-order-main-div">
        <div className="page-title-box">
          <div>
            {" "}
            <h6 className="page-title">Create Order</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Crafting New Orders</li>
            </ol>
          </div>
        </div>
        <div className="create-order-div">
          <div className="create-order-content-1">
            {/* <div className="create-order-product">
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#333547",
                  },
                }}
                variant="contained"
                onClick={() => {
                  setOpenProductModal(true)
                }}
                style={{ fontFamily: "outfit" }}
              >
                Add Products
              </Button>
            </div> */}
            <div className="create-order-cart">
              <div className="create-order-cart-btn-div">
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenProductModal(true)
                  }}
                  style={{ fontFamily: "outfit" }}
                >
                  Add Products
                </Button>
                {providerId &&
                branchId &&
                (selectedCustomer?._id || createdCustomer.length > 0) &&
                (selectedCustomerAddress?._id || createdCustomer.length > 0) &&
                cart?.items.length > 0 ? (
                  btnLoading ? (
                    <LoadingButton
                      size="small"
                      loading
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.6rem",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                      style={{ fontFamily: "outfit" }}
                    >
                      Create Order
                    </LoadingButton>
                  ) : (
                    <Button
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.6rem",
                          backgroundColor: "#333547",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#333547",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#333547",
                        },
                      }}
                      variant="contained"
                      onClick={() => {
                        if (branchId && providerId) {
                          setBtnLoading(true)
                          handleCreateOrder()
                        } else {
                          alert("order is mot created")
                        }
                      }}
                      style={{ fontFamily: "outfit" }}
                    >
                      Create Order
                    </Button>
                  )
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                    }}
                    variant="contained"
                    disabled
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Order
                  </Button>
                )}
              </div>
              <h6>Cart Items</h6>
              <Paper
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                  maxHeight: "400px", // Hide scrollbars in WebKit (Safari and Chrome)
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },

                  // Hide scrollbars in Firefox
                  scrollbarWidth: "none",
                }}
              >
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    {cart.items?.length == 0 && (
                      <caption style={{ fontFamily: "outfit" }}>
                        No Items In Cart
                      </caption>
                    )}
                    <TableHead>
                      <TableRow>
                        {" "}
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Images
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Actions
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Price
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {cart.items?.map(item => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={item._id}
                          >
                            {" "}
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              <img
                                style={{
                                  height: "80px",
                                  width: "100px",
                                  borderRadius: "10px",
                                }}
                                src={item?.mainImage?.url || ""}
                              />
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {item?.name?.en}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {" "}
                              <IconButton
                                onClick={() => {
                                  addToCart(item)
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  removeFromCart(item)
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center">
                              {item?.quantity}
                            </TableCell>{" "}
                            <TableCell align="center">
                              {formatCurrency(item?.salePrice || item?.price)}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>{" "}
              {cart.items?.length > 0 ? (
                <Stack spacing={1} sx={{ width: "100%" }}>
                  <Item>
                    Item Total : {formatCurrency(cart.total.itemTotal || 0)}
                  </Item>
                  <Item>
                    Grand Total :{" "}
                    {formatCurrency(cart.total.amountPayable || 0)}{" "}
                  </Item>
                </Stack>
              ) : (
                ""
              )}
            </div>
            <div className="create-order-customer">
              {/* <h6>Customer List </h6> */}
              <div className="create-order-cart-btn-div">
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenCustomerModal(true)
                  }}
                  style={{ fontFamily: "outfit" }}
                >
                  Add Customer
                </Button>
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  style={{ fontFamily: "outfit" }}
                  onClick={() => setOpen(true)}
                >
                  Create New Customer
                </Button>
              </div>
              <h6>Address List</h6>
              {customerAddressesIsLoading ? (
                <CircularProgress sx={{ marginTop: "50px" }} />
              ) : (
                <Paper
                  sx={{
                    width: "100%",
                    overflowY: "scroll",
                    maxHeight: "400px",
                    // Hide scrollbars in WebKit (Safari and Chrome)
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },

                    // Hide scrollbars in Firefox
                    scrollbarWidth: "none",
                  }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {customerAddresses?.length == 0 &&
                      createdCustomer.length === 0 ? (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Address Have
                        </caption>
                      ) : (
                        ""
                      )}
                      {customerAddresses === undefined &&
                      createdCustomer.length === 0 ? (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Customer Selected
                        </caption>
                      ) : (
                        ""
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            Actions
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            Address
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {createdCustomer?.map(item => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                            >
                              <TableCell
                                align="center"
                                sx={tableBodyStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                <Checkbox checked={true} />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={tableBodyStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                {`${item && item?.address},${item?.pincode},${
                                  item?.state
                                }${item.district ? `,${item?.district}` : ""} ${
                                  item.town ? `,${item?.town}` : ""
                                }`}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {customerAddresses?.map(item => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                            >
                              <TableCell
                                align="center"
                                sx={tableBodyStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                <Checkbox
                                  checked={
                                    selectedCustomerAddress?._id?.toString() ===
                                    item?._id?.toString()
                                  }
                                  onClick={() => {
                                    if (
                                      selectedCustomerAddress?._id?.toString() ===
                                      item?._id?.toString()
                                    ) {
                                      setSelectedCustomerAddress()
                                    } else {
                                      setSelectedCustomerAddress(item)
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={tableBodyStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                {`${item && item?.address},${item?.pincode},${
                                  item?.state
                                }${item.district ? `,${item?.district}` : ""} ${
                                  item.town ? `,${item?.town}` : ""
                                }`}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
              {providerId &&
              branchId &&
              (selectedCustomer?._id || createdCustomer.length > 0) &&
              (selectedCustomerAddress?._id || createdCustomer.length > 0) &&
              cart?.items.length > 0 ? (
                btnLoading ? (
                  <LoadingButton
                    size="small"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                      },
                    }}
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Order
                  </LoadingButton>
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      if (branchId && providerId) {
                        setBtnLoading(true)
                        handleCreateOrder()
                      } else {
                        alert("order is mot created")
                      }
                    }}
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Order
                  </Button>
                )
              ) : (
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  disabled
                  style={{ fontFamily: "outfit" }}
                >
                  Create Order
                </Button>
              )}
            </div>
          </div>
          <div className="create-order-content-2">
            {/* <div className="create-order-customer">
              <h6>Customer List </h6>
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    // backgroundColor: "#333547",
                    color: "#333547",
                    borderColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    color: "#333547",
                    borderColor: "#333547",
                    // backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    color: "#333547",
                    borderColor: "#333547",
                    // backgroundColor: "#333547",
                  },
                }}
                variant="outlined"
                style={{ fontFamily: "outfit" }}
                onClick={() => setOpen(true)}
              >
                Create New Customer
              </Button>
              {isCustomersLoading ? (
                <CircularProgress sx={{ marginTop: "50px" }} />
              ) : (
                <Paper
                  sx={{
                    width: "100%",
                    overflowY: "scroll",
                    maxHeight: "400px",
                  }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {customers?.length == 0 &&
                      createdCustomer.length === 0 ? (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Customers Have
                        </caption>
                      ) : (
                        ""
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          ></TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            Customer Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            Number
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            Email
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {createdCustomer?.map(item => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                            >
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                <Checkbox checked={true} />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >{`${item?.firstName} ${item?.lastName}`}</TableCell>
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                              >{`+91 ${item?.number}`}</TableCell>
                              <TableCell align="center" sx={tableHeadStyle}>
                                {item?.email || `-`}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {customers?.map(item => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                            >
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                <Checkbox
                                  checked={
                                    selectedCustomer?._id.toString() ===
                                    item?.customer?._id.toString()
                                  }
                                  onClick={() => {
                                    if (
                                      selectedCustomer?._id.toString() ===
                                      item?.customer?._id.toString()
                                    ) {
                                      setSelectedCustomer()
                                      setEntityId()
                                    } else {
                                      setSelectedCustomer(item?.customer)
                                      setEntityId(item?.customer?._id)
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >{`${item?.customer?.firstName} ${item?.customer?.lastName}`}</TableCell>
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                              >{`+91 ${item?.customer?.number}`}</TableCell>
                              <TableCell align="center" sx={tableHeadStyle}>
                                {item?.customer?.email || `-`}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
            </div> */}
            <div className="create-order-address">
              {/* <h6>Address List</h6>
              {customerAddressesIsLoading ? (
                <CircularProgress sx={{ marginTop: "50px" }} />
              ) : (
                <Paper
                  sx={{
                    width: "100%",
                    overflowY: "scroll",
                    maxHeight: "400px",
                  }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {customerAddresses?.length == 0 &&
                      createdCustomer.length === 0 ? (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Address Have
                        </caption>
                      ) : (
                        ""
                      )}
                      {customerAddresses === undefined &&
                      createdCustomer.length === 0 ? (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Customer Selected
                        </caption>
                      ) : (
                        ""
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          ></TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            Address
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {createdCustomer?.map(item => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                            >
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                <Checkbox checked={true} />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                {item && item?.address}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {customerAddresses?.map(item => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                            >
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                <Checkbox
                                  checked={
                                    selectedCustomerAddress?._id?.toString() ===
                                    item?._id?.toString()
                                  }
                                  onClick={() => {
                                    if (
                                      selectedCustomerAddress?._id?.toString() ===
                                      item?._id?.toString()
                                    ) {
                                      setSelectedCustomerAddress()
                                    } else {
                                      setSelectedCustomerAddress(item)
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                {item && item?.address}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
              {providerId &&
              branchId &&
              (selectedCustomer?._id || createdCustomer.length > 0) &&
              (selectedCustomerAddress?._id || createdCustomer.length > 0) &&
              cart?.items.length > 0 ? (
                btnLoading ? (
                  <LoadingButton
                    size="small"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                      },
                    }}
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Order
                  </LoadingButton>
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      if (branchId && providerId) {
                        setBtnLoading(true)
                        handleCreateOrder()
                      } else {
                        alert("order is mot created")
                      }
                    }}
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Order
                  </Button>
                )
              ) : (
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  disabled
                  style={{ fontFamily: "outfit" }}
                >
                  Create Order
                </Button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default createOrder

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
