import React, { useEffect } from "react"
import { useOrder } from "common/Services/order"
import { useCustomer } from "common/Services/customer"
import { useParams } from "react-router-dom"
import { formatDateTime } from "common/Services/functions"
import { Button } from "@mui/material"
import "../../css/orders.css"
import { sendEmailInvoiceOrderConfirmation } from "common/Services/dbServices"
import { result } from "lodash"
import { useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import SendIcon from "@mui/icons-material/Send"
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop"
import SaveIcon from "@mui/icons-material/Save"

function invoice() {
  const { id } = useParams()
  useEffect(() => {
    setOrderId(id)
  }, [])
  const { oneOrderDetails, setOrderId } = useOrder()
  const { currentProvider } = useCustomer()
  const [loading, setLoading] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const navigate = useNavigate()
  let htmlpage

  if (oneOrderDetails && currentProvider && currentProvider.data) {
    htmlpage = `
    <body
   style="background: #E0E0E0;font-family: 'Roboto', sans-serif;background-image: url(''); background-repeat: repeat-y; background-size: 100%; margin: 0;   box-sizing: border-box;">
   <div style="width: 100%;height: 100%; margin-top: 5vh;">
 
     <div style="margin: auto ;width: 700px;background: #fff; position: relative; padding: 3%; ">
 
       <table style="width: 100%;">
         <tr>
           <td style=" height: 50px; width: 85%;">
             <h1 style=" text-transform: uppercase; color: #00263D ;font-size: 26px; margin:0 ">
               invoice
             </h1>
           </td>
           <td>
             <img src="https://ik.imagekit.io/jhzzqw9we/Group_783_3viCPeEbL.png?tr=w-250,h-30"  alt="Merche">
           </td>
         </tr>
       </table>
       <hr style="
         border: 2px solid;
         border-color: #b9156f;
       " />
       <table style="width: 100%; min-height: 110px;">
         <tr>
           <td style="width:70%;vertical-align: top;">
             <img src="${
               currentProvider?.data?.icon?.url
             }" style="height: 100px;" alt="">
           </td>
           <td style="vertical-align: top; ">
             <h2 style="text-transform: uppercase; color: #00263D ;font-size: 17px; margin: 0px;">Invoice<span
                 style=" margin-left: 5px;">
                 ${oneOrderDetails.refNumber}</span></h2>
             <h5 style="text-transform: uppercase; font-size: 15px; color: #00263D ;margin: 0px;">Date : ${formatDateTime(
               oneOrderDetails.orderTrackingStatus.orderDeliveredDate
             )}<span
                 style=" margin-left: 17px;"> </span>
             </h5>
           </td>
         </tr>
       </table>
 
       <table style="width:100%; min-height: 150px;">
         <tr>
           <td  style="width:50%;" vertical-align: top;>
             <h2 style="text-transform: uppercase;color:  #00263D ;font-size: 18px; margin: 0;">Customer Details</h2>
             <p style="font-size: 15px;color: #000000;margin: 5px; text-transform: capitalize;">${
               oneOrderDetails.customer.firstName
             } ${oneOrderDetails.customer.lastName}<br>
              ${oneOrderDetails.customer.number}
              </p>
              <p style="font-size: 15px;color: #000000;margin: 5px;  vertical-align: top;">
                ${oneOrderDetails.customer.email} 
              </p>
               <br>
           </td>
           <td style="padding-left: 10%; vertical-align: top;">
             <h2 style="text-transform: uppercase;color:  #00263D ;font-size: 18px;margin: 0px;">Business Details</h2>
             <p style="font-size: 15px;color: #000000; margin: 5px; text-transform: capitalize;">
               ${currentProvider.data.name.en}<br> ${
      currentProvider.data.number
    }</p>
          <p style="font-size: 15px;color: #000000;margin: 5px;  vertical-align: top;">
          ${oneOrderDetails.customer.email} 
        </p>
           </td>
         </tr>
       </table>
 
       
 
       <table style="width:100%; min-height: 80px;">
         <tr>
           <td style="width:50%">
             <h2 style="text-transform: uppercase;color:  #00263D ;font-size: 18px; margin: 0px;">Order Status</h2>
             <p style="font-size: 15px;color: #000000; margin: 5px; text-transform: capitalize;"> ${
               oneOrderDetails.status
             }</p>
           </td>
           <td style="padding-left: 10%;">
             <h2 style="text-transform: uppercase;color:  #00263D;font-size: 18px; margin: 0px;">Order Method</h2>
             <p style="font-size: 15px;color: #000000; margin: 5px; text-transform: capitalize;">${
               oneOrderDetails.orderType === "inStorePickup"
                 ? "In Store Pickup"
                 : "Delivery"
             }</p>
           </td>
         </tr>
       </table>
 
 
       <div>
 
         <div style="width: 100%;border-collapse: collapse;">
           <table style=" width: 100%;border-collapse: collapse;">
             <tr style=" padding: 5px;">
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000; width: 15%;" class="item">
                 <h2 style="color:  #00263D; text-transform: uppercase;font-size: 18px; text-transform: capitalize;">
                   quantity </h2>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000; width: 38%;" class="item">
                 <h2 style="color:  #00263D; text-transform: uppercase;font-size: 18px; text-transform: capitalize;">particulars
                 </h2>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="Rate">
                 <h2
                   style="color:  #00263D; text-transform: uppercase;font-size: 18px;text-align: center; text-transform: capitalize;">
                   Cost</h2>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="Rate">
                 <h2
                   style="color:  #00263D; text-transform: uppercase;font-size: 18px;text-align: center; text-transform: capitalize;">
                  Total Cost</h2>
               </td>
               <!-- <td class="subtotal"><h2>Sub-total</h2></td> -->
             </tr>
             ${oneOrderDetails.items
               .map(item => {
                 return `
 
             <tr style=" border: 1px solid #EEE;">
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="tableitem">
                 <p style=" font-size: 15px;color: #222 ;text-transform: capitalize;">${
                   item.quantity
                 }</p>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="tableitem">
                 <p style=" font-size: 15px;color: #222;text-transform: capitalize;">${
                   item.name.en
                 }</p>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="tableitem">
                 <p style=" font-size: 15px;color: #222 ;text-align: center;text-align: center;">₹${
                   item.salePrice || item.price
                 }</p>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="tableitem">
                   <p style=" font-size: 15px;color: #222 ;text-align: center;text-align: center;">₹${
                     item.salePrice
                       ? item.salePrice * item.quantity
                       : item.price * item.quantity
                   }</p>
                 </td>
             </tr>
 
             `
               })
               .join("")} 
 
 
             <tr style="padding: 5px;">
               <td style="border: 0px solid #fff"></td>
               <td style="border: 0px solid #fff"></td>
               <td class="Rate" style="border: 0px solid #fff">
                 <p
                   style="font-size: 15px;color: #222;text-align:right ; margin-right: 5px; text-transform: capitalize;">
                   SubTotal</p>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="payment">
                 <p style="font-size: 15px;color: #222 ;text-align: center;text-align: center;">₹${
                   oneOrderDetails.payments.itemTotal
                 }</p>
               </td>
             </tr>
    
           ${
             oneOrderDetails.payments.deliveryCharge
               ? `<tr style="padding: 5px;">
                   <td style="border: 0px solid #fff"></td>
                   <td style="border: 0px solid #fff"></td>
                   <td class="Rate" style="border: 0px solid #fff">
                   <p style=" font-size: 15px;color: #222;text-align:right;margin-right: 5px; text-transform: capitalize;">
                       Delivery Charge</p>
                   </td>
                   <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="payment">
                   <p style=" font-size: 15px;color: #222 ;text-align: center;text-align: center;">₹${oneOrderDetails.payments.deliveryCharge}</p>
                   </td>
               </tr>`
               : `<tr></tr>`
           }
            
           ${
             oneOrderDetails.payments.additionalCharges
               ? `<tr style="padding: 5px;">
                   <td style="border: 0px solid #fff"></td>
                   <td style="border: 0px solid #fff"></td>
                   <td class="Rate" style="border: 0px solid #fff">
                   <p style=" font-size: 15px;color: #222;text-align:right;margin-right: 5px; text-transform: capitalize;">
                       Additional Charge</p>
                   </td>
                   <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="payment">
                   <p style=" font-size: 15px;color: #222 ;text-align: center;text-align: center;">₹${oneOrderDetails.payments.additionalCharges}</p>
                   </td>
               </tr>`
               : `<tr></tr>`
           }
 
           ${
             oneOrderDetails.payments.discount
               ? ` <tr style="padding: 5px;">
                   <td style="border: 0px solid #fff"></td>
                   <td style="border: 0px solid #fff"></td>
                   <td class="Rate" style="border: 0px solid #fff">
                   <p style=" font-size: 15px;color: #222;text-align:right;margin-right: 5px; text-transform: capitalize;">
                       Discount Amount</p>
                   </td>
                   <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="payment">
                   <p style=" font-size: 15px;color: #222 ;text-align: center;text-align: center;">₹${oneOrderDetails.payments.discount}</p>
                   </td>
               </tr>`
               : `<tr></tr>`
           }
             <tr style="padding: 5px;">
               <td style="border: 0px solid #fff"></td>
               <td style="border: 0px solid #fff"></td>
               <td class="Rate" style="border: 0px solid #fff">
                 <h2
                   style="color:  #00263D;font-size: 18px;text-align:right;margin-right: 5px; text-transform: capitalize;">
                   Total Amount</h2>
               </td>
               <td style="padding: 5px 0 5px 15px; border: 1px solid #000000" class="payment">
                 <h2 style="font-size: 15px;color: #222 ;text-align: center;text-align: center;">₹${
                   oneOrderDetails.payments.amountPayable
                 }</h2>
               </td>
             </tr>
 
           </table>
         </div><!--End Table-->
 
 
 
 
       </div><!--End InvoiceBot-->
     </div><!--End Invoice-->
   </div><!-- End Invoice Holder-->
 </body>
  `
  }
  const getPrint = () => {
    // window.print();
    var printableDiv = document.getElementById("printable-content")
    var printWindow = window.open("")
    printWindow.document.write(printableDiv.innerHTML)
    printWindow.document.close()
    printWindow.print()
  }

  const sendInvoice = () => {
    setLoading(true)

    try {
      let result = sendEmailInvoiceOrderConfirmation({ orderId: id })
      if (result) {
        setShowSuccess(true)
        setTimeout(() => {
          setShowSuccess(false)
        }, 3000)
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }
  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccess(false)
    setShowError(false)
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Invoice Send Successfully
          </Alert>
        </Snackbar>
      </Stack>
      <div style={{ marginTop: "20px" }}>
        <div className="buttonStyle">
          <Button
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 970px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
            }}
            style={{ fontFamily: "outfit", margin: "8px" }}
            variant="contained"
            onClick={() => {
              navigate(-1)
            }}
          >
            Back
          </Button>

          <div>
            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  width: 50,
                  height: 35,
                  marginRight: "5px",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  width: 90,
                  height: 35,
                  marginRight: "5px",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  width: 100,
                  height: 35,
                  marginRight: "5px",
                  backgroundColor: "#333547",
                },
              }}
              style={{ fontFamily: "outfit" }}
              variant="contained"
              startIcon={<LocalPrintshopIcon />}
              onClick={() => {
                getPrint()
              }}
            >
              Print
            </Button>

            {loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                // onClick={handleClick}
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 50,
                    height: 35,
                    marginRight: "5px",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 90,
                    height: 35,
                    marginRight: "5px",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 100,
                    height: 35,
                    marginRight: "5px",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
              >
                <span> send</span>
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 50,
                    height: 35,
                    marginRight: "5px",
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 90,
                    height: 35,
                    marginRight: "5px",
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 100,
                    height: 35,
                    marginRight: "5px",
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
                startIcon={<SendIcon />}
                onClick={() => {
                  sendInvoice()
                }}
              >
                send
              </Button>
            )}
          </div>
        </div>
        <div
          className="invoiceCardStyle"
          id="printable-content"
          dangerouslySetInnerHTML={{ __html: htmlpage }}
        ></div>
      </div>
    </>
  )
}

export default invoice
