import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Redux
import { connect, useDispatch } from "react-redux"

// import { withRouter, Link } from "react-router-dom"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-sm.png"
import Lottie from "lottie-react"
import UpdatePassword from "../../lottie/loading-and-done.json"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import { app } from "../../common/Services/dbServices"
import { useNavigate } from "react-router-dom"

const ForgetPasswordPage = props => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [resetted, setResetted] = React.useState(false)
  const dispatch = useDispatch()
  const params = new URLSearchParams(window.location.search)
  const token = params.get("token")
  const tokenId = params.get("tokenId")
  //   console.log(token, tokenId)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(6, "Password is too short - should be 6 chars minimum."),
    }),
    onSubmit: async values => {
      //   console.log("values:", values)
      setLoading(true)
      try {
        await app.emailPasswordAuth.resetPassword({
          password: values.password,
          token,
          tokenId,
        })
        setLoading(false)
        setResetted(true)
        setTimeout(() => {
          navigate("/login")
        }, 2000)
      } catch (error) {
        setLoading(false)
      }
    },
  })

  document.title = "Confirm Password | Merche Provider"
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">
                      Confirm Password
                    </h5>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  {props.forgetError && props.forgetError ? (
                    <Alert
                      color="danger"
                      style={{ marginTop: "13px" }}
                      className="mt-5"
                    >
                      {props.forgetError}
                    </Alert>
                  ) : null}
                  {props.forgetSuccessMsg ? (
                    <Alert
                      color="success"
                      style={{ marginTop: "13px" }}
                      className="mt-5"
                    >
                      {props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="mt-4"
                  >
                    <div className="mb-3">
                      <Label className="form-label" htmlor="userpassword">
                        Password
                      </Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter Password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          <div>{validation.errors.password}</div>
                        </FormFeedback>
                      ) : null}
                    </div>
                    {resetted ? (
                      <>
                        <Lottie
                          animationData={UpdatePassword}
                          loop={true}
                          style={{ width: "150px", height: "120px" }}
                        />
                        <h6>Your Password Changed,Please Login ... </h6>{" "}
                      </>
                    ) : (
                      ""
                    )}

                    <div className="row  mb-0">
                      <div className="col-12 text-end">
                        {loading ? (
                          <LoadingButton
                            color="secondary"
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            className="btn btn-primary w-md waves-effect waves-light"
                          >
                            <span>Reset</span>
                          </LoadingButton>
                        ) : (
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Sign In here{" "}
                  </Link>{" "}
                </p>
                {/* <p>
                  © {new Date().getFullYear()} Veltrix. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
