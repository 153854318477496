import React, { useState, useEffect } from "react"
import "../../css/categories.css"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import { useProduct } from "common/Services/product"


const AddCategories = ({
  providerId,
  setOpen,
  edit,
  specificCategory = null,
  refetch,
  setShowSuccessAdded,
  setShowError,
  handleClose,
}) => {
  const { categoryAdd, categoryUpdate, categoryCountRefetch } = useProduct()
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewCategory = async data => {
    if (edit) {
      setIsLoading(true)
      try {
        const result = await categoryUpdate({
          item: specificCategory,
          data,
          providerId,
        })
        // console.log("update:", result)
        setOpen(false)
        setIsLoading(false)
        categoryCountRefetch()
        refetch()
        setShowSuccessAdded(true)
        setTimeout(() => {
          setShowSuccessAdded(false)
        }, 3000)
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      setIsLoading(true)
      try {
        const result = await categoryAdd({
          data,
          providerId,
        })
        // console.log("add:", result)
        setOpen(false)
        setIsLoading(false)
        categoryCountRefetch()
        refetch()
        setShowSuccessAdded(true)
        setTimeout(() => {
          setShowSuccessAdded(false)
        }, 3000)
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    }
  }

  useEffect(() => {
    setValue("englishName", specificCategory?.name?.en)
  }, [])

  const handleFormSubmit = e => {
    e.preventDefault()
    handleSubmit(addNewCategory)()
  }

  return (
    <div>
      <h5 className="category-edit-title">
        {edit ? `Edit Category` : `Add New Category`}
      </h5>{" "}
      <form onSubmit={handleFormSubmit}>
        <div className="categories-text-field">
          <div className="categories-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewCategory)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddCategories
