import * as Realm from "realm-web"
import { createContext, useState, useContext } from "react"
import { app } from "../common/Services/dbServices"
import { useEffect } from "react"

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [customerData, setCustomerData] = useState()

  const emailPasswordLogin = async (email, password) => {
    const credentials = Realm.Credentials.emailPassword(email, password)
    const authUser = await app.logIn(credentials)
    setUser(authUser)
    return authUser
  }

  useEffect(() => {
    if (!user && app.currentUser) {
      setUser(app.currentUser)
    }
  }, [app, app.currentUser])

  const fetchUser = async () => {
    if (!app.currentUser) return false
    try {
      await app.currentUser.refreshCustomData()
      setUser(app.currentUser)
      return app.currentUser
    } catch (error) {
      throw error
    }
  }

  // Function to logout user from our Realm
  const logOutUser = async () => {
    if (!app.currentUser) return false
    try {
      await app.currentUser.logOut()
      setUser(null)
      return true
    } catch (error) {
      throw error
    }
  }
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
        emailPasswordLogin,
        logOutUser,
        customerData,
        setCustomerData,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
