import React, { useState, useEffect } from "react"
import "../../../css/variants.css"
import { Typography, Button, IconButton } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import { useProduct } from "common/Services/product"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { v4 as uuidv4 } from "uuid"
import InputAdornment from "@mui/material/InputAdornment"
import { LottieLoading } from "common/LottieLoading"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import { Row, Col } from "reactstrap"

const AddVariants = () => {
  const {
    providerId,
    productVariantsRefetch,
    variantAdd,
    variantUpdate,
    setVariantId,
    oneProductVariantData,
    oneProductVariantIsLoading,
    oneProductVariantSuccess,
    setShowSuccessAddedVariant,
    oneProductVariantRefetch,
    productVariantsRefetchForSelect,
  } = useProduct()
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [variantValue, setVariantValue] = useState("")
  const [items, setItems] = useState([])
  const [showError, setShowError] = useState(false)

  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewVariant = async data => {
    // console.log(data)
    if (id) {
      setIsLoading(true)
      try {
        if (items?.length > 0) {
          const result = await variantUpdate({
            id: id,
            data,
            providerId,
            options: items,
          })
          // console.log("update:", result)

          setIsLoading(false)
          oneProductVariantRefetch()
          productVariantsRefetch()
          productVariantsRefetchForSelect()
          setVariantId("")
          setItems([])
          navigate("/variants")
          setShowSuccessAddedVariant(true)
          setTimeout(() => {
            setShowSuccessAddedVariant(false)
          }, 3000)
        } else {
          setIsLoading(false)
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      setIsLoading(true)
      try {
        if (items?.length > 0) {
          const result = await variantAdd({
            data,
            providerId,
            options: items,
          })
          // console.log("add:", result)

          setIsLoading(false)
          oneProductVariantRefetch()
          productVariantsRefetch()
          productVariantsRefetchForSelect()
          setVariantId("")
          setItems([])
          navigate("/variants")
          setShowSuccessAddedVariant(true)
          setTimeout(() => {
            setShowSuccessAddedVariant(false)
          }, 3000)
        } else {
          setIsLoading(false)
          setShowError(true)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    }
  }

  useEffect(() => {
    if (id) {
      setVariantId(id)
    }
  }, [])
  useEffect(() => {
    setValue("englishName", oneProductVariantData?.name?.en)
    setItems(oneProductVariantData?.attributeOptions || [])
  }, [oneProductVariantSuccess])

  const handleAddItem = () => {
    if (variantValue.trim() === "") return

    const lowercaseVariantValue = variantValue.toLowerCase()
    const isDuplicate = items?.some(
      item => item.value.toLowerCase() === lowercaseVariantValue
    )

    if (!isDuplicate) {
      const newItem = {
        value: lowercaseVariantValue,
      }

      setItems([...items, newItem])
      setVariantValue("")
    } else {
      alert("Duplicate value not allowed.")
    }
  }
  const handleDeleteItem = itemId => {
    const updatedItems = items.filter(item => item._id !== itemId)
    setItems(updatedItems)
  }

  // console.log("value:", variantValue)
  // console.log("items:", items)
  // console.log("oneProductVariantData:", oneProductVariantData)

  if (oneProductVariantIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setShowError(false)
  }
  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <div className="variant-div">
        {/* <h5 style={{ textAlign: "center", padding: "20px" }}>
          {id ? `Edit Variant` : `Add New Variant`}
        </h5>{" "} */}
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <h6 className="page-title">
                {" "}
                {id ? `Edit Variant` : `Add New Variant`}
              </h6>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '5%', backgroundColor: 'white' }}>
          <form>
            <div className="variant-text-field">
              <div className="variant-text-field-row">
                {" "}
                <TextField
                  fullWidth
                  label="Name*"
                  id="fullWidth"
                  size="small"
                  {...register("englishName", { required: true })}
                  error={errors.englishName ? true : false}
                />
                <Typography
                  variant="inherit"
                  color="textSecondary"
                  sx={{ textAlign: "center" }}
                >
                  {errors && errors.englishName && errors.englishName?.message
                    ? errors.englishName?.message.toString()
                    : null}
                </Typography>
              </div>
            </div>

            <div className="variant-text-field-value-div">
              <div className="variant-value-add-div">
                <h6>
                  <span>Add each value</span>
                </h6>
                <div className="variant-text-field-row-btn">
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            sx={{
                              "@media (max-width: 600px)": {
                                fontSize: "0.6rem",
                                backgroundColor: "#2b87ae",
                              },
                              "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.8rem",
                                backgroundColor: "#2b87ae",
                              },
                              "@media (min-width: 970px)": {
                                fontSize: "0.8rem",
                                backgroundColor: "#2b87ae",
                              },
                            }}
                            variant="contained"
                            onClick={handleAddItem}
                          >
                            Add
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    value={variantValue}
                    label="Value*"
                    id="fullWidth"
                    size="small"
                    onChange={event => setVariantValue(event.target.value)}
                  />
                </div>
                <div className="variant-ul-div">
                  {items?.length === 0 ? (
                    <h6>No values added</h6>
                  ) : (
                    <ul className="variant-ul" style={{ padding: 0 }}>
                      {items?.map(item => (
                        <li
                          key={item.id}
                          style={{ listStyle: "none" }}
                          className="variant-li"
                        >
                          {item.value}{" "}
                          <IconButton onClick={() => handleDeleteItem(item._id)}>
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            <Box
              textAlign="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              {!isLoading ? (
                <Button
                  variant="contained"
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#190c33",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#190c33",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#190c33",
                    },
                  }}
                  onClick={() => {
                    setVariantId("")
                    setItems([])
                    navigate("/variants")
                  }}
                >
                  Close
                </Button>
              ) : (
                ""
              )}
              {isLoading ? (
                <LoadingButton
                  size="small"
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  Save
                </LoadingButton>
              ) : variantValue?.length === 0 ? (
                <Button
                  variant="contained"
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#2b87ae",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#2b87ae",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#2b87ae",
                    },
                  }}
                  onClick={handleSubmit(addNewVariant)}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#2b87ae",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#2b87ae",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#2b87ae",
                    },
                  }}
                  disabled
                >
                  Save
                </Button>
              )}
            </Box>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddVariants
