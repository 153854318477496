import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"
import Toolbar from "@mui/material/Toolbar"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { Button, IconButton } from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import EditNoteIcon from "@mui/icons-material/EditNote"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import AddCategories from "./AddCategories"
import { LottieLoading } from "../../common/LottieLoading"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import { useProduct } from "common/Services/product"
const Categories = () => {
  const {
    providerId,
    categoryData,
    categoryIsLoading,
    categoryRefetch,
    categoryDelete,
    categoryCount,
    categoryCountIsLoading,
    categoryCountRefetch,
    categoryListOptions,
    setCategoryListOption,
  } = useProduct()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [showSuccessAdded, setShowSuccessAdded] = useState(false)
  const [showSuccessRemoved, setShowSuccessRemoved] = useState(false)
  const [showError, setShowError] = useState(false)
  const [specificCategory, setSpecificCategory] = useState({})
  const [page, setPage] = useState(0)

  if (categoryIsLoading || categoryCountIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setSpecificCategory({})
  }

  const handleEdit = item => {
    setSpecificCategory(item)
    setOpen(true)
    setEdit(true)
  }

  const handleClickDelete = async () => {
    setIsDeleteLoading(true)
    try {
      const result = await categoryDelete({
        itemId: deleteItem?._id,
      })
      setOpenDialog(false)
      setIsDeleteLoading(false)
      categoryCountRefetch()
      categoryRefetch()
      setShowSuccessRemoved(true)
      setTimeout(() => {
        setShowSuccessRemoved(false)
      }, 3000)
    } catch (error) {
      setIsDeleteLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDeleteItem({})
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let tempOptions = categoryListOptions
    tempOptions.skip = newPage * tempOptions.limit
    setCategoryListOption(tempOptions)
    categoryRefetch()
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    let tempOptions = categoryListOptions
    tempOptions.skip = 0
    tempOptions.limit = +event.target.value
    setCategoryListOption(tempOptions)
    categoryRefetch()
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccessAdded(false)
    setShowSuccessRemoved(false)
    setShowError(false)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "outfit" }}>
          {"Are you sure you want to delete this Category?"}
        </DialogTitle>

        <DialogActions>
          {!isDeleteLoading ? (
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "outfit" }}
            >
              No
            </Button>
          ) : (
            ""
          )}
          {isDeleteLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Yes
            </LoadingButton>
          ) : (
            <Button
              onClick={handleClickDelete}
              autoFocus
              style={{ fontFamily: "outfit" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <AddCategories
            providerId={providerId}
            setOpen={setOpen}
            edit={edit}
            specificCategory={specificCategory}
            refetch={categoryRefetch}
            setShowSuccessAdded={setShowSuccessAdded}
            setShowError={setShowError}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessAdded}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            {edit
              ? `Category Successfully Updated`
              : `Category Successfully Added`}
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessRemoved}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Category Successfully Deleted
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>

      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h6 className="page-title">Categories</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Discover Your Categories Hub
              </li>
            </ol>
          </div>
          <div className="d-flex align-items-center">
            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
              }}
              variant="contained"
              onClick={() => {
                setEdit(false)
                setOpen(true)
              }}
              style={{ fontFamily: "outfit" }}
            >
              Add Category
            </Button>
          </div>
        </div>
      </div>

      <div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {categoryData?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Is Active
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryData?.map(item => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item._id}
                    >
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.name?.en}
                      </TableCell>

                      <TableCell align="center" sx={tableBodyStyle}>
                        {item?.metadata?.isActive ? (
                          <CheckCircleIcon fontSize="small" />
                        ) : (
                          <CancelIcon fontSize="small" />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                handleEdit(item)
                              }}
                            >
                              <EditNoteIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpenDialog(true)
                                setDeleteItem(item)
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>{" "}
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "center",
            }}
            colSpan={3}
            sx={tableFooterStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={categoryCount || 0}
            rowsPerPage={categoryListOptions.limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

export default Categories
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
