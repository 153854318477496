import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { Button, IconButton } from "@mui/material"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import AddAddon from "./AddAddon"
import DeleteIcon from "@mui/icons-material/Delete"
import EditNoteIcon from "@mui/icons-material/EditNote"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import { useCurrentProvider, useModifiers } from "common/Hooks/queryCalls"
import { LottieLoading } from "../../common/LottieLoading"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import { deleteAddons } from "common/Services/dbServices"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"

const Addons = () => {
  const [deleteLoading, setDeleteLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(4)
  const [specificAddon, setSpecificAddon] = useState({})
  const [edit, setEdit] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [showSuccessAdded, setShowSuccessAdded] = useState(false)
  const [showSuccessRemoved, setShowSuccessRemoved] = useState(false)
  const [showError, setShowError] = useState(false)
  const [coming, setComming] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setSpecificAddon({})
  }

  const handleEdit = item => {
    setSpecificAddon(item)
    setOpen(true)
    setEdit(true)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDeleteItem({})
  }
  const currentProvider = useCurrentProvider()

  const { data, isLoading, refetch } = useModifiers({
    providerId: currentProvider?.data?._id,
  })

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleClickDelete = async () => {
    setDeleteLoading(true)
    try {
      const result = await deleteAddons({
        providerId: currentProvider?.data?._id,
        modifierId: deleteItem?._id,
      })

      if (result) {
        refetch()
        setOpenDialog(false)
        setDeleteLoading(false)
        setShowSuccessRemoved(true)
        setTimeout(() => {
          setShowSuccessRemoved(false)
        }, 3000)
      }
    } catch (error) {
      setDeleteLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }
  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccessAdded(false)
    setShowSuccessRemoved(false)
    setShowError(false)
    setComming(false)
  }
 
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "outfit" }}>
          {"Are you sure you want to delete this Addon?"}
        </DialogTitle>

        <DialogActions>
          {!deleteLoading ? (
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "outfit" }}
            >
              No
            </Button>
          ) : (
            ""
          )}
          {deleteLoading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={deleteLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Yes</span>
            </LoadingButton>
          ) : (
            <Button
              onClick={handleClickDelete}
              autoFocus
              style={{ fontFamily: "outfit" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <AddAddon
            providerId={currentProvider?.data?._id}
            setOpen={setOpen}
            edit={edit}
            specificAddon={specificAddon}
            refetch={refetch}
            setShowSuccessAdded={setShowSuccessAdded}
            setShowError={setShowError}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessAdded}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            {edit ? `Addon Successfully Updated` : `Addon Successfully Added`}
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccessRemoved}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Addon Successfully Deleted
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={coming}
          autoHideDuration={5000}
          onClose={handleCloseToast}
          key={"top" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="warning"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            This feature is coming soon!
          </Alert>
        </Snackbar>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "5px",
          marginRight: "10px",
          marginTop: "30px",
        }}
      >
        <Button
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "0.6rem",
              backgroundColor: "#333547",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              fontSize: "0.8rem",
              backgroundColor: "#333547",
            },
            "@media (min-width: 970px)": {
              fontSize: "0.8rem",
              backgroundColor: "#333547",
            },
          }}
          variant="contained"
          onClick={() => {
            setComming(true)
            // setOpen(true)
          }}
          style={{ fontFamily: "outfit" }}
        >
          Add New Addon
        </Button>
      </div>
      <div>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {/* {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )} */}
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  This feature is coming soon!..
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Is Active
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map(item => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        <TableCell
                          key={item._id}
                          align="center"
                          sx={tableContentStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.name?.en}
                        </TableCell>
                        <TableCell align="center" sx={tableContentStyle}>
                          {item?.metadata?.isActive ? (
                            <CheckCircleIcon fontSize="small" />
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {/* <IconButton onClick={() => {}}>
                                <VisibilityIcon fontSize="small" />
                              </IconButton> */}
                              <IconButton
                                onClick={() => {
                                  handleEdit(item)
                                }}
                              >
                                <EditNoteIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpenDialog(true)
                                  setDeleteItem(item)
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontFamily: "outfit" }}
            sx={tableContentStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

export default Addons

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
}

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
}
