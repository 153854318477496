import React, { useEffect, useState, useForm } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
// import { Draggable } from "react-beautiful-dnd"
// import { useFormik } from "formik"
import { deleteCoupons } from "common/Services/dbServices"
import { Button, IconButton } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import { LoadingButton } from "@mui/lab"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"

import * as Yup from "yup"
import {
  //   Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import { useProduct } from "common/Services/product"
import { useCoupons } from "common/Hooks/queryCalls"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
// import "@fullcalendar/bootstrap/main.css"

const Calender = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const navigate = useNavigate()
  const [dragActive, setDragActive] = React.useState(false)
  // const [data, setData] = useState()
  const inputRef = React.useRef(null)
  const [deleteItemId, setDeleteItemId] = useState()
  const [id, setId] = useState()
  const [editId, setEditId] = useState()
  const [editedBrand, setEditedBrand] = useState()
  const [editValue, setEditValue] = useState()
  const [editValueOrder, setEditValueOrder] = useState(0)
  const [res, setRes] = useState()
  // const [isLoading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [page, setPage] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)
  const dispatch = useDispatch()

  const { providerId } = useProduct()

  const { data, isLoading, refetch } = useCoupons({
    providerId: providerId,
  })

  // useEffect(() => {
  //   console.log("providerId",providerId)
  //   if(providerId){
  //     CouponData()
  //   }
  // }, [deleteItemId])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  const popToggle = result => {
    setId(result)
    setOpenDialog(!openDialog)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    // setDeleteItem({})
  }

  const deleteItem = async () => {
    const deleteDoc = await deleteCoupons({ id })
    setDeleteItemId(deleteDoc)
    setOpenDialog(false)
    refetch()
    setShowSuccess(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setShowSuccess(false)
  }

  // document.title = "Coupon | Merche "

  // const CouponData = async () => {
  //   try {
  //     setLoading(true)
  //     const response =  useCoupons(providerId)

  //     if (response) {
  //       setData(response)
  //     }
  //   } catch (e) {
  //     setLoading(false)
  //     alert("Sorry,Something went wrong")
  //   }
  //   setLoading(false)
  // }
  // console.log("couponData:", data)
  return (
    <React.Fragment>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Coupon Deleted Successfully
          </Alert>
        </Snackbar>
      </Stack>

      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h6 className="page-title">List of coupons</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Discover the Deals</li>
            </ol>
          </div>

          <div className="d-flex align-items-center">
            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
              }}
              variant="contained"
              onClick={() => {
                navigate(`/add-coupon`)
              }}
              style={{ fontFamily: "outfit" }}
            >
              Add New Coupon
            </Button>
          </div>
        </div>
      </div>

      <div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Coupon Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    UseOnlyOnce
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    isActive
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        {item.name ? (
                          <TableCell
                            align="center"
                            onClick={() =>
                              navigate(`/coupon-details/${item._id}`, {
                                state: item,
                              })
                            }
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {item.name}
                          </TableCell>
                        ) : null}
                        {item.description ? (
                          <TableCell
                            align="center"
                            style={{ fontFamily: "outfit" }}
                            onClick={() =>
                              navigate(`/coupon-details/${item._id}`, {
                                state: item,
                              })
                            }
                            sx={tableBodyStyle}
                          >
                            {item.description}
                          </TableCell>
                        ) : null}
                        {item.type ? (
                          <TableCell
                            sx={tableBodyStyle}
                            align="center"
                            style={{ fontFamily: "outfit" }}
                            onClick={() =>
                              navigate(`/coupon-details/${item._id}`, {
                                state: item,
                              })
                            }
                          >
                            {item.type}
                          </TableCell>
                        ) : null}
                        {item ? (
                          <TableCell
                            align="center"
                            onClick={() =>
                              navigate(`/coupon-details/${item._id}`, {
                                state: item,
                              })
                            }
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {item?.toggleSwitch ? "true" : "false"}
                          </TableCell>
                        ) : null}
                        {item ? (
                          <TableCell
                            align="center"
                            onClick={() =>
                              navigate(`/coupon-details/${item._id}`, {
                                state: item,
                              })
                            }
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {item?.isActive ? "true" : "false"}
                          </TableCell>
                        ) : null}

                        <TableCell align="center">
                          {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <i
                                onClick={() =>
                                  navigate(`/coupon-details/${item._id}`, {
                                    state: item,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  marginRight: "20px",
                                }}
                                className="fas fa-eye"
                              ></i>
                              <i
                                onClick={() =>
                                  navigate(`/add-coupon/${item._id}`, {
                                    state: item,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  marginRight: "20px",
                                }}
                                className="fas fa-edit"
                              ></i>

                              <i
                                onClick={() => {
                                  popToggle(item._id)
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                                className="fas fa-trash"
                              ></i>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontFamily: "outfit" }}
            sx={tableFooterStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "outfit" }}>
          {"Are you sure you want to delete this Coupon?"}
        </DialogTitle>

        <DialogActions>
          {!deleteLoading ? (
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "outfit" }}
            >
              No
            </Button>
          ) : (
            ""
          )}
          {deleteLoading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={deleteLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Yes</span>
            </LoadingButton>
          ) : (
            <Button
              onClick={deleteItem}
              autoFocus
              style={{ fontFamily: "outfit" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
