import React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import ChatIcon from "@mui/icons-material/Chat"
import { IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { LottieLoading } from "common/LottieLoading"
import { useCustomer } from "common/Services/customer"
import { Row, Col } from "reactstrap"
import { Badge } from "@mui/material"

const Audience = () => {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0)
  const {
    customers,
    isCustomersLoading,
    customersRefetch,
    customersCount,
    isCustomersCountLoading,
    customersCountRefetch,
    customerListOptions,
    setCustomerListOption,
  } = useCustomer()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let tempOptions = customerListOptions
    tempOptions.skip = newPage * tempOptions.limit
    setCustomerListOption(tempOptions)
    customersRefetch()
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    let tempOptions = customerListOptions
    tempOptions.skip = 0
    tempOptions.limit = +event.target.value
    setCustomerListOption(tempOptions)
    customersRefetch()
  }

  if (isCustomersLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  return (
    <div>
      <div className="page-title-box">
        <div>
          {" "}
          <h6 className="page-title">Customer List</h6>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item active">
              Connecting with Your Customer Base
            </li>
          </ol>
        </div>
      </div>
      <div>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {customers?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Customers Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Customer Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Chat
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers?.map(item => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item._id}
                    >
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.customer?.firstName || item?.customer?.lastName
                          ? `${item?.customer?.firstName} ${item?.customer?.lastName}`
                          : `-`}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.customer?.number
                          ? `+91 ${item?.customer?.number}`
                          : `-`}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                navigate(`/chat/${item?._id}`)
                              }}
                            >
                              <Badge
                                color="secondary"
                                badgeContent={item?.message?.length}
                                max={10}
                                sx={{
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "#ff6161", // Change this color to your desired badge color
                                    color: "white", // Change this color to the text color you want
                                  },
                                }}
                              >
                                <ChatIcon fontSize="small" />
                              </Badge>
                            </IconButton>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontFamily: "outfit" }}
            sx={tableFooterStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={customersCount?.length || 0}
            rowsPerPage={customerListOptions.limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  )
}

export default Audience

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
