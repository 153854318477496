import * as React from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import {
  useBranchModifiers,
  useBranchProducts,
  useCurrentProvider,
} from "../../common/Hooks/queryCalls"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TablePagination from "@mui/material/TablePagination"
import TableSortLabel from "@mui/material/TableSortLabel"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import { LottieLoading } from "../../common/LottieLoading"
import Tooltip from "@mui/material/Tooltip"
import { Switch, FormControlLabel } from "@mui/material"
import { updateIsActive, updateIsStock } from "../../common/Services/dbServices"
import envConfig from "../../env/env.json"
import "../../css/branches.css"
import CircularProgress from "@mui/material/CircularProgress"


const headCells = [
  {
    id: "item",
    numeric: true,
    disablePadding: false,
    label: "Item",
  },
  {
    id: "isactive",
    numeric: false,
    disablePadding: false,
    label: "Is Active",
  },
  {
    id: "isstock",
    numeric: false,
    disablePadding: false,
    label: "Is Stock",
  },
]

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            sx={tableHeadStyle}
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ fontFamily: "outfit" }}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const ViewBranch = ({ specificProvider }) => {
  const [value, setValue] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [stockIndex, setStockIndex] = React.useState()
  const [activeIndex, setActiveIndex] = React.useState()
  const [stockLoading, setStockLoading] = React.useState(false)
  const [activeLoading, setActiveLoading] = React.useState(false)
  const currentProvider = useCurrentProvider()
  const { data, isLoading, refetch } = useBranchModifiers({
    branchId: specificProvider?._id,
  })
  const product = useBranchProducts({ branchId: specificProvider?._id })

  if (product.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  if (!product.data) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  if (!data) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - product?.data?.length) : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  // console.log("viewBranch::", specificProvider);
  // console.log("branchaddons::", data);
  // console.log("branchproducts::", product);

  function myFunction() {
    // Get the text field
    // var copyText = document.getElementById("myInput").innerHTML

    // Select the text field
    // copyText.select()
    // copyText.setSelectionRange(0, 99999) // For mobile devices

    // Copy the text inside the text field
    // await navigator.clipboard.writeText(copyText);

    // Alert the copied text
    //alert("Link Copied! ")

    var copyText = document.getElementById("myInput").value
    navigator.clipboard.writeText(copyText).then(() => {
      // Alert the user that the action took place.
      // Nobody likes hidden stuff being done under the hood!
      alert("Link Copied!")
    })
  }



  const handleIsActive = async (item, isActive) => {
    setActiveLoading(true)
    try {
      const result = await updateIsActive({
        item,
        isActive,
        providerId: currentProvider?.data?._id,
        branchId: specificProvider?._id,
      })
      // console.log("updateIsActive:", result);
      if (result) {
        product.refetch()
        setActiveLoading(false)
        // console.log(product.data.metadata);
      }
      product.refetch()
    } catch (error) {
      setActiveLoading(false)
    }
  }

  const handleIsStock = async (item, isStock) => {
    setStockLoading(true)
    try {
      const result = await updateIsStock({
        item,
        isStock,
        providerId: currentProvider?.data?._id,
        branchId: specificProvider?._id,
      })
      // console.log("updateIsActive:", result);
      if (result) {
        product.refetch()
        setStockLoading(false)
        // console.log(product.data.metadata);
      }
      product.refetch()
    } catch (error) {
      setStockLoading(false)
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            sx={tableHeadStyle}
            label="Details"
            {...a11yProps(0)}
            style={{ fontFamily: "outfit" }}
          />
          <Tab
            sx={tableHeadStyle}
            label="Addons"
            {...a11yProps(1)}
            style={{ fontFamily: "outfit" }}
          />
          <Tab
            sx={tableHeadStyle}
            label="Products"
            {...a11yProps(2)}
            style={{ fontFamily: "outfit" }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="view-branch">
          <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
              <Item>
                {" "}
                <div className="each-view-row" style={{ fontFamily: "outfit" }}>
                  <div className="each-element">
                    <h6>Name(EN):</h6>
                    <h6>{specificProvider?.name?.en}</h6>
                  </div>
                  <div className="each-element">
                    <h6>Prefix:</h6>
                    <h6>{specificProvider?.prefix}</h6>
                  </div>
                </div>
              </Item>

              <Item>
                <div className="each-view-row" style={{ fontFamily: "outfit" }}>
                  <div className="each-element">
                    <h6>Number:</h6>
                    <h6>{`+91 ${specificProvider?.number}`}</h6>
                  </div>
                  <div className="each-element">
                    <h6>Email:</h6>
                    <h6>{specificProvider?.email}</h6>
                  </div>
                </div>
              </Item>
              <Item>
                <div className="each-view-row" style={{ fontFamily: "outfit" }}>
                  <div className="each-element">
                    <h6>Is Active:</h6>
                    <h6>
                      {specificProvider?.metadata?.isActive ? (
                        <CheckCircleIcon fontSize="small" />
                      ) : (
                        <CancelIcon fontSize="small" />
                      )}
                    </h6>
                  </div>
                  <div className="each-element">
                    <h6>Sharable Link:</h6>
                    {/* <h6>{`${process.env.SHOP_URL}p/${specificProvider?.prefix}`}</h6> */}
                    <Tooltip title="Click to Copy" arrow>
                      <h6>
                        <input
                          type="text"
                          value={` ${envConfig.SHOP_URL}p/${specificProvider?.prefix}`}
                          id="myInput"
                          onClick={myFunction}
                          // readonly
                        />
                      </h6>
                    </Tooltip>
                  </div>
                </div>
              </Item>
            </Stack>
          </Box>
          {/* <div className="each-view-row">
            <div className="each-element">
              <h6>Name(EN):</h6>
              <h4>{specificProvider?.name?.en}</h4>
            </div>
            <div className="each-element">
              <h4>Name(AR):</h4>
              <h4>{specificProvider?.name?.ar}</h4>
            </div>
          </div>
          <div className="each-view-row">
            <div className="each-element">
              <h4>Prefix:</h4>
              <h4>{specificProvider?.prefix}</h4>
            </div>
            <div className="each-element">
              <h4>Username:</h4>
              <h4>{specificProvider?.username}</h4>
            </div>
          </div>
          <div className="each-view-row">
            <div className="each-element">
              <h4>Number:</h4>
              <h4>{`+91 ${specificProvider?.number}`}</h4>
            </div>
            <div className="each-element">
              <h4>Email:</h4>
              <h4>{specificProvider?.email}</h4>
            </div>
          </div>
          <div className="each-view-row">
            <div className="each-element">
              <h4>Is Active:</h4>
              <h4>
                {specificProvider?.metadata?.isActive ? (
                  <CheckCircleIcon fontSize="small" />
                ) : (
                  <CancelIcon fontSize="small" />
                )}
              </h4>
            </div>
            <div className="each-element">
              <h4>Sharable Link:</h4>
              <h4>{`${process.env.SHOP_URL}p/${specificProvider?.prefix}`}</h4>
            </div>
          </div> */}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="branch-modifiers-table">
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer
                sx={{
                  "@media (max-width: 600px)": {},
                  "@media (min-width: 601px) and (max-width: 960px)": {},
                  "@media (min-width: 970px)": {},
                }}
                // component={Paper}
              >
                <Table aria-label="simple table" size={"medium"}>
                  {/* {data?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Items Available
                    </caption>
                  )} */}
                  {data?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      This feature is coming soon
                    </caption>
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Item
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        is Active
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map(addon => (
                      <TableRow
                        key={addon?.getBranchModifiers?._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={tableContentStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {addon?.getBranchModifiers?.name?.en}
                        </TableCell>
                        <TableCell align="center">
                          {addon?.getBranchModifiers?.metadata?.isActive ? (
                            <CheckCircleIcon fontSize="small" />
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="branch-products-table">
          {" "}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{
                    "@media (max-width: 600px)": {},
                    "@media (min-width: 601px) and (max-width: 960px)": {},
                    "@media (min-width: 970px)": {},
                  }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  {product?.data?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Items Available
                    </caption>
                  )}
                  <EnhancedTableHead
                    // numSelected={selected.length}
                    // order={order}
                    // orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    // onRequestSort={handleRequestSort}
                    rowCount={product?.data?.length}
                  />
                  <TableBody>
                    {product?.data
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        // const isItemSelected = isSelected(row.name);
                        // const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.name)}
                            // role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={item._id}
                            // selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                              sx={tableContentStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.getBranchProducts?.name?.en}
                            </TableCell>
                            <TableCell align="center">
                              {activeLoading && activeIndex == index ? (
                                <CircularProgress size={23} />
                              ) : (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Switch
                                      checked={item?.metadata?.isActive}
                                      onClick={() => {
                                        setActiveIndex(index)
                                        handleIsActive(
                                          item,
                                          !item?.metadata?.isActive
                                        )
                                      }}
                                    />
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {stockLoading && stockIndex == index ? (
                                <CircularProgress size={23} />
                              ) : (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Switch
                                      checked={item?.inStock}
                                      onClick={() => {
                                        setStockIndex(index)
                                        handleIsStock(item, !item?.inStock)
                                      }}
                                    />
                                  }
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}

                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={{ fontFamily: "outfit" }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={product?.data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{" "}
            </Paper>
          </Box>
        </div>
      </TabPanel>
    </Box>
  )
}

export default ViewBranch

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
}

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
}
