import React, { useContext, useEffect, useState } from "react"
import {
  useCurrentProvider,
  useCompletedOrders,
  useOngoingOrders,
  useOneOrderDetails,
  useRevenue,
} from "common/Hooks/queryCalls"
import moment from "moment"
import { acceptOrder, OrderStatusUpdate } from "./dbServices"

export const OrderContext = React.createContext()

export const OrderProvider = ({ children }) => {
  const [orderId, setOrderId] = useState()
  const [selectedStatus, setSelectedStatus] = useState("all")
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("pending")
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [completedStartDate, setCompletedStartDate] = useState(null)
  const [completedEndDate, setCompletedEndDate] = useState(null)
  const currentProvider = useCurrentProvider()
  const {
    data: completedOrdersData,
    isLoading: completedOrdersIsLoading,
    refetch: completedOrdersRefetch,
  } = useCompletedOrders({
    providerId: currentProvider?.data?._id,
    completedStartDate: completedStartDate
      ? moment(completedStartDate).startOf("day").toDate()
      : null,
    completedEndDate: completedEndDate
      ? moment(completedEndDate).endOf("day").toDate()
      : null,
  })
  const {
    data: ongoingOrdersData,
    isLoading: ongoingOrdersIsLoading,
    refetch: ongoingOrdersRefetch,
  } = useOngoingOrders({
    providerId: currentProvider?.data?._id,
    status: selectedStatus,
    paymentStatus: selectedPaymentStatus,
    startDate: startDate ? moment(startDate).startOf("day").toDate() : null,
    endDate: endDate ? moment(endDate).endOf("day").toDate() : null,
  })
  const {
    data: oneOrderDetails,
    isLoading: oneOrderDetailsIsLoading,
    refetch: oneOrderDetailsRefetch,
  } = useOneOrderDetails({ orderId })

  const AcceptOrder = async () => {
    const result = await acceptOrder({
      id: orderId.toString(),
    })
    ongoingOrdersRefetch()
    completedOrdersRefetch()
    return result
  }
  const updateOrderStatus = async ({ itemId, orderStatus }) => {
    const result = await OrderStatusUpdate({ itemId, orderStatus })
    ongoingOrdersRefetch()
    completedOrdersRefetch()
    return result
  }

  const {
    data: revenueData,
    isLoading: revenueIsLoading,
    refetch: revenueRefetch,
  } = useRevenue({
    providerId: currentProvider?.data?._id,
    dateFrom: dateFrom ? moment(dateFrom).startOf("day").toDate() : null,
    dateTo: dateTo ? moment(dateTo).endOf("day").toDate() : null,
  })

  return (
    <OrderContext.Provider
      value={{
        ongoingOrdersData,
        ongoingOrdersIsLoading,
        ongoingOrdersRefetch,
        selectedStatus,
        setSelectedStatus,
        selectedPaymentStatus,
        setSelectedPaymentStatus,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        completedOrdersData,
        completedOrdersIsLoading,
        completedOrdersRefetch,
        completedStartDate,
        setCompletedStartDate,
        completedEndDate,
        setCompletedEndDate,
        oneOrderDetails,
        oneOrderDetailsIsLoading,
        oneOrderDetailsRefetch,
        setOrderId,
        AcceptOrder,
        updateOrderStatus,
        branchId: currentProvider?.data?.branch?._id,
        providerId: currentProvider?.data?._id,
        revenueData,
        revenueIsLoading,
        revenueRefetch,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export function useOrder() {
  return useContext(OrderContext)
}
