import React, { useState } from "react"
import "../../css/stockList.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TablePagination from "@mui/material/TablePagination"
import TableSortLabel from "@mui/material/TableSortLabel"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import { Switch, FormControlLabel } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/material"
import {
  useBranchModifiers,
  useBranchProducts,
  useBranchProductsCount,
  useCurrentProvider,
} from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import { updateIsActive, updateIsStock } from "common/Services/dbServices"
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import { Button } from "@mui/material"
import { Row, Col } from "reactstrap"
const headCells = [
  {
    id: "item",
    numeric: true,
    disablePadding: false,
    label: "Item",
  },
  {
    id: "isactive",
    numeric: false,
    disablePadding: false,
    label: "Is Active",
  },
  {
    id: "isstock",
    numeric: false,
    disablePadding: false,
    label: "Is Stock",
  },
]

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            sx={tableHeadStyle}
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ fontFamily: "outfit" }}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const Stocks = () => {
  const [page, setPage] = React.useState(0)
  const [stockListOptions, setStockListOption] = useState({
    sort: { _id: -1 },
    skip: 0,
    limit: 5,
  })

  const [stockIndex, setStockIndex] = React.useState()
  const [activeIndex, setActiveIndex] = React.useState()
  const [stockLoading, setStockLoading] = React.useState(false)
  const [activeLoading, setActiveLoading] = React.useState(false)

  const [btnprimary1, setBtnprimary1] = useState(false)
  const [btnprimary2, setBtnprimary2] = useState(false)

  const [selectedStatus, setSelectedStatus] = useState("Status")
  const [selectedStock, setSelectedStock] = useState("Stock")

  const currentProvider = useCurrentProvider()
  const { data, isLoading, refetch } = useBranchModifiers({
    branchId: currentProvider?.data?.branch?._id,
  })
  const product = useBranchProducts({
    branchId: currentProvider?.data?.branch?._id,
    status: selectedStatus,
    stock: selectedStock,
    options: stockListOptions,
  })
  const productCount = useBranchProductsCount({
    branchId: currentProvider?.data?.branch?._id,
    status: selectedStatus,
    stock: selectedStock,
  })

  if (product.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  if (!product.data) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  if (!data) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    let tempOptions = stockListOptions
    tempOptions.skip = newPage * tempOptions.limit
    setStockListOption(tempOptions)
    product.refetch()
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    let tempOptions = stockListOptions
    tempOptions.skip = 0
    tempOptions.limit = +event.target.value
    setStockListOption(tempOptions)
    product.refetch()
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // console.log("branchaddons::", data)
  // console.log("branchproducts::", product)
  // console.log("currentProvider:", currentProvider?.data)

  const handleIsActive = async (item, isActive) => {
    setActiveLoading(true)
    try {
      const result = await updateIsActive({
        item,
        isActive,
        providerId: currentProvider?.data?._id,
        branchId: currentProvider?.data?.branch?._id,
      })
      // console.log("updateIsActive:", result);
      if (result) {
        product.refetch()
        setActiveLoading(false)
        // console.log(product.data.metadata);
      }
      product.refetch()
    } catch (error) {
      setActiveLoading(false)
    }
  }

  const handleIsStock = async (item, isStock) => {
    setStockLoading(true)
    try {
      const result = await updateIsStock({
        item,
        isStock,
        providerId: currentProvider?.data?._id,
        branchId: currentProvider?.data?.branch?._id,
      })
      // console.log("updateIsActive:", result);
      if (result) {
        product.refetch()
        setStockLoading(false)
        // console.log(product.data.metadata);
      }
      product.refetch()
    } catch (error) {
      setStockLoading(false)
    }
  }

  return (
    <div
      className="stock-main-page"
      // style={{ marginTop: "30px" ,marginLeft:"10px",}}
      // style={{
      //   height: "100vh",
      //   width: "100%",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      // }}
    >
      <div className="page-title-box">
        <div>
          {" "}
          <h6 className="page-title">Product List</h6>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item active">
            Managing Your Product Inventory
            </li>
          </ol>
        </div>
      </div>
      <div className="btn-group" style={{ margin: 5 }}>
        <Dropdown
          isOpen={btnprimary1}
          toggle={() => setBtnprimary1(!btnprimary1)}
          style={{ marginRight: "5px" }}
        >
          <DropdownToggle
            tag="button"
            className="btn"
            style={{ backgroundColor: "#333547", color: "white" }}
          >
            {selectedStatus}
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setSelectedStatus("Active")}>
              Active
            </DropdownItem>
            <DropdownItem onClick={() => setSelectedStatus("InActive")}>
              InActive
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Dropdown
          isOpen={btnprimary2}
          toggle={() => setBtnprimary2(!btnprimary2)}
          style={{ marginRight: "5px" }}
        >
          <DropdownToggle
            tag="button"
            className="btn"
            style={{ backgroundColor: "#333547", color: "white" }}
          >
            {selectedStock}
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setSelectedStock("Active")}>
              Active
            </DropdownItem>
            <DropdownItem onClick={() => setSelectedStock("InActive")}>
              InActive
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Button
          variant="contained"
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "0.8rem",
              marginLeft: "5px",
              textTransform: "capitalize",
              backgroundColor: "#333547",
              fontWeight: "400",
              height: "35px",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              fontSize: "0.8rem",
              marginLeft: "5px",
              textTransform: "capitalize",
              backgroundColor: "#333547",
              fontWeight: "400",
              height: "35px",
              "&:hover": {
                backgroundColor: "#535EB4", // Change the background color on hover
              },
            },
            "@media (min-width: 970px)": {
              fontSize: "0.8rem",
              marginLeft: "5px",
              textTransform: "capitalize",
              backgroundColor: "#333547",
              fontWeight: "400",
              height: "35px",
              "&:hover": {
                backgroundColor: "#535EB4", // Change the background color on hover
              },
            },
          }}
          onClick={() => {
            setSelectedStatus("Status"), setSelectedStock("Stock")
          }}
        >
          Clear
        </Button>
      </div>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <h6 style={{ display: "flex", marginLeft: "10px", padding: "15px",fontWeight:600 }}>
            Products List
          </h6> */}
          <TableContainer>
            <Table
              sx={{
                "@media (max-width: 600px)": {},
                "@media (min-width: 601px) and (max-width: 960px)": {},
                "@media (min-width: 970px)": {},
              }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              {product?.data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <EnhancedTableHead
                // numSelected={selected.length}
                // order={order}
                // orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                // onRequestSort={handleRequestSort}
                rowCount={product?.data?.length}
              />
              <TableBody>
                {product?.data?.map((item, index) => {
                  // const isItemSelected = isSelected(row.name);
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={item._id}
                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="th"
                        // id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.getBranchProducts?.name?.en}
                      </TableCell>
                      <TableCell align="center">
                        {activeLoading && activeIndex == index ? (
                          <CircularProgress size={23} />
                        ) : (
                          <FormControlLabel
                            key={index}
                            control={
                              <Switch
                                checked={item?.metadata?.isActive}
                                onClick={() => {
                                  setActiveIndex(index)
                                  handleIsActive(
                                    item,
                                    !item?.metadata?.isActive
                                  )
                                }}
                              />
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {stockLoading && stockIndex == index ? (
                          <CircularProgress size={23} />
                        ) : (
                          <FormControlLabel
                            key={index}
                            control={
                              <Switch
                                checked={item?.inStock}
                                onClick={() => {
                                  setStockIndex(index)
                                  handleIsStock(item, !item?.inStock)
                                }}
                              />
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}

                {
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={tableFooterStyle}
            style={{ fontFamily: "outfit" }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={productCount?.data || 0}
            rowsPerPage={stockListOptions.limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{" "}
        </Paper>
      </Box>
      {/* <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <caption style={{ display: "flex", marginLeft: "20px" }}>
            Addon's List
          </caption>
          <TableContainer
            sx={{
              "@media (max-width: 600px)": {},
              "@media (min-width: 601px) and (max-width: 960px)": {},
              "@media (min-width: 970px)": {},
            }}
            // component={Paper}
          >
            <Table aria-label="simple table" size={"medium"}>
              {data?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Items Available
                    </caption>
                  )}
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  This feature is coming soon
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Item
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    is Active
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(addon => (
                  <TableRow
                    key={addon?.getBranchModifiers?._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={tableContentStyle}
                      style={{ fontFamily: "outfit" }}
                    >
                      {addon?.getBranchModifiers?.name?.en}
                    </TableCell>
                    <TableCell align="center">
                      {addon?.getBranchModifiers?.metadata?.isActive ? (
                        <CheckCircleIcon fontSize="small" />
                      ) : (
                        <CancelIcon fontSize="small" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box> */}
    </div>
  )
}

export default Stocks

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem"
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem"
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem"
    },
  },
}
