import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Button, IconButton } from "@mui/material"
import { LottieLoading } from "common/LottieLoading"
import { useNavigate } from "react-router-dom"
import { useCurrentProvider, useBookings } from "common/Hooks/queryCalls"
import { formatDateOnly, format24ToTime } from "common/Services/functions"
const Bookings = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const currentProvider = useCurrentProvider()
  const { data, isLoading, refetch } = useBookings({
    providerId: currentProvider?.data?._id,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  return (
    <>
      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h6 className="page-title">Bookings</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Diving into Bookings</li>
            </ol>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Bookings Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Booking Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Booking Status
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Assigned To
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Start Time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Estimated End Time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    End Time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Payment Status
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                        onClick={() =>
                          navigate(`/booking-details/${item?._id}`)
                        }
                      >
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.refNumber}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {formatDateOnly(item?.bookedDate)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={
                            item?.status == "completed"
                              ? { color: "#098041", fontFamily: "outfit" }
                              : item.status == "pending"
                              ? { color: "#FFC107", fontFamily: "outfit" }
                              : item.status == "ready" ||
                                item.status == "accepted"
                              ? { color: "#3796F0", fontFamily: "outfit" }
                              : { color: "#F44336", fontFamily: "outfit" }
                          }
                        >
                          {item?.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.serviceMemberData?.length > 0 &&
                          item?.serviceMemberData[0]
                            ? item?.serviceMemberData[0]?.name
                            : `-`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.startTime
                            ? format24ToTime(item?.startTime)
                            : `-`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.estimatedEndTime
                            ? format24ToTime(item?.estimatedEndTime)
                            : `-`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.endTime ? format24ToTime(item?.endTime) : `-`}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.paymentStatus}
                        </TableCell>

                        <TableCell align="center">
                          {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  navigate(`/booking-details/${item?._id}`)
                                }
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={tableFooterStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

export default Bookings
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
