import React, { useContext, useEffect, useState } from "react"
import {
  useCurrentProvider,
  useAudience,
  useAudienceCount,
  useAddresses,
  useAudienceOrder,
} from "common/Hooks/queryCalls"
import {
  createOneCustomer,
  createCustomerAddress,
  CustomerOneAddress,
} from "./dbServices"
export const CustomerContext = React.createContext()

export const CustomerProvider = ({ children }) => {
  const [entityId, setEntityId] = useState()
  const currentProvider = useCurrentProvider()
  const [customerListOptions, setCustomerListOption] = useState({
    sort: { _id: -1 },
    skip: 0,
    limit: 5,
  })
  //to get audience
  const {
    data: customers,
    isLoading: isCustomersLoading,
    refetch: customersRefetch,
  } = useAudience({
    providerId: currentProvider?.data?._id,
    options: customerListOptions,
  })

  console.log("audience:", customers)

  const {
    data: customersCount,
    isLoading: isCustomersCountLoading,
    refetch: customersCountRefetch,
  } = useAudienceCount({
    providerId: currentProvider?.data?._id,
  })

  const {
    data: audience,
    isLoading: isAudienceLoading,
    refetch: audienceRefetch,
  } = useAudienceOrder({
    providerId: currentProvider?.data?._id,
  })

  console.log("create order page audience:", audience)

  //to get all addresses correspond to customer
  const {
    data: customerAddresses,
    isLoading: customerAddressesIsLoading,
    refetch: customerAddressesRefetch,
  } = useAddresses({ entityId: entityId?.toString() })

  //to create a customer
  const createCustomer = async ({ data }) => {
    try {
      const result = await createOneCustomer({ data })
      return result
      // console.log("result:", result)
    } catch (error) {
      throw error
    }
  }

  //to create a address to a customer
  const createAddress = async ({ entityId, data }) => {
    try {
      const result = await createCustomerAddress({ entityId, data })
      return result
      // console.log("result:", result)
    } catch (error) {
      throw error
    }
  }

  const oneAddress = async ({ entityId }) => {
    // console.log("oneAddress:", entityId)
    try {
      const result = await CustomerOneAddress({ entityId })
      return result
    } catch (error) {
      throw error
    }
  }

  return (
    <CustomerContext.Provider
      value={{
        createCustomer,
        createAddress,
        customers,
        isCustomersLoading,
        customersRefetch,
        customersCount,
        isCustomersCountLoading,
        customersCountRefetch,
        customerListOptions,
        setCustomerListOption,
        customerAddresses,
        customerAddressesIsLoading,
        customerAddressesRefetch,
        entityId,
        setEntityId,
        oneAddress,
        currentProvider,
        audience,
        isAudienceLoading,
        audienceRefetch,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export function useCustomer() {
  return useContext(CustomerContext)
}
