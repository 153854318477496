import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Rating from "@mui/material/Rating"
import { useReviews, useCurrentProvider } from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import { formatDate } from "common/Services/functions"
import { updateReviewIsActive } from "common/Services/dbServices"
import { Switch, FormControlLabel } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { Row, Col } from "reactstrap"

const Reviews = () => {
  const [page, setPage] = React.useState(0)
  const [activeIndex, setActiveIndex] = React.useState()
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [activeLoading, setActiveLoading] = useState(false)
  const currentProvider = useCurrentProvider()
  const navigate = useNavigate()
  const { data, isLoading, refetch } = useReviews({
    providerId: currentProvider?.data?._id,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const onSubmit = async (id, activeIndex) => {
    setActiveLoading(true)
    try {
      await updateReviewIsActive({
        id,
        isActive: activeIndex,
      })
      refetch()
    } catch (error) {
      setActiveLoading(false)
    }
    setActiveLoading(false)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleTableRowClick = item => {
    navigate("/review-details", { state: item })
  }

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  return (
    <div>
      <div className="page-title-box">
        <div>
          {" "}
          <h6 className="page-title">Reviews</h6>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item active">
              Review Central: Your Feedback Hub
            </li>
          </ol>
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          {activeLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div className="spinner-border" role="status"></div>
              <span className="sr-only">Loading ...</span>
            </div>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}

              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Rating
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Review
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Active
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                          onClick={() => handleTableRowClick(item)}
                        >
                          {formatDate(item?.metaData?.createdAt)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                          onClick={() => handleTableRowClick(item)}
                        >
                          <Rating
                            name="read-only"
                            value={item?.rating || 0}
                            readOnly
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                          onClick={() => handleTableRowClick(item)}
                        >
                          {item?.review}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          <FormControlLabel
                            key={index}
                            control={
                              <Switch
                                checked={item?.metaData?.isActive}
                                onChange={() => {
                                  const updatedItem = { ...item }

                                  onSubmit(
                                    item?._id,
                                    !updatedItem.metaData.isActive
                                  )
                                }}
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          style={{ fontFamily: "outfit" }}
          sx={tableFooterStyle}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default Reviews
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
